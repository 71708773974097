import React ,{Component,Props} from 'react';
import Loading from "../../../Loading";
import { Pagination } from 'antd';
// import   "./UsageInfo.css";
import { Translate } from 'react-redux-i18n'
type UsageInfoProps = {
  username:string,
  data:any,
  loading:boolean,
  backPage:any
}

type UsageInfoState = {
  current:any, pageSize:any
}

class UsageInfo extends Component<UsageInfoProps, UsageInfoState> {
  constructor(props) {
    super(props);
    this.state = {
      current:1,
      pageSize:6
    };
    this.onShowSizeChange = this.onShowSizeChange.bind(this)
  }

  backPage(){
      this.props.backPage()
  }

  onShowSizeChange(current, pageSize){
    this.setState({current:current, pageSize:pageSize})
  }
  render() {
    let usageAll:any = []
    console.log('====================================');
    console.log(this.state);
    console.log('====================================');
    let data = this.props.data;
    data = { ...data}

    usageAll = data.usage || []

    let usage = usageAll.slice((this.state.current - 1) * this.state.pageSize, this.state.current * this.state.pageSize)
      // usage
    return (
      <div id="login-" className="bg_login usage-info" >
          <div className="bt-sz-back fix" onClick={()=>this.backPage()} >
            <span><Translate value="back" /></span>
            </div>
            {/* style={{padding:'0px 30px'}} */}
      <div className="row" >
      <div className="col-md-12 header-text">
        <span><b> <Translate value="usageHistory" />  </b> </span>

        </div>

        <div className="col-md-12">


      <div id="info" style={{ textAlign: "center" }}>
        <div className="checkTime"  style={{marginTop: 20,}}>

        <div className="detail-data">
          <div className="row">
            <div className="col-5 col-md-5 text-right nopadding">
            <Translate value="name"/>
            </div>
            <div className="col-1 col-md-1 text-center nopadding">
            :
            </div>
            <div className="col-5 col-md-5 text-center nopadding">
            {data.userName}
            </div>
          </div>
          <div className="row">
            <div className="col-5 col-md-5 text-right nopadding">
            <Translate value="cardType"/>
            </div>
            <div className="col-1 col-md-1 text-center nopadding">
            :
            </div>
            <div className="col-5 col-md-5 text-center nopadding">
            {data.groupName}
            </div>
          </div>
          <div className="row">
            <div className="col-5 col-md-5 text-right nopadding">
            <Translate value="timeLimit"/>
            </div>
            <div className="col-1 col-md-1 text-center nopadding">
            :
            </div>
            <div className="col-5 col-md-5 text-center nopadding">
            {data.timeLimit?data.timeLimit:"-"}
            </div>
          </div>
          <div className="row">
            <div className="col-5 col-md-5 text-right nopadding">
            <Translate value="timeUsed"/>
            </div>
            <div className="col-1 col-md-1 text-center nopadding">
            :
            </div>
            <div className="col-5 col-md-5 text-center nopadding">
            {data.timeUsed?data.timeUsed:'-'}
            </div>
          </div>

          <div className="row">
            <div className="col-5 col-md-5 text-right nopadding">
            <Translate value="timeRemaining"/>
            </div>
            <div className="col-1 col-md-1 text-center nopadding">
            :
            </div>
            <div className="col-5 col-md-5 text-center nopadding">
            {data.timeLeft?data.timeLeft:'-'}
            </div>
          </div>


          <div className="row">
            <div className="col-12 col-md-12 text-center" >
            (<Translate value="on"/> {data.servertime})
            </div>
            </div>

          </div>







          <div
            className="contact"
            style={{
              marginTop: 20,
              marginBottom: 10,
              width: 280,
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center"
            }}
          >

          </div>
        </div>

        <div className="stream-time" style={{ textAlign: "center" }}>
          {data.usage?

          <div className="detail-data list" style={{ padding: 0 }}>
            {/*  */}
          <table  className="table" >
            <thead style={{ textAlign: "center" }}>
              <tr>
                <th  className="td-100"> <Translate value="startTime"/></th>
                <th  className="td-100"> <Translate value="stopTime"/></th>
                <th  ><Translate value="upload"/></th>
                <th  ><Translate value="download"/></th>
                <th  ><Translate value="sessionTime"/></th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {usage.map(row => {
                return (
                  <tr>
                    <td  className="td-100"> {row.acctStartTime?row.acctStartTime:'-'} </td>
                    <td  className="td-100"> {row.acctStopTime?row.acctStopTime:'-'} </td>
                    <td  className="td-70"> {row.acctInputOctets?row.acctInputOctets:'-'} </td>
                    <td  className="td-70"> {row.acctOutputOctets?row.acctOutputOctets:'-'} </td>
                    <td  className="td-70"> {row.acctSessionTime?row.acctSessionTime:'-'} </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={2} style={{ paddingLeft: 20 }} className="text-center">
                  <strong> <Translate value="total"/></strong>
                </td>
                <td className="td-70" style={{ textAlign: "center" }}><strong> {data.totalUpload}</strong> </td>
                <td className="td-70" style={{ textAlign: "center" }}><strong> {data.totalDownload}</strong> </td>
                <td className="td-70" style={{ textAlign: "center" }}><strong> {data.timeUsed}</strong> </td>
              </tr>
            </tfoot>
          </table>


          </div>:null}

          {
              usageAll && usageAll.length > 10 ? <Pagination pageSize={this.state.pageSize} onChange={(current, pageSize)=>{
                console.log('====================================');
                console.log(current, pageSize);
                console.log('====================================');
                this.setState({current:current, pageSize:pageSize})
              }}  defaultCurrent={1}  size="small"  total={usageAll.length} style={{marginTop:5}}/> :null
            }
        </div>

        <br />
      </div>
      </div>
      </div>
      </div>
    );
  }
}

export default UsageInfo;
