

import React, { Component } from 'react';
import Agreement from '../../components/Agreement';

import i18n from "../../../../../i18n/index";
import {Button ,DatePicker ,Form ,Icon ,Dropdown,Menu,Typography,Divider } from 'antd';
import Notification  from '../../../../../services/notification.service';
// import { Translate } from 'react-redux-i18n'
import { Modal } from 'antd';
import { connect } from 'react-redux'
import { getCurrentLocale } from "../../../../../data/utils";
import DictionaryService  from '../../../../../i18n/DictionaryService';
import ReactGA from 'react-ga';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CheckboxM from '@material-ui/core/Checkbox';
// import Lens from '@material-ui/icons/Lens';
import Lens from '@material-ui/icons/Stop';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { browserName} from 'react-device-detect';

import { async } from 'q';
const { Title } = Typography;
const Checkbox:any = withStyles({
  root: {
    // background: 'rgb(248, 153, 50)',
    // borderRadius: 3,
    // border: 0,
    color: 'white',
    // height: 48,
    padding: '0 0px',
    margin: '-8px -10px -14px 0px',
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    // width:  '100%'
  },
  label: {
    textTransform: 'capitalize',
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  },
})(CheckboxM);


const LensChecked = withStyles({
  root: {
    color: 'rgb(248, 153, 50)',
  }
})(Lens);


type TranslateProps = {
  value: any,
  locale:any
}
type TranslateState = {}
class TranslatePage extends Component<TranslateProps, TranslateState> {
  constructor(props) {
    super(props)
  }
  render() {
    let locale = "EN";
    try{
      locale = this.props.locale;
    }catch(e){

    }
    return (<span> {DictionaryService.msg(this.props.value,locale)}</span>);
  }
}

const mapStateToProps0: any = (state: any) => ({
  locale: getCurrentLocale(state),
});

const mapDispatchToProps0 = {}
const Translate:any = connect<any, any, {}>(mapStateToProps0, mapDispatchToProps0)(Form.create({})(TranslatePage));

// rememberSocial={this.state.rememberSocial}
// conditionSocial={this.state.conditionSocial} ,remember:any,condition:any
class SocialLogin extends React.Component<{setConditionVisible:any,setCondition:any,setRemember:any,loginSocial:any,buildingInfo:any,remember:any,condition:any,rememberSocial:any,conditionSocial:any,clearUserRemember:any,form:any,changePage:any,goRegister:any,locale:any,handleSubmitERegister:any,handleSubmitERegisterChecklogin:any,userRemember:any},{userTemp:any,condition:any,conditionVisible:any,disabled:any,eregiter:any,rememberMe:any,loading:any,pageType:any}> {
  constructor(props) {
    super(props);
    console.log("props.userRemember",props)
    this.state = {
      userTemp:{username:''},
      loading:false,
      eregiter:true,
      rememberMe:props.userRemember.remember || false,
      disabled:true,
      conditionVisible:false,
      condition:true,
      pageType:'',
    }
    setTimeout(() => {
      // console.log(props)
      this.setState({disabled:false})
  }, 100);

  this.handleSubmitUsername = this.handleSubmitUsername.bind(this)
  }

  componentWillReceiveProps(nextProps){
    // console.log(nextProps,this.props)
  }

  handleSubmitUsername = (e,form) => {
    e.preventDefault();
    // if (this.props.conditionSocial == false || this.props.condition == false) {
    //   if (this.props.locale === "EN") {
    //    Notification.error({
    //       message:"",description:`You must accept the usage agreement by clicking on the checkbox below the password form to login.`
    //     });
    //   } else {
    //     Notification.error({
    //       message:"",description:"ท่านต้องยอมรับข้อตกลงการใช้งาน ด้วยการติ๊กเครื่องหมายถูกที่ช่องสี่เหลี่ยมใต้ช่องกรอกรหัสผ่าน จึงจะเข้าสู่ระบบได้",
    //     });
    //   }
    //   return false;
    // }


    form.validateFields(async (err, user) => {
      if (!err) {
        console.log("userRemember",this.props.userRemember )
        // if(this.props.userRemember && this.props.userRemember.user && this.props.userRemember.username){
        //   this.props.handleSubmitERegister(this.props.userRemember)
        //   return false;
        // }
        console.log("user",user)
        if (!user.username) {
          if (this.props.locale === "EN") {
            Notification.error({
            message:"",description:"Please type your username.",

            });
          } else {
            Notification.error({
            message:"",description:"กรุณากรอกชื่อผู้ใช้",
            });
          }
          return false;
        }
        this.setState({userTemp:{}})
        let userPost = {...user,remember:this.props.rememberSocial}
        // user.remember =  this.state.rememberMe;
        console.log('Received values of form: ', user,this.state,userPost);
        this.setState({loading:true})
         let data:any = await this.props.handleSubmitERegisterChecklogin(userPost)


        let d = data ? data : {};
         let logindata:any = {};
         if(d.logindata && d.logindata.res && d.logindata.res.resultData){
           logindata = d.logindata.res.resultData
         }
         console.log("ReactGA.event d",d,logindata,data.resultCode , data.resultCode[0])
         let msg = (data.resultCode && data.resultCode[0] === "2" ? data.resultDescription : ( d.msg ? d.msg : (d.msgE?d.msgE:d.pageError) ) )
         let label = (data.resultCode && data.resultCode[0] === "2"?"Successfully":"Error") +" : "+d.resultCode+" $ "+ msg;
         ReactGA.event({
           category: 'Login Page',
           action: logindata.userType?logindata.userType:"",
           label: label
         });
         console.log("ReactGA.event",{
           category: 'Login Page',
           action: logindata.userType?logindata.userType:"",
           label: label
         },logindata,data)


         console.log("data.resultCode",data.resultCode,userPost)
         if(data.resultCode[0] === "2"){
          // let msg = data.errorMessage
          this.setState({pageType:'checkpassword',userTemp:userPost})
          // if(data.resultCode ==="20001"){
          //   msg = (i18n[this.props.locale]?i18n[this.props.locale].emailNotConfirmed:"")  + data.errorMessage
          // }
          // Notification.success({
          //   message:"",
          //   description:msg,
          // });
        }else if(data.resultCode[0] === "4"){
          Notification.confirm({
          message:"",
          resultCode:data.resultCode,
          okText:this.props.locale==='EN'?'Confirm':'ยืนยัน',
          cancelText:this.props.locale==='EN'?'Close':'ปิด',
          description: <div>

              <div  style={{marginTop:8,fontSize: 13}}>
                  {i18n[this.props.locale].emailNotConfirmed1} {userPost.username}
                </div>
              <div  style={{marginTop:8,fontSize: 13,}}>
                  {d.errorMessage}
              </div>
              <div  style={{marginTop:8,fontSize: 13,}}>
                {i18n[this.props.locale].emailNotConfirmed3}
              </div>

          </div>,
          onOk:(close)=>{
                      console.log("this.props.registerUser",{email:userPost.username})
            this.props.goRegister({email:userPost.username})

            close()
          }
          });


          // Notification.error({
          //   message:"",
          //   description: d.errorMessage,
          //   resultCode : d.resultCode
          // });
        }else {
          Notification.error({
            message:"",
            description: d.errorMessage,
            resultCode : d.resultCode
          });
        }
         this.setState({loading:false})
      }
    });
  };



  handleSubmit = e => {
    e.preventDefault();
    // if (this.props.conditionSocial == false) {
    //   if (this.props.locale === "EN") {
    //    Notification.error({
    //       message:"",description:`You must accept the usage agreement by clicking on the checkbox below the password form to login.`
    //     });
    //   } else {
    //     Notification.error({
    //       message:"",description:"ท่านต้องยอมรับข้อตกลงการใช้งาน ด้วยการติ๊กเครื่องหมายถูกที่ช่องสี่เหลี่ยมใต้ช่องกรอกรหัสผ่าน จึงจะเข้าสู่ระบบได้",
    //     });
    //   }
    //   return false;
    // }


    this.props.form.validateFields(async (err, user) => {
      if (!err) {
        // console.log("userRemember",this.props.userRemember )
        // if(this.props.userRemember && this.props.userRemember.user && this.props.userRemember.username){
        //   this.props.handleSubmitERegister(this.props.userRemember)
        //   return false;
        // }
        user.username = this.state.userTemp.username;
        if (!user.username) {
          if (this.props.locale === "EN") {
            Notification.error({
            message:"",description:"Please type your username.",

            });
          } else {
            Notification.error({
            message:"",description:"กรุณากรอกชื่อผู้ใช้",
            });
          }
          return false;
        }

        if (!user.password) {
          if (this.props.locale === "EN") {
            Notification.error({
            message:"",
            description:"Please type your password.",
            });
          } else {
            // message.error("กรุณากรอกรหัสผ่าน");
            Notification.error({
            message:"",
            description:"กรุณากรอกรหัสผ่าน",
            });
          }
          return false;
        }
        let userPost = {...user,remember:this.props.rememberSocial}
        // user.remember =  this.state.rememberMe;
        console.log('Received values of form: ', user,this.state,userPost);
        this.setState({loading:true})
         let data:any = await this.props.handleSubmitERegister(userPost)


        let d = data.data ? data.data : {};
         let logindata:any = {};
         if(d.logindata && d.logindata.res && d.logindata.res.resultData){
           logindata = d.logindata.res.resultData
         }
         // console.log("ReactGA.event d",d,logindata,data.resultCode , data.resultCode[0])
         let msg = (data.resultCode && data.resultCode[0] === "2" ? data.resultDescription : ( d.msg ? d.msg : (d.msgE?d.msgE:d.pageError) ) )
         let label = (data.resultCode && data.resultCode[0] === "2"?"Successfully":"Error") +" : "+d.resultCode+" $ "+ msg;
         ReactGA.event({
           category: 'Login Page',
           action: logindata.userType?logindata.userType:"",
           label: label
         });
         console.log("ReactGA.event",{
           category: 'Login Page',
           action: logindata.userType?logindata.userType:"",
           label: label
         },logindata,data)

         if(data.resultCode =="20000" ){
           this.setState({pageType:'',userTemp:{}});
           this.props.loginSocial()
         }


         if(data.resultCode && data.resultCode !=="20000" && data.resultCode[0] === "2"){
          let msg = data.errorMessage
          if(data.resultCode ==="20001"){
            msg = (i18n[this.props.locale]?i18n[this.props.locale].emailNotConfirmed:"")  + data.errorMessage
          }
          Notification.success({
            message:"",
            description:msg,
          });
        }else  if(data.resultCode === "40402"){
          Notification.error({
            message:"",
            description:this.props.locale === 'EN'? "Your password is incorrect.":"รหัสผ่านของท่านไม่ถูกต้อง",
            resultCode:"40309"
          });

        }else if(data.msgE){
          Notification.error({
            message:"",
            description:data.msgE,
            resultCode:data.resultCode
          });
        }
         this.setState({loading:false})
      }
    });
  };




  handleChangeCheckBok = name => event => {
    this.setState({ ...this.state, [name]: event.target.checked });
  };


  render() {
    const menu = <Menu style={{width:260}}
    onClick={async(e)=>{
      console.log(e.key)
      if(e.key === '0'){
        // if (this.props.conditionSocial == false) {
        //   if (this.props.locale === "EN") {
        //    Notification.error({
        //       message:"",description:`You must accept the usage agreement by clicking on the checkbox below the password form to login.`
        //     });
        //   } else {
        //     Notification.error({
        //       message:"",description:"ท่านต้องยอมรับข้อตกลงการใช้งาน ด้วยการติ๊กเครื่องหมายถูกที่ช่องสี่เหลี่ยมใต้ช่องกรอกรหัสผ่าน จึงจะเข้าสู่ระบบได้",
        //     });
        //   }
        //   return false;
        // }
        let data:any = await this.props.handleSubmitERegister(this.props.userRemember)
        console.log("data",data)

        let d = data.data ? data.data : {};
        let logindata:any = {};
        if(d.logindata && d.logindata.res && d.logindata.res.resultData){
          logindata = d.logindata.res.resultData
        }
        console.log("ReactGA.event d",d,logindata,data.resultCode , data.resultCode[0])
        let msg = (data.resultCode && data.resultCode[0] === "2" ? data.resultDescription : ( d.msg ? d.msg : (d.msgE?d.msgE:d.pageError) ) )
        let label = (data.resultCode && data.resultCode[0] === "2"?"Successfully":"Error") +" : "+d.resultCode+" $ "+ msg;
        ReactGA.event({
          category: 'Login Page',
          action: logindata.userType?logindata.userType:"",
          label: label
        });
        console.log("ReactGA.event",{
          category: 'Login Page',
          action: logindata.userType?logindata.userType:"",
          label: label
        },logindata,data)


        if(data.resultCode !=="20000" && data.resultCode[0] === "2"){
          let msg = data.errorMessage
          if(data.resultCode ==="20001"){
            msg = (i18n[this.props.locale]?i18n[this.props.locale].emailNotConfirmed:"")  + data.errorMessage
          }
          Notification.success({
            message:"",
            description:msg,
            onCancel:()=>{
              if(browserName === 'WebKit') {
                // window.location.href = window.location.origin + window.location.search +"&reload=true"
              }
            }
          });

          //browserName
        }else if(data.msgE){
         Notification.error({
           message:"",
           description:data.msgE,
           resultCode:data.resultCode
         });
       }

       if(!this.props.rememberSocial){
        // console.log("remember",this.props.remember)
        this.props.clearUserRemember()
       }

      }else if(e.key === '1'){
        this.props.clearUserRemember()
      }
    }}
    >
      <Menu.Item key="0" >
      <Icon type="user" />{this.props.userRemember.username}
      </Menu.Item>
      <Menu.Item key="1">
      <Icon type="unlock" />
       Use another account
      </Menu.Item>
    </Menu>;


    const { getFieldDecorator } = this.props.form;
    // let props = this.props;

    let buildingInfo = this.props.buildingInfo && this.props.buildingInfo.data ? this.props.buildingInfo.data :{};
    console.log("buildingInfo::data",buildingInfo,this.state.userTemp)
    return (
      <>
          {(this.state.loading)?
          <div id="login_" >
              <div style={{  paddingBottom: 50,paddingTop: 50,display: 'table', margin: '0 auto' }}>
                  <div className="loader"></div>
              </div>
              </div>
            :null}


      <div style={!this.state.loading ? {} : { display: 'none' }}>

      {
        this.state.pageType == ''?
        <>
        <UserLogin remember={this.props.remember} condition={this.props.condition} setConditionVisible={this.props.setConditionVisible}  setCondition={this.props.setCondition} setRemember={this.props.setRemember}  handleSubmitUsername={this.handleSubmitUsername} disabled={this.state.disabled} userRemember={this.props.userRemember} locale={this.props.locale}/>



        </>:<>
      <Form onSubmit={this.handleSubmit} className="login-form"  autoComplete="off" >
        {
          this.state.eregiter ?
          <div>
          {false?
            <div/>
          :<div>

          <div style={{margin: '10px 0px'}}>

              <Grid container spacing={3} alignItems="flex-end">
              <Grid item  xs={1}>
                <div className="sz-icons"><div className="icon username"></div></div>
              </Grid>
              <Grid item  xs={11}>
            <div className="MuiFormControl-root MuiTextField-root" style={{width: '100%'}}>
                <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" style={{position: 'relative'}}>{DictionaryService.msg("email",this.props.locale)}</label>
                  <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                    <input className="MuiInputBase-input MuiInput-input" id="input-with-icon-grid0" type="text" value={this.state.userTemp.username} disabled/>
                  </div>
                  </div>
              </Grid>
            </Grid>
          </div>


        {/*  <div style={{margin: '10px 0px'}}>
        {getFieldDecorator('username2', {
          initialValue: " ",
          rules: [],
        })(
            <Grid container spacing={3} alignItems="flex-end">
            <Grid item  xs={1}>
            <div className="sz-icons"><div className="icon username"></div></div>
            </Grid>
            <Grid item  xs={11}>
              <TextField id="input-with-icon-grid-2" label={DictionaryService.msg("email",this.props.locale)}  style={{width:  '100%'}}

              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                }
              }}
              disabled={this.state.disabled}
              />
            </Grid>
          </Grid>,
          )}
        </div>
          */}
        <div style={{margin: '10px 0px'}}>
        <Form.Item>
          {getFieldDecorator('password', {
            initialValue:this.props.userRemember.password || "",
            rules: [
              { required: true, message: DictionaryService.msg("vPassword",this.props.locale) }
            ],
          })(
            <Grid container spacing={3} alignItems="flex-end">
            <Grid item  xs={1}>
              <div className="sz-icons"><div className="icon password"></div></div>
            </Grid>
            <Grid item  xs={11}>
              <TextField id="input-with-icon-grid"  type="password" label={DictionaryService.msg("password",this.props.locale)}  style={{width:  '100%'}}
              inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off",
              }
            }}
              disabled={this.state.disabled}/>
            </Grid>
          </Grid>,
          )}
        </Form.Item>
        </div>


        {false?<div/>:
            <div style={{textAlign: "right",fontSize: 13,marginTop: -10}}>
             <Button id="forgot-password" type="link" ghost className="login-form-forgot" style={{color: '#fff',padding: 0}} onClick={()=>{
              this.props.changePage('forgotpassword')
            }}>
            <Translate value="forgotPassword" />
            </Button>
            </div>
        }
          </div>
        }
          </div>:null
        }



        {/* <Checkbox style={{color: '#FFF'}}><Translate value="rememberMe" /></Checkbox> */}
       <div>
      </div>


      {
        this.state.eregiter ?<div>
       {false?<div/>:
         <Form.Item>
           <div className="row" style={{margin: '10px 3px'}} >
               <div className="col-12 col-md-12" style={{padding: 0}} >
               <Button id="signin-eregister-submit" type="primary" htmlType="submit" className="login-form-button bt-singin" style={{
                  background: '#f89932',
                  borderColor: '#f89932',
                  width:  '100%',
                  borderRadius: 10}}>
                  <Translate value="signIn" />
                </Button>
               </div>
          </div>
        </Form.Item>}
        </div>:null
      }
      </Form>
              </>
            }
      </div>

      <Modal
          title={this.props.locale==='EN'?"Terms of Use":"ข้อตกลงการใช้งาน"}
          visible={this.state.conditionVisible}
          footer={null}
          onCancel={() => {
            this.setState({ conditionVisible: false });
          }}
          width={720}
          mask={true}
        >
          <Agreement  locale={this.props.locale}
          buildingInfo={buildingInfo}
          />
        </Modal>
      </>
    );
  }
}



class UserLoginForm extends Component<{setConditionVisible:any,setCondition:any,setRemember:any,handleSubmitUsername:any,form:any,disabled:any,userRemember:any,locale:any,remember:any,condition:any},{}> {
  constructor(props) {
    super(props);
    // this.state = {}
    }

    render() {
      const { getFieldDecorator } = this.props.form;
      return <div>
          <Form onSubmit={(e)=>this.props.handleSubmitUsername(e, this.props.form )} className="login-form" style={{marginTop:8,marginBottom:2}} autoComplete="off">
          <div style={{margin: '10px 0px'}}>
          <Form.Item>
            {getFieldDecorator('username', {
              initialValue:this.props.userRemember.username || "",
              rules: [{
                required: true, message: DictionaryService.msg("vEmail",this.props.locale) },{
                type: 'email',
                message: DictionaryService.msg("vEmail",this.props.locale),
              },],

            })(
              <Grid container spacing={3} alignItems="flex-end">
              <Grid item  xs={1}>
              <div className="sz-icons"><div className="icon username"></div></div>
              </Grid>
              <Grid item  xs={11}>
                <TextField id="input-with-icon-grid" label={DictionaryService.msg("email",this.props.locale)}  style={{width:  '100%'}}
                disabled={this.props.disabled}
                inputProps={{
                  autocomplete: "new-password",
                  form: {
                    autocomplete: "off",
                  }
                }}
                />
              </Grid>
            </Grid>,
            )}
          </Form.Item>
          </div>

          <Form.Item>
            <div className="row" style={{margin: '10px 3px'}} >
                <div className="col-12 col-md-12" style={{padding: 0}} >
                <Button id="signin-eregister-submit" type="primary" htmlType="submit" className="login-form-button bt-singin" style={{
                   background: '#f89932',
                   borderColor: '#f89932',
                   width:  '100%',
                   borderRadius: 10}}>
                   <Translate value="nextStep" />
                 </Button>
                </div>
           </div>
         </Form.Item>

          </Form>

          <div className="col-md-12" style={{margin: '0px 3px', marginTop: 0,color: '#BAD8FE',fontSize: 12 }}>
            *** <Translate value="mNote" /> :
          </div>
          <div className="col-md-12" style={{margin: '0px 3px',color: '#BAD8FE',fontSize: 10,marginBottom: 10 }}>
              <Translate value="mNoteEregister" />
          </div>

          <div className="col-md-12">
          <Form.Item>
                <FormControlLabel
                  label={DictionaryService.msg("msg_keep_logged_in",this.props.locale)}
                  control={<Checkbox
                  color="white"
                  checked={this.props.remember}
                  onChange={()=>{
                    this.props.setRemember()
                    //his.setState({remember:!this.state.remember})
                      //this.handleChangeCheckBok('remember')
                  }}
                  icon={<Lens style={{fontSize: 22 }}/>} checkedIcon={<LensChecked style={{fontSize: 22 }}/>}
                />}
                />
          </Form.Item>
          </div>
          {/**
          <div className="col-md-12">
          <Form.Item>
              <FormControlLabel
                label={DictionaryService.msg("msg_accept",this.props.locale)}
                control={<Checkbox
                color="white"
                checked={this.props.condition}
                onChange={()=>{
                  this.props.setCondition()
                  // this.setState({condition:!this.state.condition})
                }}
                icon={<Lens style={{ fontSize: 22 }}/>} checkedIcon={<LensChecked style={{fontSize: 22 }}/>}
              />}
              />
                <div className="MuiFormControlLabel-root">
                <a
                  onClick={() => {
                    this.props.setConditionVisible()
                    //this.setState({ conditionVisible: true });
                  }}
                  style={{
                    cursor: "pointer",
                    textDecoration:'underline',
                    color: '#F89932',
                    padding: 10,
                    position: 'relative',
                    top: 3

                  }}
                >
                  <Translate value="msg_term_of_use" />
                </a>
                </div>
          </Form.Item>


          </div>
          */}


        </div>
    }
  }




// export default connect<any, any, {}>(mapStateToProps, mapDispatchToProps)(AppThemes)

const mapStateToProps: any = (state: any) => ({
  locale: getCurrentLocale(state),
});

const mapDispatchToProps = {}
const UserLogin:any = connect<any, any, {}>(mapStateToProps, mapDispatchToProps)(Form.create({})(UserLoginForm));
const SocialLoginForm:any = connect<any, any, {}>(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'fm-register' })(SocialLogin));

export default SocialLoginForm;
