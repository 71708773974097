// import {  } from '../factory'
import { Map } from 'immutable'
import { combineReducers } from 'redux-immutable'

export interface ILanguageSelectModal extends Map<any, any> {

}

export default combineReducers({

})
