// import AppConfig  from '../services/app.config';
import { Modal, Button } from 'antd';
import React  from 'react';
import queryString from 'query-string';
const { confirm } = Modal;

class Notification {

  constructor() {

  }
  error(data:any){
    let { message , btn , key ,description="", placement ='',duration} = data
    // description = 'ชื่อผู้ใช้ของท่านได้ล็อกอินในเครื่องอื่นอยู่<br/>หากผู้ใช้ต้องการล็อกอินด้วยชื่อผู้ใช้นี้<br/>โปรดล็อกเอ้าท์ในเครื่องอื่นก่อน';
    let languageCode = 'EN'
    try {
      var haslocalStorage = !!localStorage;
        if (haslocalStorage) {
        if(localStorage.getItem('locale')){
          languageCode = localStorage.getItem('locale') || 'EN'
        }
      }
    }
    catch (e) {

    }

    // console.log("data.username",data.username);

    try {
      let params: any = queryString.parse(window.location.search);

      // if(data.username && data.username != params.mac){
      //     description = "<div>["+data.username+"]</div>" + description
      // }
      console.log("mac",params.mac);
      if(params && params.mac){
          description = "<div>["+params.mac+"]</div>" + description
      }
    }
    catch (e) {
      console.log(e);
    }

    if(data.resultCode && data.resultCode !== "20000"){
      description = "<div>["+data.resultCode+"]</div>" + description
    }





    Modal.error({
      title: <div dangerouslySetInnerHTML={{__html:description}}></div>,
      // content: 'Some descriptions',
      okText: languageCode === 'EN'?'Close':'ปิด',
      centered:true,
      okButtonProps: {
        // hidden: true,
      },
      onOk() {
        console.log('OK');
        if(data.onCancel){
          data.onCancel()
        }
      },
      onCancel() {
        console.log('Cancel');
        if(data.onCancel){
          data.onCancel()
        }

      },
    });
    return ;
  }



  success(data:any){
    let { message , btn , key ,description="", placement ='',duration} = data
    let languageCode = 'EN'
    try {
      var haslocalStorage = !!localStorage;
        if (haslocalStorage) {
        if(localStorage.getItem('locale')){
          languageCode = localStorage.getItem('locale') || 'EN'
        }
      }
    }
    catch (e) {

    }

    Modal.success({
      title: <div dangerouslySetInnerHTML={{__html:description}}></div>,
      // content: 'Some descriptions',
      okText: languageCode === 'EN'?'Close':'ปิด',
      okButtonProps: {
        // hidden: true,
      },
      onOk() {
        console.log('OK');
        if(data.onCancel){
          data.onCancel()
        }
      },
      onCancel() {
        console.log('Cancel');
        if(data.onCancel){
          data.onCancel()
        }

      },
    });
    return ;
  }

  successConfirm(data:any){
    let { message , btn , key ,description="", placement ='',duration} = data
    let languageCode = 'EN'
    try {
      var haslocalStorage = !!localStorage;
        if (haslocalStorage) {
        if(localStorage.getItem('locale')){
          languageCode = localStorage.getItem('locale') || 'EN'
        }
      }
    }
    catch (e) {

    }

    Modal.success({
      title: <div dangerouslySetInnerHTML={{__html:description}}></div>,

      okText: languageCode === 'EN'?'Close':'ปิด',
      okButtonProps: {

      },
      onOk(close) {
          data.onOk(close)
      },
      onCancel() {
        console.log('Cancel');
        if(data.onCancel){
          data.onCancel()
        }

      },
    });
    return ;
  }

  warning(data:any){
    let { message , btn , key ,description="", placement ='',duration} = data
    let languageCode = 'EN'
    try {
      var haslocalStorage = !!localStorage;
        if (haslocalStorage) {
        if(localStorage.getItem('locale')){
          languageCode = localStorage.getItem('locale') || 'EN'
        }
      }
    }
    catch (e) {

    }

    Modal.warning({
      title: description,
      okText: data.okText? data.okText: languageCode === 'EN'?'Close':'ปิด',
      // cancelText: languageCode === 'EN'?'Close':'ปิด',
      // footer:data.footer?<></>:<></>,
      // maskClosable:true,
      okButtonProps: {
      },
      onOk() {
        console.log('OK');
        if(data.okText){
            data.onOk()
        }else{
          if(data.onCancel){
            data.onCancel()
          }
        }

      },
      onCancel() {
        console.log('Cancel');
        if(data.onCancel){
          data.onCancel()
        }

      },
    });
    return ;
  }


  open(){

  }

  confirm(data:any){
    let { message , btn , key ,description="", placement ='',duration} = data
    let languageCode = 'EN'
    try {
      var haslocalStorage = !!localStorage;
        if (haslocalStorage) {
        if(localStorage.getItem('locale')){
          languageCode = localStorage.getItem('locale') || 'EN'
        }
      }
    }
    catch (e) {

    }

    Modal.confirm({
      title: description,
      okText: data.okText? data.okText: languageCode === 'EN'?'Close':'ปิด',
      cancelText: data.cancelText? data.cancelText: languageCode === 'EN'?'Close':'ปิด',
      // cancelText: languageCode === 'EN'?'Close':'ปิด',
      // footer:data.footer?<></>:<></>,
      // maskClosable:true,
      // <Icon type="exclamation" />
      icon:'exclamation-circle',
      okButtonProps: {
      },
      onOk(close) {
        if(data.okText){
            data.onOk(close)
        }else{
          if(data.onCancel){
            data.onCancel()
            close()
          }
        }
      },
      onCancel() {
        console.log('Cancel');
        if(data.onCancel){
          data.onCancel()
        }

      },
    });
    return ;
  }
}


export default new Notification();
