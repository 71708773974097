import React,{Component}  from 'react';
  class Tooltip extends Component<{text:any,children:any,style:any}, {active:any,hover:any}> {
    constructor(props) {
      super(props);
      this.state = {
        active:false,
        hover:false
      };
      this.handleMouseEnter = this.handleMouseEnter.bind(this)
      this.handleMouseLeave = this.handleMouseLeave.bind(this)
    }

    handleMouseEnter(){
      this.setState({hover:true})
    }

    handleMouseLeave(){
      this.setState({hover:false})
    }


    render() {
      // console.log(this.props.text)
        return (
          <>
            <div>
            <div className={this.state.active || this.state.hover?"tooltip-sc active":"tooltip-sc"} onClick={()=>{
              if(this.state.active){
                this.setState({hover:false})
              }

              this.setState({active:!this.state.active})
            }}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}

            style={this.props.style}>
                {this.props.children}
              <span className="tooltiptext">{this.props.text()}</span>
            </div>

            </div>
          </>
        );
    }
  }


export default Tooltip
