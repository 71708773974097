
import React,{}  from 'react';
import moment from 'moment'
import Request  from '../../../../services/request.service';
declare let $: any;
declare let window: any;

let ad_img_default = {
  w:"https://smartrms.s3.ap-southeast-1.amazonaws.com/f5afa850-9908-11eb-be54-3d45d7813b6adownload.png",
  x:"https://smartrms.s3.ap-southeast-1.amazonaws.com/f5afa850-9908-11eb-be54-3d45d7813b6adownload.png",
  y:"https://smartrms.s3.ap-southeast-1.amazonaws.com/068ecf70-9909-11eb-be54-3d45d7813b6adownload%20%281%29.png",
  z:"",

}
class AdCustom extends React.Component<{advertisement:any, type:any ,buildingInfo:any,setPopupAds:any},{open:any}>{
  constructor(props) {
    super(props);
    this.state = {
      open:false
    }

  }

   static defaultProps = {
     setPopupAds:()=>{
     }
   }

  componentDidMount () {
    setTimeout(() => {
      this.setState({open:true},()=>{
         // console.log("this.props.type",this.props.type);
         this.setOverflow()
      })
      this.renderHtml(this.props)
      setTimeout(()=>{
        this.setState({open:false})
      },5000)
    }, 200);
  }


  componentWillUpdate (nextProps, nextState) {}

  renderHtml(nextProps){
    let item:any = {}
    try{
      item =  nextProps.advertisement ? nextProps.advertisement[nextProps.type]:{}
      console.log("item",item)
      this.adsView(item)
    }catch(e){
      console.log(e)
    }

  }

  setOverflow(){
    let item:any = null
    try{
      item =  this.props.advertisement ? this.props.advertisement[this.props.type]:null
    }catch(e){

    }
    // console.log("setOverflow",item);
    if(item && this.props.type === 'z'){
        document.getElementsByTagName("body")[0].style['overflow-y'] = `hidden`;
    }
  }


adsClick(item){
  if(!item || item.adsId === 'default'){
    return ;
  }
  let points = [{value:1 ,name:'w'},{value:2 ,name:'x'},{value:3 ,name:'y'},{value:4 ,name:'z'}];
  let point:any = null;
  for(let i in points){
    if(points[i].name === this.props.type){
      point = points[i].value
    }
  }
  console.log("item???",item);

  Request.post("/login/adsClick", {
    adsId:item.adsId,
    adsName:item.adsName,
    point:point,
    custGroupId:this.props.buildingInfo.custgroupId
  }, {
    "x-access-id": this.props.buildingInfo.accessToken
  }).then((res: any) => {

  });
  if(item.redirectUrl){
      window.open(item.redirectUrl, "_blank")
  }
}

adsView(item){
  if(!item || item.adsId === 'default'){
    return ;
  }

  let points = [{value:1 ,name:'w'},{value:2 ,name:'x'},{value:3 ,name:'y'},{value:4 ,name:'z'}];
  let point:any = null;
  for(let i in points){
    if(points[i].name === this.props.type){
      point = points[i].value
    }
  }
  console.log(
    "item???",item
  );

  Request.post("/login/adsView", {
    adsId:item.adsId,
    adsName:item.adsName,
    point:point,
    custGroupId:this.props.buildingInfo.custgroupId
  }, {
    "x-access-id": this.props.buildingInfo.accessToken
  }).then((res: any) => {

  });
  // if(item.redirectUrl){
  //     window.open(item.redirectUrl, "_blank")
  // }
}

render() {
  // let {id,keyData,mode } = this.props
  let item:any = null
  try{
    item =  this.props.advertisement ? this.props.advertisement[this.props.type]:null
    // console.log("item",item);

  }catch(e){

  }




  let type = "";
  try{
    if(item.url){
      let url = item.url
      type = url.substring(url.length-3,url.length)
    }

  }catch(e){

  }


  if(!item){
    if(this.props.type === 'w'&& ad_img_default.w){
      item = {
        adsId:'default',
          url: ad_img_default.w
        }
    }else if(this.props.type === 'x'&& ad_img_default.x){
      item = {
        adsId:'default',
          url:  ad_img_default.x
      }
    }else if(this.props.type === 'y'&& ad_img_default.y){
      item = {
        adsId:'default',
          url:  ad_img_default.y
      }
    }else if(this.props.type === 'z' && ad_img_default.z){

      item = {
        adsId:'default',
        url:  ad_img_default.z
      }
      // document.getElementsByTagName("body")[0].style['overflow-y'] = `hidden`;
    }else{
      item = {
      }
    }

      // console.log("item???",item,"this.props.type",this.props.type);

  }

  // console.log(":item",this.props.type,item)
  return  <>
  {item && item.adsId?
  <>
  {

    this.props.type === 'z'?<>
    {
      this.state.open?
      <div className="ads-mask" onClick={()=>{
          this.setState({ open:false},()=>{
             this.props.setPopupAds(true)
          })
        }}>
        {
          type ==='mp4'?<>
          <video src={item.url} width="100%" height="350" autoPlay loop controls muted style={{position: 'initial',width: '100% !important'}}>

          </video>
          </>:<img src={item.url} onClick={()=>{
              this.adsClick(item)
              this.props.setPopupAds(true)
          }} style={{width:"100%"}} />}
        </div>:null
    }
    </>:<>

    {
      type ==='mp4'?<>
      <video src={item.url} width="100%" height="350" autoPlay loop controls muted style={{position: 'initial',width: '100% !important'}}>

      </video>
      </>:<img src={item.url} onClick={()=>{
          this.adsClick(item)
      }} style={{width:"100%"}} />}


    </>
  }
  </>:null}



  </>
  }
}


export default AdCustom;
