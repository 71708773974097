import React, { Component } from 'react';
import { Route, Link } from "react-router-dom";
import { Translate } from 'react-redux-i18n'
import queryString from 'query-string';
import Notification  from '../services/notification.service';
import Request  from '../services/request.service';
declare let FB: any;
type AuthLineProps = {

  locale:any,
  history:any,
  location:any,
  loginfacebook:any
}

type AuthLineState = {

}

class AuthLine extends Component<AuthLineProps, AuthLineState> {

  constructor(props) {
     super(props);
     this.state = {};

  }

componentDidMount() {
  let params = queryString.parse(this.props.location.search);
  let state:any = params.state || ''
  let code:any = params.code || ''

  let arr = state.split(",")
      arr.push("social=line")
      arr.push(`code=${code}`)

      // console.log("arr.join",arr.join("&"))
    this.props.history.push({ pathname:"/", search: arr.join("&")});
}


  render() {

    return (
      <>
      <div className="app-main" id="app-main">
      <div id="login_" style={{position: "absolute",left:" 50%", top:"50%"}} > <div style={{ paddingTop: 0, display: 'table', margin: '0 auto' }}> <div className="loader"></div> </div> </div>
      </div>
      </>)
  }
}


export default AuthLine
