import EN from './EN'
import TH from './TH'

let msg_ = [
  {
    text:"Unsuccessful login Please try again",
    en:"Unsuccessful login Please try again, If login is not successful many times please contact call center tel:",
    th:"ล็อกอินไม่สำเร็จ กรุณาลองใหม่อีกครั้ง หากล็อกอินไม่สำเร็จหลายครั้ง กรุณาติดต่อผู้ดูแลระบบ โทร.",
    code:"C50010"
  },

  {
    text:"Your maximum never usage time has been reached",
    en:"Your maximum usage time has been reached",
    th:"เวลาการใช้งานของท่านหมดแล้ว",
    code:"C40103"
  },
  {
    text:"You are already logged in",
    en:"You are already logged in on another device",
    th:"ท่านไม่สามารถ login ได้เนื่องจาก login เครื่องอื่นอยู่",
    code:"C40111"
  },
  {
    text:"You are already logged in - access denied",
    en:"You are already logged in on another device",
    th:"ท่านไม่สามารถ login ได้เนื่องจาก login เครื่องอื่นอยู่",
    code:"C40111"
  }
]
class DictionaryService {
  msg(word,locale){
    try {
      if(locale === 'TH'){
        return TH[word]
      }else{
        return EN[word]
      }
    } catch (e) {
      console.log(e);
      return ''
    }
  }


    // if (this.props.locale === "EN") {
    //   msg = data.message || "Unsuccessful login Please try again."
    // } else {
    //   msg = data.message || "ล็อกอินไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
    // }



  msgChilli(word,locale,phone = ""){
    try {

      let data:any =  null;
      for (let index = 0; index < msg_.length; index++) {
        const element = msg_[index];
        if(element.text === word){
          data = element;
          break;
        }
      }

      if(!data){
        data = msg_[0];
      }

      if(locale === 'TH'){
        if(data.code === "C50010"){
          return data.th + (phone?phone:"02-026-1922")
        }
        return data.th
      }else{
        if(data.code === "C50010"){
          return data.en + (phone?phone:"02-026-1922")
        }
        return data.en
      }
    } catch (e) {
      console.log(e);
      return ''
    }
  }


  msgChilliCode(word,code){
    try {

      let data:any =  null;
      for (let index = 0; index < msg_.length; index++) {
        const element = msg_[index];
        if(element.text === word){
          data = element;
          break;
        }
      }
      // msg_.find(r=>r.text === word)


      if(!data){
        if(code==="20000"){
          return msg_[0].code;
        }
        return code;
      }
       return data.code

    } catch (e) {
      console.log(e);
      return ''
    }
  }

}
export default new DictionaryService();

