import React  from 'react';
const Loading: React.SFC<{}> = () => (
  <div
   id="form_login_loading"
    style={{
      height: "100vh",
      width: "100%",
      textAlign: "center",
      position: "relative"
    }}
  >
    <div
      className="loader"
      style={{ top: "50%", left: "50%", position: "absolute" }}
    />
  </div>
);

export default Loading;
