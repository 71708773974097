import { ActionsUnion } from '../types'
import { createAction } from '../utils'
import { otpModel } from "../actionTypes";
export const actions = {
  setOTPData: (otp) => {
    return createAction(otpModel.SET_OTPDATA, otp);
  }
};

export type Actions = ActionsUnion<typeof actions>
