import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import configureStore from './data'
import * as serviceWorker from './serviceWorker';
// import * as Sentry from '@sentry/browser';

ReactDOM.render(<Provider store={configureStore()}>
  <Router >
    <App />
  </Router>
</Provider>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
