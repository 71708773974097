import languageSelectModal, {
  ILanguageSelectModal,
} from './languageSelectModal/reducer'
import configModel, { IConfigModel } from "./configModel/reducer";
import otpModel, { IOtpModel } from "./otpModel/reducer";
import { i18nReducer } from 'react-redux-i18n'
import { combineReducers } from 'redux'

export interface IAppState {
  i18n: typeof i18nReducer;
  languageSelectModal: ILanguageSelectModal;
  configModel: IConfigModel;
  otpModel: IConfigModel;
}

export default combineReducers({
  i18n: i18nReducer,
  languageSelectModal,
  configModel,
  otpModel,
});
