import React, { Component ,useState, useEffect, useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Person from '@material-ui/icons/Person';
import { Menu, Dropdown, Icon,Button } from 'antd';
import { Link } from "react-router-dom";
import AppConfig  from '../../../../services/app.config';
const Header = (props) =>{


  let buildingInfo = props.buildingInfo && props.buildingInfo ? props.buildingInfo : {}
  // user.isShowIFrame
  // console.log("props:::",props.user.isShowIFrame)
    return <header >
      <div className="navbar navbar-dark bg-main box-shadow">
        <div className="container d-flex justify-content-between">

          <a  className="navbar-brand d-flex align-items-center" onClick={async ()=>{
            let config: any = await AppConfig.getConfig();
            setTimeout(() => {
                window.location.href = `http://${config.chilli_host}:${config.chilli_port}`;
            }, 0);
          }} >
            {
              buildingInfo.logoHeader?<>
              <img src={buildingInfo.logoHeader} alt="Logo" className="brand"
                 style={{marginTop: 0}} />
              </>:
            <>
            {
              buildingInfo.loginAlphaboxFlag === 1 ?<>
                <img src={require('../../../../assets/images/alpha-box/logo2.png')} alt="Logo" className="ab-img brand"
                 style={{width: 110,marginTop: 0}} />
                <img src={require('../../../../assets/images/alpha-box/logo3.png')} alt="Logo" className="ab-img brand-min"
                  style={{width: 35,marginTop: 0}} />
              </>:<>
              {
                buildingInfo.custgroupId?<>
                    <img src={require('../../../../assets/images/header_logo.png')} alt="Logo" className="brand" />
                    <strong  className="header">Supported by SmartZone</strong>
                  </>:null
              }
              </>
            }
            </>}

          </a>
          <div>
          { props.user && (buildingInfo.enableIframeLoginSuccess && buildingInfo.iframeLoginSuccessUrl && (props.user.isShowIFrame))?
            <Button onClick={()=>{
                  props.history.push({ pathname:"/user-info-iframe", search: props.location.search});
              }}  className="" style={{borderRadius: 30,marginRight: 10}}>
                {/*props.locale ==="TH"?<span>ไป Donedeal.shop</span>:<span>Go Donedeal.shop</span>
                 <img  src={require('../../../../assets/images/dd-icon.png')} style={{height: 12,width: 70,marginTop: -3}} />
                >*/}
                  <span>
                    {props.locale ==="TH"?<span>ข้อมูลการเปลี่ยนเส้นทาง</span>:<span>Redirect URL</span>}
                  </span>
              </Button>
              :null}

          <div className="dropdown" style={{lineHeight: "50px"}}>
          <a id="lang-th"  onClick={()=>{props.setLocaleData('TH')}}
          style={{cursor : 'pointer',color:props.locale!=='TH'?'#000':'#fff'}}>TH</a>
          <span style={{fontStyle:'italic',padding: 6}}>|</span>
          <a id="lang-en"  onClick={()=>{props.setLocaleData('EN')}}
          style={{cursor : 'pointer',color:props.locale!=='EN'?'#000':'#fff'}}>EN</a>
          </div>
          <ButtonMenu buildingInfo={buildingInfo} user={props.user} locale={props.locale} location={props.location} history={props.history} socialTypeList={props.socialTypeList}/>


          </div>
        </div>
      </div>
  </header>
}

class ButtonMenu  extends Component<{buildingInfo:any,socialTypeList:any,locale:any,user:any,history:any,location:any}, {isOpen:any,hover:any}> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen:false,
      hover:false
    };
 }

 componentDidMount() {
  document.addEventListener("mousedown", this.handleClickOutside);
}

componentWillUnmount() {
  document.removeEventListener("mousedown", this.handleClickOutside);
}

myRef:any = React.createRef();

handleClickOutside = e => {
  if (!this.myRef.current.contains(e.target)) {
    this.setState({ hover: false });
  }
};

  render() {
    // console.log("xxx",this.props.user)
    return (<>
        <div ref={this.myRef}  className={this.state.hover  ?"dropdown menu hover":"dropdown menu "} style={{float:'left'}} >
        <IconButton
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={()=>{
                  this.setState({hover:!this.state.hover})
                }}
                color="inherit"
              >
          <Person />
        </IconButton>
      <div className="dropdown-content"  >

      { this.props.user && this.props.user.isSocialMember?
          <a  onClick={()=>{
            this.setState({hover:false})
            this.props.history.push({ pathname:"/edit-profile", search:this.props.location.search});
          }}  className="menu-profile">
            <Icon type="edit" style={{position: 'relative',top: -3,left: -3}}/> {this.props.locale ==="TH"?<span>ข้อมูลส่วนตัว</span>:<span>Profile</span>}
          </a>:null }

      { (this.props.user && this.props.user.isSocialMember && this.props.socialTypeList.indexOf(1) > -1)?
          <a  onClick={()=>{
            this.setState({hover:false})
            this.props.history.push({ pathname:"/change-password", search:this.props.location.search});
          }}  className="menu-profile">
            <Icon type="lock" style={{position: 'relative',top: -3,left: -3}}/> {this.props.locale ==="TH"?<span>เปลี่ยนรหัสผ่าน</span>:<span>Change Password</span>}
          </a>:null}
      { !this.props.user ||  (this.props.user && !this.props.user.isSocialMember)?
          <a onClick={()=>{
            this.setState({hover:false})
            this.props.history.push({ pathname:"/usage-history", search:this.props.location.search});
          }} className="menu-profile">
            <Icon type="history" style={{position: 'relative',top: -3,left: -3}}/> {this.props.locale ==="TH"?<span>ข้อมูลการใช้งาน</span>:<span>Usage History</span>}
          </a>:null}




              {/*
                _buildingInfo.enableIframeLoginSuccess && _buildingInfo.iframeLoginSuccessUrl?
                <div style={{position: 'fixed',top: 10,right: 15,zIndex: 1000}}>
                <Button type="primary" icon="shrink" size={'small'} onClick={()=>{
                      this.props.history.push({ pathname:"/user-info-iframe", search: this.props.location.search});
                }} style={{border: 'transparent',backgroundColor: 'transparent',color: 'black',height: 30,boxShadow: 'none'}}/>
                </div>
                :null
              */}


      </div>
    </div>





        {/* </Dropdown> */}
        {/* </div> */}
    </>)
  }
}


export default Header;
