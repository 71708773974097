import React, { Component } from 'react';
import Request  from '../../../services/request.service';
import { Translate } from 'react-redux-i18n'
import { getCurrentLocale } from "../../../data/utils";
import { setLocale } from 'react-redux-i18n'
import { Button ,Form  } from 'antd';
import { connect } from 'react-redux'
import DictionaryService  from '../../../i18n/DictionaryService';
import Notification  from '../../../services/notification.service';
import i18n from "../../../i18n/index";
import { init } from '@sentry/browser';
import queryString from 'query-string';
import AppConfig  from '../../../services/app.config';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Header from './components/Header';
import '../../../assets/css/new-theme/index.scss';
import IconButton from '@material-ui/core/IconButton';
import PriorityHigh from '@material-ui/icons/ErrorOutline';
import Tooltip from "./components/Tooltip"
import ReactGA from 'react-ga';
declare let FB: any;
declare let chilliController: any;
declare let location: any;


type MemberForgotProps = {
  loginInfo: any,
  user: any,
  locale:any,
  location:any
  form:any,
  setLocaleCustom:any
}

type MemberForgotState = {
  data:any,
  dataInit:any,
  newPassword:string,
  confirmPassword:string
  v_confirmPassword:any,
  v_newPassword:any
  loding:any
}


const trackPage = (page) => {
  ReactGA.set({
    page,
  });
  ReactGA.pageview(page);
  ReactGA.set({ contentGroup3: "SmartZone™" ,contentGroup2:"Member Forgot" });
};

class MemberForgotPage extends Component<MemberForgotProps, MemberForgotState> {
  constructor(props) {
    super(props);
    this.state = {
      data:false,
      dataInit:false,
      newPassword:'',
      confirmPassword:'',
      v_confirmPassword:{},
      v_newPassword:{},
      loding:false
    }
    let params: any = queryString.parse(this.props.location.search);
    if(params.language){
      this.props.setLocaleCustom(params.language)
    }

  }

  componentDidMount() {
    const page = this.props.location.pathname;
    trackPage(page);
  }

  componentWillReceiveProps(nextProps) {
    const currentPage = this.props.location.pathname;
    const nextPage = nextProps.location.pathname;
    console.log("nextPage",nextPage)
    if (currentPage !== nextPage) {
      trackPage(nextPage);
    }
  }

  async redirectChilli() {
    let config: any = await AppConfig.getConfig();
      window.location.href = `http://${config.chilli_host}:${config.chilli_port}/logoff`;
  }

  init(){
    let params: any = queryString.parse(this.props.location.search);
    this.setState({data:{}})
    Request.post("/register/getconfirmForgotPassword", {
      email:params.email,
      confirmCode:params.key
    })
      .then((res: any) => {
        if (res.resultCode && res.resultCode[0] === "2") {
          this.setState({dataInit:res.errorMessage })
        }else{
          Notification.error({
            message:"",
            description:res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
            resultCode:res.resultCode
            });
        }
        }).catch(e => {
          console.log(e);
         Notification.error({
          message:"",
          description:e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
          });

        });
  }


  postData(data){
    let params: any = queryString.parse(this.props.location.search);
    this.setState({data:false,loding:true})
    Request.post("/register/confirmForgotPassword", {
      email:params.email,
      confirmForgotCode:params.key,
      password:data.password,
      flagConfirm:0
    })
      .then((res: any) => {
        this.setState({loding:false})
        if (res.resultCode && res.resultCode[0] === "2") {// === "20000"
          this.setState({data:res.errorMessage })
        }else{
          Notification.error({
            message:"",
            description:res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
            resultCode:res.resultCode
            });
        }
        }).catch(e => {
          console.log(e);
          this.setState({loding:false})
         Notification.error({
          message:"",
          description:e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
          });

        });
  }



  async ChilliLogOff() {
    return new Promise(async resolve => {
      let config: any = await AppConfig.getConfig();
      let params: any = queryString.parse(this.props.location.search);
      let chilli_https =  params.c === "1" || params.c === 1 ? true :false;
      if (location.protocol != 'https:'){
        chilli_https = false;
      }
      if(!chilli_https){
        chilliController.host = config.chilli_host;
        chilliController.port = config.chilli_port;
      }else{
        chilliController.host = config.chilli_hosts;
      }
      chilliController.interval = 60;
      chilliController.ssl = chilli_https;
      chilliController.onError = err => {
        return resolve(false);
      };
      chilliController.onUpdate = res => {
        let data = chilliController;
        return resolve(data);
      };
      chilliController.logoff();
    });
  }

handleSubmit = e => {
  e.preventDefault();
  this.handleConfirmPasswordChange(this.state.confirmPassword)
  this.handleNewPasswordChange(this.state.newPassword)
  this.props.form.validateFields(async (err, user) => {

    if (!err && this.state.v_confirmPassword.validateStatus==='success' && this.state.v_newPassword.validateStatus==='success'  ) {
        console.log(user)
        this.postData({password:this.state.newPassword})
        return false;
      }
    });
  }

  validatePassword(data) {
    if(!data && this.state.newPassword){
      return {
        validateStatus: 'error',
        errorMsg: DictionaryService.msg("vConfirmPassword",this.props.locale),
      };
    }

    if (data === this.state.newPassword || !this.state.newPassword) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }



    console.log(DictionaryService.msg("vConfirmPasswordNotMatch",this.props.locale))
    return {
      validateStatus: 'error',
      errorMsg: DictionaryService.msg("vConfirmPasswordNotMatch",this.props.locale),
    };
  }

  validatePasswordRegex(data,type ='') {
    let regex = /^[a-zA-Z0-9!@#$&()\-.+,/*_:<=>?@\[\]^`{}|~]*$/
    console.log("regex",regex,regex.test(data),data,data.length )
    if(data.length <= 0 ){
      if(type === 'confirm'){
        return {
          validateStatus: 'error',
          errorMsg:  DictionaryService.msg("vConfirmPassword",this.props.locale),
        }
      }
      return {
        validateStatus: 'error',
        errorMsg:  DictionaryService.msg("vPassword",this.props.locale),
      }
    }else if(data.length < 4 || data.length > 16 ){
      return {
        validateStatus: 'error',
        errorMsg:  DictionaryService.msg("errOldNewPasswordMin",this.props.locale),
      }
    }else if(!regex.test(data)){
      return {
        validateStatus: 'error',
        errorMsg:  DictionaryService.msg("errOldNewPasswordRegex",this.props.locale),
      }
    }

    return {
      validateStatus: 'success',
      errorMsg: null,
    };
  }

  handleConfirmPasswordChange = ( confirmPassword ) => {
    setTimeout(() => {
        let temp = this.validatePasswordRegex(confirmPassword ,'confirm')
        console.log("temp",temp)
        if(temp.validateStatus === 'error'){
          this.setState({
            v_confirmPassword: {...
              {...temp},
              value:confirmPassword
            },
          });
          return;
        }

        // setTimeout(() => {
          let { newPassword } = this.state;
            this.setState({
              v_confirmPassword: {
                ... {...this.validatePassword(confirmPassword)},
                value:confirmPassword
              },
              v_newPassword: {
                ... {...this.validatePassword(newPassword)},
                value:newPassword
              },
            });

        // }, 25);
    }, 25);
  };

  isDisabledBT(){
    console.log( this.props.form.getFieldsError() ,this.props.form.isSubmitting())
    return !( this.state.v_confirmPassword.validateStatus==='success' && this.state.v_newPassword.validateStatus==='success')
  }

  handleNewPasswordChange = (newPassword) => {
    // let pass = true;
    setTimeout(() => {
        let temp = this.validatePasswordRegex(newPassword)
        if(temp.validateStatus === 'error'){
          this.setState({
            v_newPassword: {...
              {...temp},
              value:newPassword
            },
          });
          return;
        }
        // setTimeout(() => {
          let { confirmPassword } = this.state;
          this.setState({
            v_newPassword: {
              ... {...this.validatePassword(newPassword)},
              value:newPassword
            },
            v_confirmPassword:{
              ... {...this.validatePassword(confirmPassword)},
              value:confirmPassword
            }
          });

      // }, 25);
    }, 25);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
        <div>
          <div className="app-main alphabox" id="page" >
            <Header {...this.props}
            setLocaleData ={(languageCode)=> {
              this.props.setLocaleCustom(languageCode);
            }}
            />
            <main role="main" >
            <div className="album py-5">
            <div className="container ">
            <div className="row app-main-container">
            <div className="col-md-6 offset-md-3 main-form" >
            <div className="row">
              <div className="col-12" >
              <div id="wrapper">
              <div id="content">
                <div id="login-" className="bg_login">
                <div className="bt-sz-back fix"  onClick={()=>this.redirectChilli()}  >
                  <Translate value="back" />
                  </div>

                  <div id="form_login" style={{marginTop:15}}>
                    <div >
                    <Form onSubmit={this.handleSubmit} className="login-form" >
                      <div id="form_login" style={!this.state.data ? {} : { display: 'none' }}>
                      <div className="row">
                      <div className="col-12 header-text" >
                              <Translate value="memberForgot" />
                              </div>
                         <div className="col-12" >

                      <Form.Item
                          validateStatus={this.state.v_newPassword.validateStatus}
                          help={this.state.v_newPassword.errorMsg || ''}
                      >
                        {/* {getFieldDecorator('password', {
                            initialValue:"",
                            rules: [{ required: true, message: DictionaryService.msg("vNewPassword",this.props.locale) }],
                          })( */}
                            <Grid container spacing={3} alignItems="flex-end">

                            <Grid item  xs={11}>
                              <TextField id="input-with-icon-grid"
                              label={DictionaryService.msg("newPassword",this.props.locale)}
                              style={{width:  '100%'}}
                              value={this.state.newPassword}
                              type="password"
                              onChange={(e)=>{
                                let value = e.target.value
                                this.setState({ newPassword:value})
                                this.handleNewPasswordChange(value)
                              }}
                              />
                            </Grid>
                            <Grid item  xs={1}>
                            {/* <Form.Item> */}
                            <Tooltip text={()=> {
                              return <>
                               <h5 style={{marginBottom: 0}}> <Translate value="passwordInfo1" /> </h5>
                                <Translate value="passwordInfo2" /> <br/>
                                <Translate value="passwordInfo3" /> <br/>
                                <Translate value="passwordInfo4" /> <br/>
                                <Translate value="passwordInfo5" />
                              </>
                            }} style={{margin: -15}}>
                            <IconButton aria-label="delete"  size="small">
                                  <PriorityHigh style={{fontSize: 15,color:'#fff' }}/>
                            </IconButton>
                            </Tooltip>
                            {/* </Form.Item> */}
                            </Grid>
                          </Grid>

                            {/*    ,
                          )} */}
                        </Form.Item>


                        </div>
                        <div className="col-12">
                        <Form.Item
                          validateStatus={this.state.v_confirmPassword.validateStatus}
                          help={this.state.v_confirmPassword.errorMsg || ''}
                          >

                        <Grid container spacing={3} alignItems="flex-end">

                            <Grid item  xs={11}>
                              <TextField id="input-with-icon-grid"
                              label={DictionaryService.msg("confirmPassword",this.props.locale)}
                              style={{width:  '100%'}}
                              value={this.state.confirmPassword}
                              type="password"
                              onChange={(e)=>{
                                let value = e.target.value
                                this.setState({ confirmPassword:value})
                                this.handleConfirmPasswordChange(value)
                              }}
                              />
                            </Grid>
                          </Grid>
                        </Form.Item>
                        </div>
                        <div className="col-12 text-center" style={{marginTop:30}}>
                        <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button  bt-singin"
                        disabled={this.state.loding || this.isDisabledBT()}

                        style={{
                          background: '#f89932',
                          borderColor: '#f89932',
                          width: '265px'}}>
                           <Translate value="submit" />
                        </Button>
                        </Form.Item>
                        </div>
                        {/* <div className="col-6 text-center"  style={{marginTop:10}}>
                          <Form.Item>
                          <Button className="bt-singin-other" onClick={()=>this.redirectChilli()} style={{color: '#fff',width:'100%'}}>
                            <Translate value="back" />
                          </Button>
                        </Form.Item>
                        </div> */}
                      </div>

                          </div>
                      </Form>

                      <div style={this.state.data ? {} : { display: 'none' }}>
                      <div className="row">
                        <div className="col-12" style={{marginTop: 15}}>
                              <div style={{textAlign:'center'}}>
                              {this.state.data?
                              <p style={{fontSize:20,color:'#fff'}}>
                                {this.state.data}
                                {/* ทำรายการสำเร็จ */}
                              </p>:null}
                              <Button className="login-form-button bt-singin" id="forgotpassword-confirm-submit" onClick={()=>this.redirectChilli()} style={{marginTop: 15,width:"100%",color:'#fff'}}>
                              <Translate value="login" />
                              </Button>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
               </div>
            </div>






            </div>
            </div>
            </div>
            </div>
          </main>
          </div>
        </div>
    );
  }
}

const mapStateToProps: any = (state: any) => ({
  locale: getCurrentLocale(state),
});

const setLocaleCustom = (res)=>{
  try {
    let haslocalStorage = !!localStorage;
    if (haslocalStorage) {
          localStorage.setItem('locale', res);
        }
      }
  catch (e) {
  }
return setLocale(res)
}
const mapDispatchToProps = {
setLocaleCustom,
}
const MemberForgotPage_:any = connect<any, any, {}>(mapStateToProps, mapDispatchToProps)(Form.create({})(MemberForgotPage));

export default MemberForgotPage_
