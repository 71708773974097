import React  from 'react';
const Error: React.SFC<{}> = () => (
  <div
    style={{
      height: "100vh",
      width: "100%",
      textAlign: "center",
      position: "relative"
    }}
  >
    <div
      style={{ top: "50%",textAlign:'center' , position: "absolute", fontSize: 20,    width: '100%' }}
    >
      404 not found
    </div>
  </div>
);
export default Error;
