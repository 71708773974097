import i18nDictionary from '../i18n'

import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n'

// import { actions } from "data/languageSelectModal/action";
import { actions } from "./configModel/action";

import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'

import rootReducer from './rootReducer'

const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware, thunk]
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default () => {
  function* saga() {
    yield 'hello world'
  }

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middlewares)),
  )

  sagaMiddleware.run(saga)

  const { language } = navigator
  let languageCode:any = language.slice(0, 2).toUpperCase()

  // initialize react-redux-i18n
  syncTranslationWithStore(store)
  store.dispatch<any>(loadTranslations(i18nDictionary))


      try {
        var haslocalStorage = !!localStorage;
          if (haslocalStorage) {
          if(localStorage.getItem('locale')){
            languageCode = localStorage.getItem('locale') || 'EN'
          }
        }
      }
      catch (e) {

      }


  store.dispatch<any>(setLocale(languageCode))


  import("../config/" + process.env.REACT_APP_ENV + ".json")
    .then(res => {
      store.dispatch<any>(actions.setConfig(res.default));
    })



  return store
}
