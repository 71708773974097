import { Dictionary } from './Dictionary'

const TH: any = {
  'redirectRegisAlphabox':'หากท่านต้องการลงทะเบียน Alpha Box กรุณากรอกรหัสยืนยันของท่านซึ่งสามารถดูได้จากคู่มือติดตั้ง Alpha Box เพื่อทำการลงทะเบียน',
  'redirectRegisAlphabox2':'*สามารถกรอกรหัสยืนยันได้เฉพาะเจ้าของ Alpha Box เท่านั้น',
  'msg_connect': 'ขณะนี้ท่านกำลังเชื่อมต่ออยู่กับเครือข่ายของ',
  'msg_connect2': 'หากท่านต้องการใช้งานอินเตอร์เน็ต สามารถติดต่อสอบถามได้ที่ ',
  'nav_information': 'ข้อมูลการใช้งาน',
  'nav_assistance': 'โปรแกรมช่วยเหลือ',
  'nav_discussion': 'แจ้งปัญหา/แนะนำบริการ',
  'msg_support_time': 'ตลอด 24 ชั่วโมง',
  'msg_keep_logged_in': 'จดจำชื่อผู้ใช้และรหัสผ่าน',
  'msg_accept': 'ยอมรับเงื่อนไข',
  'msg_term_of_use': 'ข้อตกลงการใช้งาน*',
  'msg_number_line': 'จำนวนสายที่มี',
  'msg_status_normal': 'ปกติ',
  'msg_status_error': 'ไม่ปกติ',
  'click_other_logout': 'คลิกเพื่อให้ผู้ใช้อีกเครื่องออกจากระบบ',
  "new_users":"ผู้ใช้งานใหม่",
  'buy_card': 'ซื้อบัตร',
  'read': 'อ่าน',
  'none_building':'บราวเซอร์พื้นฐานโทรศัพท์ของท่านไม่รองรับกับหน้าเว็บไซต์​นี้ กรุณาเปิด ​Google ​Chrome​ ตามด้วยพิมพ์​ 2.2.2.2​ เพื่อเข้าสู่หน้า​ login',
  'errAgreement': 'ท่านต้องยอมรับข้อตกลงการใช้งาน ด้วยการติ๊กเครื่องหมายถูกที่ช่องสี่เหลี่ยมใต้ช่องกรอกรหัสผ่าน จึงจะเข้าสู่ระบบได้',
  'errBlankUserPass': 'โปรดกรอกชื่อผู้ใช้และรหัสผ่าน',
  'errUserPassIncorrect': 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
  'errInvalidCustGroup': 'บัตรของท่านได้ซื้อมาจากสถานที่อื่น บัตรจะสามารถถูกใช้งาน ณ สถานที่ที่ซื้อมาเท่านั้น',
  'errFreeTestDisabled': 'ไม่สามารถใช้ชื่อผู้ใช้สำหรับทดสอบฟรีที่นี่',
  'errActiveExpireDate': 'บัตรของท่านครบจำนวนวันที่อนุญาตให้ใช้งานแล้ว',
  'errCardExpireDate': 'บัตรของท่านหมดอายุแล้ว',
  'errSmartZoneFreetest': 'ไม่สามารถดูเวลาการใช้งานของชื่อผู้ใช้สำหรับทดสอบฟรี',
  'errLockMacInvalid': 'Username ของท่านไม่สามารถเข้าสู่ระบบจากเครื่องนี้',
  'errMacAlreadyUsedPromotion': 'เครื่องที่ท่านเชื่อมต่ออินเตอร์เน็ต ใช้สิทธิ์เล่นเน็ตฟรีไปแล้ว ** สามารถใช้ได้ 1 สิทธิ์ / 1 หมายเลขโทรศัพท์ / 1 เครื่องใช้งาน',
  'errPromotionUsernameForOtherMac': 'username ไม่สามารถใช้งานกับเครื่องนี้ได้ ** สามารถใช้ได้ 1 สิทธิ์ / 1 หมายเลขโทรศัพท์ / 1 เครื่องใช้งาน **',
  'errLoginData': 'ข้อมูลในการเข้าสู่ระบบไม่ครบถ้วน กรุณาคลิกลิงค์ด้านล่างแล้วลองใหม่อีกครั้ง',
  'errLoginToRMS': 'เกิดปัญหาการ Login กับ SmartRMS',
  'errRMSCardNotFound': 'ท่านยังไม่ได้ซื้อบัตร กรุณาซื้อบัตรก่อน',
  'errLoginFail': 'เข้าสู่ระบบผิดพลาดกรุณาลอกงใหม่อีกครั้ง',
  'errOther':'ไม่สามารถเชื่อมต่อระบบได้ กรุณาติดต่อ Callcenter',
  'errOldNewPasswordSame': "รหัสผ่านเก่าและรหัสผ่านใหม่เหมือนกัน",
  'errOldNewPasswordMin': "รหัสผ่านจะต้องมีความยาว 4-16 ตัวอักษร",
  'errMobiledMin': "เบอร์โทรต้องมีความยาว 10 ตัวอักษร",
  'errMobiledMax': "เบอร์โทรต้องมีความยาว 10 ตัวอักษร",
  "errOldNewPasswordRegex":"ท่านสามารถกรอก a-z A-Z 0-9 และอักขระพิเศษ ยกเว้น ' \" และ ;",
  "passwordInfo1":"แจ้งให้ทราบ:",
  "passwordInfo2":"- รหัสผ่านจะต้องมีความยาว 4-16 ตัวอักษร",
  "passwordInfo3":"- อนุญาตให้ใช้ตัวอักษร a-z, A-Z และตัวเลข 0-9 และอักขระพิเศษ",
  "passwordInfo4":"- อักขระพิเศษที่สามารถป้อนได้ ได้แก่:",
  "passwordInfo5":"   ! # $ % & ( ) * + , - . / : < = > ? @ [ \ ] ^ _ ` { | } ~",
  "username":"ชื่อผู้ใช้",
  "singIn":"เข้าสู่ระบบ",
  "email":"อีเมล",
  "password":"รหัสผ่าน",
  "firstName":"ชื่อ",
  "lastName":"นามสกุล",
  "birthday":"วันเกิด",
  "idCard":"เลขบัตรประชาชน",
  "passport":"พาสปอร์ต",
  "register":"ลงทะเบียน",
  "mobileNo":"เบอร์โทร",
  "female":"หญิง",
  "male":"ชาย",
  "gender":"เพศ",
  "occupation":"อาชีพ",
  "nationality":"สัญชาติ",
  "vUsername":"กรุณากรอกชื่อผู้ใช้",
  "vMobileNo":"กรุณากรอกเบอร์โทร",
  "vMobile2":"รูปแบบเบอร์โทรศัพท์มือถือไม่ถูกต้อง  กรุณากรอกเบอร์โทรศัพท์มือถือให้ครบ 10 หลัก",
  "vMobile":"กรุณากรอกเบอร์โทรศัพท์มือถือ",
  "vPassword":"กรุณากรอกรหัสผ่าน",
  "vNewPassword":"กรุณากรอกรหัสผ่านใหม่",
  "vConfirmPassword":"กรุณากรอกยืนยันรหัสผ่าน",
  "vConfirmPasswordNotMatch":"รหัสผ่านใหม่และยืนยันรหัสผ่านไม่ตรงกัน",
  "vEmail":"กรุณากรอกอีเมล",
  "vIDcard_":"ข้อมูลเลขบัตรประชาชนไม่ถูกต้อง ",
  "vPassport_":"ข้อมูลพาสปอร์ตไม่ถูกต้อง ",
  "errPhoneRegex":"ข้อมูลโทรศัพท์ไม่ถูกต้อง",
  "vFirstName":"กรุณากรอกชื่อ",
  "vLastName":"กรุณากรอกนามสกุล",
  "vBirthday":"กรุณากรอกวันเกิด",
  "vIdCard":"กรุณากรอกเลขบัตรประชาชน หรือ พาสปอร์ต",
  "vPassport":"กรุณากรอกเลขบัตรประชาชน หรือ พาสปอร์ต",
  "vGender":"กรุณาเลือกเพศ",
  "rememberMe":"จดจำชื่อผู้ใช้และรหัสผ่าน",
  "forgotPassword":"ลืมรหัสผ่าน?",
  "newPassword":"รหัสผ่านใหม่",
  "vOldPassword":"กรุณากรอกรหัสผ่านเดิม",
  "oldPassword":"รหัสผ่านเดิม",
  "confirmPassword":"ยืนยันรหัสผ่าน",
  "signIn":"เข้าสู่ระบบ",
  "loginForFree":"เข้าใช้งานฟรี",
  "loginForNotFree":"เข้าใช้งานด้วยรูปแบบอื่น",
  "back":"กลับ",
  "registerNow":"ลงทะเบียน",
  "registerText":"ลงทะเบียนเพื่อเข้าสู่ระบบ",
  "or":"หรือ",
  "welcome":"ยินดีต้อนรับ",
  "dateOfBirth":"วันเกิด",
  "loginWithFacebook":"เข้าสู่ระบบด้วย Facebook",
  "loginWithLine":"เข้าสู่ระบบด้วย Line",
  "loginWithMobile":"เข้าสู่ระบบด้วย Mobile",
  "loginWithERegister":"เข้าสู่ระบบด้วยอีเมล (E-Register)",
  "vNotRobot":"กรุณาคลิกกล่องด้านบนเพื่อยืนยันว่าท่านไม่ใช่หุ่นยนต์",
  "editProfile":"ข้อมูลส่วนตัว",
  "changePassword":"เปลี่ยนรหัสผ่าน",
  "submit":"ยืนยัน",
  "savePassword":"บันทึกรหัสผ่าน",
  "save":"บันทึก",
  "cancel":"ยกเลิก",
  "usageHistory":"ข้อมูลการใช้งาน",
  "memberForgot":"เปลี่ยนรหัสผ่าน",
  "reverse":"คืนค่า",
  "resetPassword":"ตั้งค่ารหัสผ่านใหม่",
  "reset":"ตั้งค่ารหัสผ่านใหม่",
  "resetPasswordSub":"กรุณากรอกอีเมลของท่านเพื่อรับขั้นตอนการตั้งรหัสผ่านใหม่",
  "resetPasswordSub2":"กรุณากรอกชื่อผู้ใช้ของท่านเพื่อรับขั้นตอนการตั้งรหัสผ่านใหม่",
  "login":"เข้าสู่ระบบ",
  "name":"ชื่อผู้ใช้",
  "startDate":"วันที่เริ่มต้นใช้งาน",
  "validUntil":"ใช้งานได้ถึง",
  "remainingTime":"เวลาที่คงเหลือ",
  "on":"ข้อมูล ณ",
  "signOut":"ออกจากระบบ",
  "cardType":"ประเภทบัตร",
  "timeLimit": "เวลาที่ใช้ได้สูงสุด",
  "timeUsed": "เวลาที่ใช้ไป",
  "timeRemaining": "เวลาที่คงเหลือ",
  "startTime":"เริ่ม",
  "stopTime":"สิ้นสุด",
  "upload":"อัพ (Mb)",
  "download":"ดาวน์ (Mb)",
  "sessionTime":"เวลา",
  "usageDetails":"ข้อมูลการใช้งาน",
  "total":"รวม",
  "contactUs":"ติดต่อเรา",
  "lblStampLink":"คลิกเพื่อให้ผู้ใช้อีกเครื่องออกจากระบบ",
  "already":"ท่านไม่สามารถ login ได้เนื่องจาก login เครื่องอื่นอยู่",
  "warningChagepassword":"** การแก้ไขรหัสผ่านนี้จะมีผลต่อการ login <br/> ด้วย  email (e-register) เท่านั้น",
  "successfullyRegisteredAndLogged":"ลงทะเบียนและเข้าสู่ระบบสำเร็จแล้ว  ",
  "emailNotConfirmed":"อีเมลนี้ยังไม่ได้รับการยืนยัน ",
  "facebookLoginWith":"เข้าสู่ระบบด้วย",
  "chilliError":"ไม่สามารถเชื่อมต่อกับเครื่องแม่ข่ายได้ กรุณาลองใหม่อีกครั้ง",
  "tryAgain":"ลองอีกครั้ง",
  "otp":"ลงทะเบียน - ยืนยัน OTP",
  "otp_x":"ยืนยัน OTP",
  "otp_2":"ลืมรหัสผ่าน - ยืนยัน OTP",
  "continue":"ดําเนินการต่อ",
  "resendOtp":"รับรหัส OTP ใหม่",
  "mOtp1":"กรุณากรอก OTP ให้ครบ 6 ตัว",
  "mOtp2":"ท่านแล้ว SMS",
  "mOtp3":"หมายเลขอ้างอิง",
  "mOtp4":"OTP จะหมดอายุภายในเวลา",
  "smotp":"เบอร์สำหรับรับ OTP",
  "regis_h1":'กรอกข้อมูล ตาม พ.ร.บ. กำหนด',
  "regis_h2":'ลงทะเบียนและยืนยันตัวตนทาง email ครั้งแรกเพียงครั้งเดียวเท่านั้น',
  "regis_h3":'ลงทะเบียนครั้งแรกเพียงครั้งเดียวเท่านั้น',
  "errm4":"รูปแบบเบอร์โทรสถานที่ไม่ถูกต้อง",
  "recaptcha1":"กรุณากรอกค่าจากรูปภาพ",
  "recaptcha2":"กรุณากรอกข้อความจากรูปให้ถูกต้อง",
  "LoginWithWserCard":"ลงชื่อเข้าใช้งานด้วยบัตร",
  "v_facebook_like":"กรุณากดถูกใจเพจ facebook",
  "nextStep":"ถัดไป",
  "emailNotConfirmed1":"อีเมลของท่านคือ",
  "emailNotConfirmed3":"ต้องการทำการลงทะเบียนหรือไม่",
  "mNote":"หมายเหตุ",
  "mNoteEregister":"กรุณากรอกอีเมลเพื่อเข้าใช้งานหรือลงทะเบียน"

}

export default TH
