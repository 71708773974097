import React from 'react';
// import red from '@material-ui/core/colors/red';
// import Checkbox from '@material-ui/core/Checkbox';
// import ScValidator from './ScValidator';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Form,Button,Icon } from 'antd';
import DateFnsUtils from '@date-io/date-fns';
import { Translate } from 'react-redux-i18n'
import Notification  from '../services/notification.service';
import DictionaryService  from '../i18n/DictionaryService';
// import moment from "moment";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
// import ReCAPTCHA from "react-google-recaptcha";
import Request  from '../services/request.service';
import AppConfig  from '../services/app.config';
import {
  DatePicker,
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  // KeyboardDatePicker,
} from '@material-ui/pickers';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Lens from '@material-ui/icons/Lens';
// import PriorityHigh from '@material-ui/icons/ErrorOutline';
import { withStyles } from '@material-ui/core/styles';
import { FacebookProvider, Like, Page } from 'react-facebook';
const axios = require('axios');
declare let FB: any;

const LensChecked = withStyles({
  root: {
    color: 'rgb(248, 153, 1)',
  }
})(Lens);
const LensUnCheckedChecked = withStyles({
  root: {
    color: '#fff',
  }
})(Lens);


function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => {
    if(field === 'data'){
      let res = false;
      for (const i in fieldsError[field]) {
        if(fieldsError[field][i]){
          res = true;
          break;
        }
      }
      return res
    }
    return fieldsError[field]
  });
}

let id = 1;

class DynamicFieldSet extends React.Component<{enableFacebookUserLikes:any,locale:any,facebookAuth:any,fbPageRef:any,setRecaptcha1:any,errorOnTouched:any,values:any,fields:any,onChange:any,isDisabledTouched:any,form:any,onReady:any,onSuccess:any,onSuccess2:any,submitFormText:any,subButtonFormText:any,isDisabled:any,isSubButton:any},{isFacebookLike:any,fbAppId:any,recaptcha_text:any,svg_text:any,svg:any,reCaptcha:any,disabled:any,itemData:any}> {
static defaultProps = {
  locale:'TH',
  facebookAuth:{},
  isSubButton: false,
  fbPageRef:'',
  isDisabledTouched:false,
  subButtonFormText:'',
  errorOnTouched:true,
  enableFacebookUserLikes:false
}
  handleSubmit = async e => {
    e.preventDefault();

    if(this.props.facebookAuth.accessToken && this.props.fields ){
      let isfacebook_like = false;
      for(let i in this.props.fields){
        if(this.props.fields[i].name === "facebook_like"){
          isfacebook_like = true;
        }
      }


      if(isfacebook_like){



        let res = await this.getFacebookApi()
        if((res.data && res.data.length > 0 )|| (!this.props.enableFacebookUserLikes)){


        }else{
          Notification.error({
            message:"",
            description: DictionaryService.msg("v_facebook_like",this.props.locale),
            });
          return;
        }
      }
    }
    // alert("OK")
    // return ;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { keys, data } = values;
        console.log('Received values of form: ', keys ,data);
        if(keys)
        for(let i in keys){
          if(keys[i].name === 'birthday'){
            data.birthday = this.state.itemData['birthday']
          }
        }

        this.props.onSuccess(data);
      }else{
        console.log('Received err of form: ', err);
      }
    });
  };

  constructor(props) {
    super(props);

    // console.log("props>>",props.fields)
    let itemData = {};
    for(let i in props.fields){
      itemData[props.fields[i].name] = props.fields[i].value
    }
     this.state = {
      isFacebookLike:false,
      reCaptcha:"",
      disabled:false,
      itemData:itemData,
      svg:"",
      recaptcha_text:'',
      svg_text:'',
      fbAppId:''
     };
     this.getData(props).then(( state )=>{
      this.setState({...state});
     })



  }

  componentDidMount() {
    this.props.onReady(this.props.form);
    this.getCaptcha()

    try {
      FB.Event.subscribe('edge.create', (response)=> {
        console.log("subscribe",response);
        this.setState({isFacebookLike:true})
      });

    } catch (error) {
        this.setState({isFacebookLike:true})
    }




  }

  getCaptcha(){
    Request.get('/captcha').then((res)=>{
        // console.log(res.resultData)
        if(res.resultCode === "20000"){
          this.setState({
            svg:res.resultData.data,
            recaptcha_text:'',
            svg_text:res.resultData.text,
          })
        }else{

        }

    })
  }

  getFacebookLike(){
    // console.log("facebookAuth",this.props.facebookAuth)
    if(this.props.facebookAuth && this.props.facebookAuth.accessToken){
      // console.log("facebookAuth",this.props.facebookAuth)
      this.getFacebookApi().then((res)=>{
          if(res.data && res.data.length > 0){
              this.setState({isFacebookLike:true})
          }
      }).catch(e => {
        this.setState({isFacebookLike:true})
      });
    }else{

    }

  }

getFacebookApi(){
  let access_token = this.props.facebookAuth.accessToken;
  let facebookUrl = "https://graph.facebook.com";
  return axios.get(facebookUrl + `/me/likes/${this.props.fbPageRef}?access_token=${access_token}`).then(response => response.data)

}



  async getData(nextProps){
    // console.log("getData")
    let props = nextProps
    const { getFieldDecorator } = props.form;
    getFieldDecorator('keys', { initialValue: nextProps.fields });

    let data = {}
    for(let i = 0;i<props.fields.length;i++){
      if(props.fields[i] && (
        props.fields[i].type === "date" ||
        props.fields[i].type === "radio" ||
        props.fields[i].type === "seletebox"||
        props.fields[i].type === "text" ||
        props.fields[i].type === "password" ||
        props.fields[i].type === "recaptcha" ||
        props.fields[i].type === "facebook_like" ||
        props.fields[i].type === "select"
      )){


        data[props.fields[i].name] = props.fields[i].value
      }
    }
    // this.props
    setTimeout(() => {
      if(this.props.isDisabled){
        this.props.form.validateFields();
      }
      console.log("getFacebookLike")
      if(this.props.fbPageRef){
          this.getFacebookLike();
      }
    }, 100);

    let config:any = await AppConfig.getConfig();

    return {
      reCaptcha:config.reCaptcha,
      disabled:true,
      fbAppId:config.fbAppId,
      itemData:{
        ...data
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.fields !== this.props.fields){
      // let data = await this.getData(nextProps);
      this.getData(nextProps).then(( state )=>{
        this.setState({...state});
       })


    }
  }

  UIField(label,type,name,items,disabled,helperText){
    const { getFieldValue,setFieldsValue} = this.props.form;
    // console.log("this.state.itemData",this.state.itemData)
    if(type === 'password'){
      return <TextField style={{ width: '100%'}} label={label} type="password"
            value={this.state.itemData[name]}
            onChange={(e)=>{
              let { itemData } = this.state;
              let value = e.target.value
              itemData[name] = value;
              // console.log("xxx",e.target.value)
              this.setState({itemData:itemData},()=>{
                let data = {}
                data["data["+name+"]"] = value;
                setTimeout(() => {
                  // setFieldsValue(data)
                }, 0);
              })

            }}
          inputProps={{
            autocomplete: "new-password",
            form: {
              autocomplete: "off",
            }
          }}/>
    }else if(type ===  'seletebox'){
      let $items = items || []
      return <><TextField
      select
      label={label}
      disabled={disabled}
      SelectProps={{

      }}
      helperText={helperText}
      value={this.state.itemData[name]}
      onChange={(e)=>{
        let { itemData } = this.state;
        itemData[name] = e.target.value;

        let data = {}
        data["data["+name+"]"] = e.target.value;
        setTimeout(() => {
          // setFieldsValue(data)
        }, 0);

        this.setState({itemData:itemData},()=>{

        })

      }}
      margin="normal"
      style={{width:'100%'}}
      >
      {$items.map(option => (
        <MenuItem key={option.value+""} value={option.value+""}>
          {option.label}
        </MenuItem>
      ))}
      </TextField></>
    }
    else if(type === 'date'){
      return <>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              format="dd/MM/yyyy"
              views={["year","month","date"]}
              InputProps={{}}
              value={this.state.itemData[name]}
              onChange={(e)=>{
                let { itemData } = this.state;
                    console.log(e)
                    itemData[name] = e;
                    this.setState({itemData:itemData},()=>{
                      let data = {}
                      data["data["+name+"]"] = e;
                      setTimeout(() => {
                        // setFieldsValue(data)
                      }, 10);
                    })

              }}
              style={{width:  '100%',marginTop: 15}}
            />
      </MuiPickersUtilsProvider></>
    }else if(type === 'radio'){
      let $items = items || []
      let value = this.state.itemData[name] === undefined ? null : this.state.itemData[name]
       value = value === 0 || value === 1 ? value + "" : value //value === 0 || value === 1 ? value + "" : value
      return <>
          <RadioGroup
                value={value + ""}
                onChange={(event:any)=>{
                  let e = event.target.value

                  let { itemData } = this.state;
                  // console.log(itemData)
                  itemData[name] = e;
                  this.setState({itemData:itemData},()=>{
                    let data = {}
                    data["data["+name+"]"] = e;

                    setTimeout(() => {
                      // setFieldsValue(data)
                    }, 50);
                  })
                }}
                row
              >
                {
                  $items.map((r)=>{
                    return <FormControlLabel value={r.value} control={<Radio
                      icon={<LensUnCheckedChecked style={{fontSize: 15 }}/>} checkedIcon={<LensChecked style={{fontSize: 15 }}/>}
                      style={{fontSize: 15 }}/>} label={r.label} />
                  })
                }
          </RadioGroup>
      </>

    }else if(type === 'facebook_like'){
      return <>
      {
        this.props.facebookAuth && this.props.facebookAuth.accessToken?
        <>
        <div style={{fontSize: 16,fontWeight: 600,marginTop: 8}} className="ant-form-item">
          <span className="rq"> <label >{label}</label> </span>
        </div>
        {
          this.state.fbAppId?<div className={'facebooklike '+(this.state.isFacebookLike?"disabled":"")}>
          <FacebookProvider appId={this.state.fbAppId}>
              {/* <Like href={"https://www.facebook.com/"+this.props.fbPageRef} colorScheme="dark" showFaces width="300" /> */}
             {/*<Like href={"https://www.facebook.com/"+this.props.buildingInfo.data.fbPageRef} colorScheme="dark" showFaces width="300" /> */}
             <Page href={"https://www.facebook.com/"+this.props.fbPageRef} tabs=""  height={200}  width="300" />
           </FacebookProvider>
           </div>:null
        }
        </>:
        null
      }
      </>
    }else if(type === 'recaptcha'){
      // let $items = items || []
      // console.log("type",type)
      return <>
      <div className="row">
      <div className="col-5" style={{marginTop: 12,padding:'8px 0px 4px 14px'}}>
        <div  style={{color: '#3f81d3',cursor: 'pointer',position: 'absolute',top: 6,right: 4}}>
          <Icon type="reload" onClick={()=>{
                  this.getCaptcha();
          }}/>
        </div>
        <div dangerouslySetInnerHTML={{__html:this.state.svg}} style={{background: '#fff'}}></div>
      </div>
      <div className="col-6">

      <TextField style={{ width: '100%'}} label={<Translate value="recaptcha1" />}
            value={this.state.recaptcha_text}
            onChange={(e)=>{
                this.setState({recaptcha_text:e.target.value},()=>{
                    let { recaptcha_text,svg_text } = this.state;
                    this.props.setRecaptcha1({recaptcha_text,svg_text})
                })
            }}
          inputProps={{
            maxLength: 4,
            autocomplete: "new-password",
            form: {
              autocomplete: "off",
            }
          }}/>
      </div>
    </div>
      {/*
          <div className="gc-reset g-recaptcha" style={{background:'transparent'}}>
            this.state.reCaptcha?
            <ReCAPTCHA
                  sitekey={this.state.reCaptcha}
                  hl={"en"}
                  onChange={(e)=>{
                      let { itemData } = this.state;
                      itemData[name] = e;
                      console.log("itemData::",itemData,this.props.form)
                      this.setState({itemData:itemData},()=>{
                        let data = {}
                        data["data["+name+"]"] = e;

                        setTimeout(() => {
                          setFieldsValue(data)
                          if(!data){
                            this.props.form.validateFields();
                          }
                        }, 50);
                      })
                  }}
                />:null

        <div id="sample-captcha"></div>
        </div>
        */}
      </>

    }

    // console.log("xxx",name,this.state.itemData[name])
    let $value = this.state.itemData[name] === undefined ? null : this.state.itemData[name]
    let fieldsData = {}
    return  <><TextField style={{ width: '100%'}} label={label}
                value={$value}
                onChange={(e)=>{
                  let { itemData } = this.state;
                  let value = e.target.value
                  itemData[name] = value;
                  this.setState({itemData:itemData},()=>{
                    let data = {}
                    data["data["+name+"]"] = value;
                    setTimeout(() => {
                      // setFieldsValue(data)
                    }, 0);
                  })

                }}
                {...fieldsData}
                disabled={disabled}
                helperText={helperText}
                inputProps={{
                  autocomplete: "new-password",
                  form: {
                    autocomplete: "off",
                  }
                }}/>
                {/* {this.state.itemData[name]} */}
              </>
  }


  render() {
    // console.log("itemData",this.state.itemData)
    const { getFieldDecorator, getFieldValue,getFieldValues,getFieldError,isFieldTouched ,isFieldsTouched ,getFieldsError} = this.props.form;
    const $isFieldsTouched = isFieldsTouched();
    getFieldDecorator('keys', {});
    const keys = getFieldValue('keys');

    // console.log("$isFieldsTouched",$isFieldsTouched,hasErrors(getFieldsError()),keys)

    const formItems = keys.map((k, index) => {

    if(!k) return null;

    const dataError =  (!this.props.isDisabled || (isFieldTouched(`data[${k.name}]`)  || !this.props.errorOnTouched) ) && getFieldError(`data[${k.name}]`);
    let requiredUI = k.requiredUI || false;
    if(k.requiredUIDynamic){
      requiredUI = k.requiredUIDynamicFuction()
    }
   let validateTrigger = k.type === 'recaptcha' ? ['onChange'] : ['onChange', 'onBlur'];
    return  <>

      {k.start}
      <Grid item  xs={k.xs || 12}>

        <Form.Item
            required={false}
            key={k.name}
            validateStatus={dataError ? 'error' : ''}
            help={dataError || ''}
          >
        {getFieldDecorator(`data[${k.name}]`, {
            validateTrigger: validateTrigger, //
            // trigger: ['onChange', 'onBlur'],
            initialValue:k.value,
            rules: k.rules,
            validateFirst:true,
          })(<div className={requiredUI?"rq":''}>
              {this.UIField(k.label,k.type,k.name,k.items,k.disabled,k.helperText)}
            </div>)}
        </Form.Item>

        </Grid>
        {k.end}
        </>
    });

    // console.log("getFieldsError",getFieldsError())
    return (
      <Form onSubmit={this.handleSubmit} noValidate autoComplete="off">
         <Grid container spacing={3} alignItems="flex-end">
         {formItems}
         </Grid>



         {!this.props.isSubButton?
        <Form.Item  style={{marginTop:15}}>

          <Button id="signin-submit" type="primary" htmlType="submit" className="login-form-button bt-singin" style={{
          width:  '100%;',marginTop: 10}}
          disabled={hasErrors(getFieldsError())  && this.props.isDisabled}>
            {this.props.submitFormText}
          </Button>


        </Form.Item>
        :<div className="row" style={{marginTop: 10}}>
        <div className="col-6" >
        <Button id="signin-submit" type="primary" htmlType="submit" className="login-form-button bt-singin" style={{
          width:  '100%',marginTop:10}}
          disabled={(hasErrors(getFieldsError())  && this.props.isDisabled) ||  (!$isFieldsTouched &&  this.props.isDisabledTouched ) }>
            {this.props.submitFormText}
          </Button>
        </div>
        <div className="col-6" >
          <Button id="updateprofile-submit" type="primary"  className="login-form-button bt-close" style={{
            width: '100%',
            background: '#f89932',
            borderColor: '#f89932',marginBottom:10,marginTop:10}}
            disabled={!$isFieldsTouched &&  this.props.isDisabledTouched }
            onClick={()=>{
              this.props.onSuccess2()
              }}>
                 {this.props.subButtonFormText}

          </Button>
        </div>
        </div>}
        {/*
        <pre className="language-bash" style={{width:'200px'}}>{JSON.stringify(this.state.itemData, null, 2)}</pre>
         */}


        </Form>

    );
  }
}

function mapPropsToFieldsValue(value) {
let valueTemp = value && value.data ? value.data :{}
let temp = {};
  for (const i in valueTemp) {
    temp[i] = Form.createFormField({
        ...valueTemp[i],
        value: valueTemp[i].value,
      })
  }
  return temp
}


const WrappedDynamicFieldSet:any = Form.create({
  name: 'dynamic_form_item' ,
  onFieldsChange(props:any, changedFields) {
    // console.log("onFieldsChange",props,changedFields)
    // if(props)
    props.onChange(changedFields);
  },
  // mapPropsToFields(props) {
  //   // console.log("mapPropsToFields::",props)
  //   return mapPropsToFieldsValue(props.values);
  // },
  onValuesChange(_, values) {
    // console.log("onValuesChange",values);
  },
})(DynamicFieldSet);
export default WrappedDynamicFieldSet;
