import React ,{Component,Props} from 'react';
// import Script from 'react-load-script';
// import queryString from 'query-string';
import Request  from '../../../services/request.service';
import { Form } from 'antd';
import { Translate } from 'react-redux-i18n'
// import AppConfig  from '../../../services/app.config';
// import { message } from "antd";
import {  notification,Button } from 'antd';
import {Helmet} from "react-helmet";
import UsageInfo from './components/UsageInfo';
import Loading from "../../Loading";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DictionaryService  from '../../../i18n/DictionaryService';
import { connect } from 'react-redux'
import i18n from "../../../i18n/index";
import Notification  from '../../../services/notification.service';
import { getCurrentLocale } from "../../../data/utils";
import ReactGA from 'react-ga';
const key = 'updatable';
const btn = (
  <Button  onClick={() => notification.close(key)} style={{marginTop: 10}}>
    ปิด
  </Button>
);
type LoginProps = {
  location: any,
  history: any,
  match:any,
  locale:any,
  setLocaleCustom: (locale: string) => void
  form:any,
  ChilliData:any,
  buildingInfo:any
  user:any
}

type InfoState = {
  username:string,
  data:any,
  loading:boolean
}

class InfoForm extends Component<LoginProps, InfoState> {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      data: [],
      loading:true
    };
     this.handleSubmit = this.handleSubmit.bind(this);
     this.backPage = this.backPage.bind(this);

      if(this.props.user.userName && !this.props.user.isSocialMember){
        this.getuserdetail({userName:this.props.user.userName},false)
      }else{
        setTimeout(() => {
          this.setState({
            loading: false
          });
        }, 50);
      }

  }
  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.user !== this.props.user) {
      console.log("user",nextProps.user)
      if(nextProps.user.userName && !this.props.user.isSocialMember){
        this.getuserdetail({userName:nextProps.user.userName},false)
      }
    }
  }

  backPage(){
    if(this.props.user.userName){
      this.backHome()
    }else{
      this.setState({data:[]})
    }

  }

  backHome(){
    this.props.history.push({ pathname:"/", search:this.props.location.search});
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields(async (err, user) => {
      if (!err  ) {
          this.getuserdetail(user)
          return false;
        }
      });


  }

  getuserdetail(item,noti = true){
    this.setState({
      loading: true
    });
    let custgroupId = this.props.buildingInfo.data.custgroupId;
    let sessionId = this.props.ChilliData&& this.props.ChilliData.session ? this.props.ChilliData.session.sessionId :"";
    Request.get("/login/usageHistory", {custGroupId:custgroupId,sessionId:sessionId,...item}, {
      "x-access-id": this.props.buildingInfo.data.accessToken
    })
    .then((res: any) => {
      this.setState({
        loading: false
      });

      let _data = res;
      let msg = _data.resultDescription ;
      let label = (_data.resultCode && _data.resultCode[0] === "2"?"Successfully":"Error") +" : "+_data.resultCode+" $ "+ msg;
      ReactGA.event({
        category: 'Usage History Page',
        action: _data.userType?_data.userType:"",
        label: label
      });
      console.log("ReactGA.event",{
        category: 'Usage History Page',
        action:  _data.userType?_data.userType:"",
        label: label
      })

      if (res.resultCode && res.resultCode[0] === "2") {// === "20000"
        this.setState({
          data: res.resultData
        });
      } else {
        Notification.error({
          message:"",
          description:res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
          resultCode:res.resultCode
          });

          if(!noti){
            this.backHome()
          }
      }
    })
    .catch(e => {
      this.setState({
        loading: false
      });

      Notification.error({
        message:"",
        description:e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
        });

        if(!noti){
          this.backHome()
        }
    });
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    if (this.state.data.userName) {
      return <UsageInfo {...this.state} backPage={this.backPage}/>;
    }
    const { getFieldDecorator } = this.props.form;
    return (

      <div id="login-" className="bg_login info">
                  <div >
                    <div >
                    <Form onSubmit={this.handleSubmit} className="login-form" autoComplete="off">
                      <div id="form_login" >
                          <div className="bt-sz-back fix" onClick={()=>this.backHome()} >
                          <Translate value="back" />
                          </div>
                      <div className="row">
                         <div className="col-md-12 header-text">
                            <span><b><Translate value="usageHistory" />  </b> </span>

                        </div>
                      <div className="col-12" >

                        </div>
                         <div className="col-12" >
                         <div className="col-12" >
                              <Form.Item >
                                {/* {this.props.locale} */}
                                  {getFieldDecorator('userName', {
                                      initialValue:this.props.user.username,
                                      rules: [{ required: true, message: DictionaryService.msg("vUsername",this.props.locale) }],
                                    })(

                                        <Grid container spacing={3} alignItems="flex-end">
                                          <Grid item  xs={1}>
                                            <div className="sz-icons"><div className="icon username"></div></div>
                                          </Grid>
                                          <Grid item  xs={11}>
                                            <TextField id="input-with-icon-grid"
                                            label={DictionaryService.msg("username",this.props.locale)}
                                            style={{width:  '100%'}}
                                            />
                                          </Grid>
                                        </Grid>

                                        ,
                                        )}
                                </Form.Item>
                                </div>
                                <div className="col-md-12 text-center" style={{marginTop:25}}>
                                <Form.Item>
                                <Button id="usagehistory-submit" type="primary" htmlType="submit" className="login-form-button  bt-singin"
                                disabled={this.state.loading}
                                style={{
                                  background: '#f89932',
                                  borderColor: '#f89932',
                                  width: '265px'}}>
                                  <Translate value="submit" />
                                </Button>
                                </Form.Item>
                                </div>
                        </div>
                      </div>
                      </div>
                      </Form>
                      </div>
                      </div>
                    </div>



    );
  }
}


{/* <div id="content">
        <div id="info">
          <h1>
            กรุณากรอกชื่อผู้ใช้งาน (Username) ที่อยู่บนหน้าบัตร
            เพื่อตรวจสอบข้อมูลการใช้งาน
          </h1>
          <h2>Please enter your card’s Username to check usage time.</h2>
          <p style={{ textAlign: "center" }} className="alert" />
          <form onSubmit={this.handleSubmit}>
            <p>
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Username"
                value={this.state.username}
                onChange={e => {
                  this.setState({ username: e.target.value });
                }}
              />
            </p>


            <p>
              <input type="submit" value="Submit" />
            </p>
          </form>
        </div>
      </div> */}
const mapStateToProps: any = (state: any) => ({
  locale: getCurrentLocale(state),
});

const mapDispatchToProps = {}
const InfoPage:any = connect<any, any, {}>(mapStateToProps, mapDispatchToProps)(Form.create({})(InfoForm));

export default InfoPage
