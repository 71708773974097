import React, { Component } from 'react';

import ReactDOM from 'react-dom';
import DictionaryService  from '../../../../i18n/DictionaryService';
import { connect } from 'react-redux'
import { getCurrentLocale } from "../../../../data/utils";
type TranslateProps = {
  value: any,
  locale:any
}
type TranslateState = {}
class TranslatePage extends Component<TranslateProps, TranslateState> {
  constructor(props) {
    super(props)
  }
  render() {
    let locale = "EN";
    try{
      locale = this.props.locale;
    }catch(e){

    }
    return (<span> {DictionaryService.msg(this.props.value,locale)}</span>);
  }
}

const mapStateToProps0: any = (state: any) => ({
  locale: getCurrentLocale(state),
});

const mapDispatchToProps0 = {}
const Translate:any = connect<any, any, {}>(mapStateToProps0, mapDispatchToProps0)(TranslatePage);


class BuildingInfo extends React.Component<{data:any,locale:any,buildingInfoLoading:any},{isFixed:any,scroll:any,top:any,height:any}> {
// const BuildingInfo = ({data,locale,buildingInfoLoading}) =>{
  instance:any = null;

  constructor(props) {
    super(props);
    this.state = { isFixed:false,scroll:0,top:0,height:0};
    this.handleScroll = this.handleScroll.bind(this);
  }

    handleScroll() {
      var w = window,
          d = document,
          e = d.documentElement,
          g = d.getElementsByTagName('body')[0],
          x = w.innerWidth || e.clientWidth || g.clientWidth,
          y = w.innerHeight|| e.clientHeight|| g.clientHeight;

      let isFixed = false;
      const el:any = document.querySelector('nav');
      // console.log(this.instance.getBoundingClientRect().top,window.scrollY )
      if(window.scrollY > this.state.top && x < 768 ){
        isFixed = true;
      }
        this.setState({isFixed:isFixed });
    }

    componentDidMount() {

      const el:any = document.querySelector('nav');
      if(el){
        let offsetTop  = this.instance.getBoundingClientRect().top;
        // console.log(offsetTop,window.scrollY )
        this.setState({top: this.instance.getBoundingClientRect().top + window.scrollY, height: el.offsetHeight});
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener("resize", this.handleScroll, true);
      }

  }

componentDidUpdate() {
      // this.state.scroll > this.state.top ?
      //     document.body.style.paddingTop = `${this.state.height}px` :
      //     document.body.style.paddingTop = '0';
  }


  render() {
    let {data,locale,buildingInfoLoading} = this.props
    if(!buildingInfoLoading){
      return <div style={{padding:100}}>
        <div className="loader"></div>
      </div>
    }

    let buildingName = data.data["buildingName" + locale] || '' //.substring(0, 17)
    let size = 20;
    let line = 22;
    let classAdd =''
    if(buildingName.length >= 45){
      size = 6;
      line = 22;
      classAdd = 'nowrap-text'
    }else
    if(buildingName.length >= 40){
      size = 6;
      line = 22;
    }else if(buildingName.length >= 35){
      size = 8;
      line = 22;
    }else if(buildingName.length >= 30){
      size = 10;
      line = 22;
    }else if(buildingName.length >= 25){
      size = 12;
      line = 22;
    }else if(buildingName.length >= 20){
      size = 14;
      line = 22;
    }else if(buildingName.length >= 15){
      size = 16;
      line = 22;
    }else if(buildingName.length >= 10){
      size = 18;
      line = 22;
    }
    // console.log("data.logoLoginUrl",data.data)
  return (
    <>
      <div>
      <nav className={this.state.isFixed? "building-info fixed" : "building-info"} id="nav"
      ref={(el) => this.instance = el } >
        <div className="card-body" >
        <div className="row">



        <div className="non-fixed">
          <div className="row">
            <div className="image container">
             <div><img src={require("../../../../assets/images/icon_building.png")} ></img ></div>
            </div>
            <div className="col-12 text-left text-building">
              <h5 className={"card-title color-org "+classAdd } style={{fontSize:size,lineHeight:`${line}px` }}>{buildingName}</h5>
            </div>
              <div className="col-12 ip">
                <span className="text-color-main fontweight600">IP : </span>
                <span className="text-color-main2 fontweight600">
                  {data.data.ip} ({data.data.customerCode})
                </span>
              </div>
          </div>




        </div>


        <div className="col-12 header">
          <h5 className="card-title color-org building-name">{buildingName}</h5>
        </div>
        {
          data.data.logoLoginUrl?
          <div className="col-12 header" style={{padding:0}}>
            <img src={ data.data.logoLoginUrl}  style={{width:'100%'}}/>
          </div>:null
        }
        <div className="col-12 deteil">
          <div className="row" style={{marginRight: 0,marginLeft:0,marginBottom: 10}}>
        <div className="col-3" style={{    padding: 10}}>
          <img src={require("../../../../assets/images/icon_building.png")} style={{width:'100%'}}></img>
        </div>
        <div className="col-9">

            <p className="card-text text-color-main">
            <p>
              <Translate value="msg_connect" /> {data.data["buildingName" + locale]}{" "} <br/>
                <Translate value="msg_connect2" />{data.data["buildingName" + locale]}
            </p>
            </p>
            <div className="d-flex justify-content-between align-items-center row">
            <div id="ip" className="col-12">
                <span className="text-color-main fontweight600">IP : </span>
                <span className="text-color-main2 fontweight600">
                  {data.data.ip} ({data.data.customerCode})
                </span>
              </div>
              <div id="ip" className="col-12">
                <span className="text-color-main fontweight600">Service Type : </span>
                <span className="text-color-main2 fontweight600">
                  {data.data.serviceTypeName}
                </span>
              </div>
            </div>
          </div>
        <div id="type">
          {/* <h4>
            <img src={require("../../../../assets/images/icon-type.png")} />
            SmartZone Service Type
          </h4> */}
          {/* <ul>
            <li>
              <div
                id="service_type_1"
                className={`service_fancybox ${
                  data.data.serviceTypeId === 1 ? "current" : ""
                }`}
              />
              <h5>Diamond</h5>
            </li>
            <li>
              <div
                id="service_type_2"
                className={`service_fancybox ${
                  data.data.serviceTypeId === 2 ? "current" : ""
                }`}
              />
              <h5>Platinum</h5>
            </li>
            <li>
              <div
                id="service_type_3"
                className={`service_fancybox ${
                  data.data.serviceTypeId === 3 ? "current" : ""
                }`}
              />
              <h5>Gold</h5>
            </li>
            <li>
              <div
                id="service_type_5"
                className={`service_fancybox ${
                  data.data.serviceTypeId === 5 ? "current" : ""
                }`}
              />
              <h5>Software</h5>
            </li>
          </ul> */}
        </div>
        {/* <br className="clear" /> */}
      </div>
      </div>
      </div>
      </div>
      </nav>
      </div>
    </>
  );
    }
}


export default BuildingInfo;
