import React, { Component } from 'react';
import LoginInfo from './components/LoginInfo';

import LoginForm from './components/auth/LoginForm';
import {  Route,  Link ,Switch  } from "react-router-dom";

const VresidenceTheme = (props) => {
  if (!props.loginInfoLoading || props.confirmloginLoging) {
    return <div id="login-" >
      <div style={{ paddingTop: 200, paddingBottom: 200, display: 'table', margin: '0 auto' }}>
        <div className="loader"></div>
      </div>
    </div>
  }
  // else if(props.user){
  //   return <LoginInfo {...props} />
  // }
  // <Route
  //     path={`/user-info-iframe`}
  //     render={() => (
  //       <LoginInfoIFrame {...props} />
  //     )}
  //   />
  return <Switch>
            <Route
                path={`/user-info`}
                render={() => (
                  <LoginInfo {...props} />
                )}
              />

              <Route
                path={`/`}
                render={() => (
                  <LoginForm
                  specialTheme={props.specialTheme}
                  customThemeJson={props.customThemeJson}
                  updatePageError={props.updatePageError} freeUser={props.freeUser} social={props.social} buildingInfo={props.buildingInfo} changeLoginmode={props.changeLoginmode} loginmode={props.loginmode} postLoginERegister={props.postLoginERegister} location={props.location} locale={props.locale}  history={props.history}  user={props.user} OnSubmitLogin={props.OnSubmitLogin} OnSubmitLogOff={props.OnSubmitLogOff}/>
                )}
              />

</Switch>


}


// registerUser={this.state.registerUser}
// registerAction={this.state.registerUser}




// export default LoginInfo;
export default VresidenceTheme
