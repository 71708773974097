import * as actionTypes from '../actionTypes'
import { createReducer } from '../utils'

const createConfigReducer = (namespace: string) => {
  const initialState = {};
  const target = actionTypes[namespace];
  console.log("target",target,namespace)
  return createReducer(
    {
      [target.SET_CONFIG](state: typeof initialState,action:any) {
        // console.log("SET_CONFIG", state, action.payload);
        return action.payload || state;
      },
      [target.SET_OTPDATA](state: typeof initialState,action:any) {
        // console.log("SET_CONFIG", state, action.payload);
        return action.payload || state;
      }
    },
    initialState
  );
};

export default createConfigReducer;
