import React, { Component } from 'react';
import LoginInfo from './components/LoginInfo';
import LoginForm from './components/auth/LoginForm';
import {  Route,  Link ,Switch  } from "react-router-dom";
// import { Translate } from 'react-redux-i18n'
import i18n from "../../../i18n/index";
import queryString from 'query-string';
import Request  from '../../../services/request.service';
import Notification  from '../../../services/notification.service';
import Cookies from 'js-cookie';
import { getCurrentLocale } from "../../../data/utils";
import DictionaryService  from '../../../i18n/DictionaryService';
import { connect } from 'react-redux'
type TranslateProps = {
  value: any,
  locale:any
}
type TranslateState = {}
class TranslatePage extends Component<TranslateProps, TranslateState> {
  constructor(props) {
    super(props)
  }
  render() {
    let locale = "EN";
    try{
      locale = this.props.locale;
    }catch(e){

    }
    return (<span> {DictionaryService.msg(this.props.value,locale)}</span>);
  }
}

const mapStateToProps0: any = (state: any) => ({
  locale: getCurrentLocale(state),
});

const mapDispatchToProps0 = {}
const Translate:any = connect<any, any, {}>(mapStateToProps0, mapDispatchToProps0)(TranslatePage);







class LoginErrorPage extends Component<{notificationError:any,locale:any,strUsername:any,buildingInfo:any,user:any,pageError:any,history:any,location:any}, {text:any,dataInit:any,strUsername:any}> {
  constructor(props) {
    super(props);
    this.state = {
      dataInit:false,
      text:false,
      strUsername:false
    }//user:props.registerUser
    if(!props.pageError){
      this.props.history.push({ pathname:"/", search:this.props.location.search})
    }else{
      this.init();
    }
  }

  getStrUsername(item){

  }

  init(){
    // console.log("xxx")
    // let params: any = queryString.parse(this.props.location.search);
    // this.setState({data:{}})
    let strUsername = this.props.strUsername
    console.log(" ??? strUsername",strUsername);

      try{
        console.log(" ??? strUsername",Cookies.get('strUsername'));
        if(!strUsername && Cookies.get('strUsername')){
          strUsername = Cookies.get('strUsername')
          Cookies.remove('strUsername', { path: '' });
        }
      }catch (e) {
        console.log("ga",e)
      }
      console.log("strUsername",strUsername);

      if(!strUsername){
        Request.get("/login/rememberLoginStr", {
            mac:this.props.buildingInfo.data.mac
        }, {
          "x-access-id":
          this.props.buildingInfo.data.accessToken
        }).then((res: any) => {
          console.log("setUserrememberLogin",res);
            if (res.resultCode && res.resultCode[0] === "2" && res.resultData && res.resultData.length) {
              console.log(res.resultData[0].strUsername);
              this.getFirstMac(res.resultData[0].strUsername)
            }
        })
      }else{
        this.getFirstMac(strUsername)

      }




  }

  getFirstMac(strUsername){
    Request.get("/login/getFirstMac", {
      userName:strUsername,
    },{
      "x-access-id": this.props.buildingInfo.data.accessToken
    })
      .then((res: any) => {
        if (res.resultCode && res.resultCode[0] === "2") {// === "20000"
          console.log("dataInit",res.resultData );

          this.setState({dataInit:res.resultData, strUsername:strUsername })
        }else{
          if(res.resultCode){
            this.setState({text:"["+res.resultCode+"] "+res.errorMessage })
          }else{
            this.setState({text:"[C50000] "+i18n[this.props.locale].errOther })
          }

          Notification.error({
            message:"",
            description:res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
            resultCode:res.resultCode
            });
            console.log("this.props.buildingInfo",this.props.buildingInfo);

            // Request.get("/login/getStrUsernameLast", {
            //   apmac:this.props.buildingInfo && this.props.buildingInfo.data ? this.props.buildingInfo.data.apmac : null,
            // },{
            //   "x-access-id": this.props.buildingInfo.data.accessToken
            // }).then((res: any) => {
            //
            // })

        }
        }).catch(e => {
          console.log(e);
         Notification.error({
          message:"",
          description:e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
          });

        });
  }


  stamplink(){
    console.log(this.props.buildingInfo,this.props.user)


    let $stamp_user = this.state.strUsername;
    let $stamp_custgroupid = this.props.buildingInfo.data.custgroupId;
    let $stamp_currentmacaddr = this.props.buildingInfo.data.apmac//""//@$_GET['mac'];
    let { dataInit } =  this.state
    let $checkMd5 = dataInit.md5;
    let apmac = dataInit.mac
    // "http://192.168.182.1:4300/stamp";
    let $stamplink = "http://192.168.182.1:4300/stamp?mac=" +apmac + "&md5="+$checkMd5 + "&user=" + $stamp_user +"&curmac="+$stamp_currentmacaddr+"&cgid="+$stamp_custgroupid+"&type=premium";
    window.location.href = $stamplink
    console.log($stamplink)
  }

  render() {
    let props = this.props;
  // if (!props.loginInfoLoading || props.confirmloginLoging) {
  //   return <div id="login-" >
  //     <div style={{ paddingTop: 200, paddingBottom: 200, display: 'table', margin: '0 auto' }}>
  //       <div className="loader"></div>
  //     </div>
  //   </div>
  // }
  console.log("props.pageError",props);

  return <div className="">
          <div>
            <div >
              <div  id="login-" className="bg_login login"  >
                  <div className="bt-sz-back fix" onClick={()=>{
                      this.props.history.push({ pathname:"/", search:this.props.location.search});
                    }}  >
                <Translate value="back" />
                </div>

              <div className="row">
                <div className="col-md-12 header-text text-center" >
                    {props.pageError === 'already' ||
                     props.pageError === 'You are already logged in on another device' ||
                     props.pageError === 'You are already logged in' ||
                     props.pageError === 'You are already logged in - access denied' ||
                     props.pageError === 'ท่านไม่สามารถ login ได้เนื่องจาก login เครื่องอื่นอยู่'

                     ?
                    <div>
                       <div style={{marginTop: 20}}>
                         {this.state.text?this.state.text:<Translate value="already"></Translate>}
                       {/* <Translate value="already"></Translate> */}
                      </div>
                     {this.state.dataInit?
                      <p className="p-button" style={{marginTop: 10}}>
                          <button id="btnstamp" className="login-form-button bt-singin auto-height" style={{height:"auto !important"}}
                             onClick={()=>{
                               this.stamplink()
                             }}     > <Translate value="lblStampLink"></Translate> </button>
                      </p>:null}
                    </div>
                    :null}

                </div>
                </div>
              <div id="form_login"  >

              </div>
          </div>
        </div>
      </div>
    </div>
  }
}

// registerUser={this.state.registerUser}
// registerAction={this.state.registerUser}




// export default LoginInfo;
export default LoginErrorPage
