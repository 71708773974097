// import {  } from '../factory'
import { Map } from 'immutable'
import { combineReducers } from 'redux-immutable'
import { createConfigReducer } from "../factory";
export interface IConfigModel extends Map<any, any> {
  config: any;
}

export default combineReducers({
  config: createConfigReducer("configModel")
});
