import React,{Component}  from 'react';
import { Carousel } from "antd";
import renderHTML from "react-render-html";
import { connect } from 'react-redux'
import { getCurrentLocale } from "../../../../data/utils";
import PopupAgreement from "../../../../components/PopupAgreement";
import ContactUs from "./ContactUs";


// import PopupAgreement from './components/PopupAgreement'
const  PrivacyPolicy = () => {
  const buildingInfo = {
    loginAlphaboxFlag:1
  }
  return (<div>
    <header className="navbar navbar-dark bg-main box-shadow header-done variant typeheader-4" style={{height:50}}>
      <div className="container d-flex justify-content-between">
        <a  className="navbar-brand d-flex align-items-center"  onClick={async ()=>{

        }} >
        {
          buildingInfo.loginAlphaboxFlag === 1 ?<>
          <img src={require('../../../../assets/images/alpha-box/logo2.png')} alt="Logo" className="ab-img brand"
           style={{width: 110,marginTop: 0}} />
          <img src={require('../../../../assets/images/alpha-box/logo3.png')} alt="Logo" className="ab-img brand-min"
            style={{width: 35,marginTop: 0}} />
          </>:
          <></>
        }
        </a>


     </div>
     </header>
    <main role="main">
      <div className="album py-4">
          <div className="container_ app-main-container ">
            <div className="box-key hide-md list  ">
            <div className="col-12r">
              <div className="card mb-12 box-shadow" >
                  <div style={{padding: 8}}>
                    <PopupAgreement />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
        <ContactUs buildingInfo={{}} />
    </div>)
}


export default PrivacyPolicy
