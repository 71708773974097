// import {  } from '../factory'
import { Map } from 'immutable'
import { combineReducers } from 'redux-immutable'
import { createConfigReducer } from "../factory";
export interface IOtpModel extends Map<any, any> {
  otp: any;
}

export default combineReducers({
  otp: createConfigReducer("otpModel")
});
