import React  from 'react';
import AES from "crypto-js/aes";
import moment from 'moment';
import * as Sentry from '@sentry/browser';
declare let chilliController: any;
declare let location: any;
class CommonService {
  timeConfig = 15;
  constructor() {

  }

  mockupChilliS1(){
    // return {
    //   clientState: 0,
    //   challenge: "challenge_id" + Math.floor(Math.random() * 100),
    //   nasid: "00-0C-29-D0-33-32",
    //   session :{
    //     sessionId: "5d6f73ce00000002",//"5d6f73ce00000002"//"5d3ae7ed00000001",//""
    //   },
    //   redir:{
    //     // "originalURL":"http://www.paysbuy.com/api_payment"
    //   }
    // }
    // return {"reloadurl":"http://192.168.182.1:3990/logon?username=NNA000009&response=a2cccdb0772851e8c8d4bb6a16a87d58","reload":true,"clientState":0,"challenge":"54e981686918e1794676fee740b74146","nasid":"00-E2-69-20-DC-B7","redir":{"macAddress":"84-4B-F5-B8-C6-B7"},"session":{"sessionId":"5eb4ab4c0000000d"}};

    return {
      "version":"1.0",
      "clientState":1,
      "challenge":"59a8a817bc6a6618a165a2431fb0cdf1",
      session :{
        // sessionId: "5e8407f900000001",//"5d6f73ce00000002"//"5d3ae7ed00000001"//"5d5e109800000001"
        sessionId:"5d838df10000001b"
      },
      "redir":{
        "originalURL":"",
        "redirectionURL":"",
      // "macAddress":"00-50-56-92-3A-60"
        // "macAddress": "D4-6E-0E-4C-35-19"
      "macAddress": "F4-0F-24-1A-69-31"
    },
      // "message": "You are already logged in - access denied"
    }
  }

  mockupChilliS0(){
    // let y = Math.random();
    // if(y<0.5){
    //   y =Math.floor(y)
    // }else{
    //   y= Math.ceil(y)
    // }
    return {
      clientState: 0,
      challenge: "challenge_id" + Math.floor(Math.random() * 100),
      // nasid: "00-E2-69-20-DC-C13",//"00-0C-29-06-1E-30",//"00-0C-29-D0-33-32",
      session :{
        sessionId: "5e8407f900000001",//"5d6f73ce00000002"//"5d3ae7ed00000001"//"5d5e109800000001"

      },
      message:"Your maximum never usage time has been reached",//,
      redir:{
        // macAddress:"00-E2-69-20-DC-C3"//"00-0C-29-65-AA-D2"
        // macAddress: "D4-6E-0E-4C-35-19",
        "macAddress": "F4-0F-24-1A-69-31"
        // "originalURL":"http://www.paysbuy.com/api_payment"
      }
    }
  }



  calRemainingTime(dateStartAds){
    let time = 0;
    if(dateStartAds){
      let a:any = dateStartAds;
      let b:any  = new Date();
      let difference = (b - a);
      time = (this.timeConfig * 1000)-  difference
      if(time < 0){
        time = 0
      }
    }
    return time
  }

   redirectChilli() {
     console.log("redirectChilli")
    window.location.href = "http://192.168.182.1:3990";
  }

  scrollView(){
    let view:any = document.getElementById('main-view')
    if(view){
      const yCoordinate = view.getBoundingClientRect().top + window.pageYOffset;
      const yOffset = -85;
      window.scrollTo({
          top: yCoordinate + yOffset,
          behavior: 'smooth'
      });
    }
  }

  getChilli(config,params,mode = 'api') {
    return new Promise(async resolve => {
      if (params.preview) {
        return resolve(this.mockupChilliS0());
      }
      let succeed = ['succeed','success','already']
      // if(mode !== 'api'){
        return resolve({
          clientState:succeed.indexOf(params.res) > -1 ?1:0,
          challenge: params.challenge,
          nasid: params.nasid,
          redir:{
            macAddress: params.mac,
          },
          session :{
            sessionId: params.sessionid?params.sessionid:''
          },
        });
      // }

      // console.log("mode:::::3",mode)
      let chilli_https =  true;// params.c === "1" || params.c === 1 ? true :false
      if (location.protocol != 'https:'){
        chilli_https = false;
      }
      if(!chilli_https){
        chilliController.host = config.chilli_host;
        chilliController.port = config.chilli_port;
      }else{
        chilliController.host = config.chilli_hosts;
      }
      chilliController.interval = 60;
      chilliController.ssl = chilli_https;
      chilliController.onError = err => {
        console.log("err", err);
        // this.ErrorChilli("Get Location",{
        //   nasid:params.nasid,
        //   mac:params.mac,
        //   called:params.called,
        //   uamip:params.uamip,
        // })
        // return resolve(false);
        return resolve({
          modeChilli:'redirect',
          clientState:succeed.indexOf(params.res) > -1 ?1:0,
          challenge: params.challenge,
          nasid: params.nasid,
          redir:{
            macAddress: params.mac,
          },
          session :{
            sessionId: params.sessionid?params.sessionid:''
          },
        });
      };
      chilliController.onUpdate = res => {
        console.log("getChilli",chilliController);
        let data = chilliController;
        return resolve(data);
      };
      chilliController.refresh();
    });
  }

  logonChilli(username, password,config,params, mode = 'api') {
    return new Promise(async resolve => {
      console.log("logonChilli>>>>>>>>",params,params.preview);

      if (params.preview) {
        console.log("logonChilli>>>>>>>> ccccc");

        return resolve(this.mockupChilliS1());
      }
        console.log("logonChilli>>>>>>>> ssssss");
      let succeed = ['succeed','success','already']
      // if(mode !== 'api'){
        let url = `http://192.168.182.1:3990/logon?username=${username}&response=${password}`
        return resolve({
          reloadurl:url,
          reload:true,
          clientState:succeed.indexOf(params.res) > -1 ?1:0,
          challenge: params.challenge,
          nasid: params.nasid,
          redir:{
            macAddress: params.mac,
          },
          session :{
            sessionId: params.sessionid?params.sessionid:''
          },
        });
      // }


      let chilli_https = params.c === "1" || params.c === 1 ? true :false
      if (location.protocol != 'https:'){
        chilli_https = false;
      }
      if(!chilli_https){
        chilliController.host = config.chilli_host;
        chilliController.port = config.chilli_port;
      }else{
        chilliController.host = config.chilli_hosts;
      }
      chilliController.interval = 60;
      chilliController.ssl = chilli_https;
      console.log("chilliController logonChilli",username, password);
      chilliController.onError = err => {
        console.log("err", err);
        this.ErrorChilli("Login",{
          nasid:params.nasid,
          mac:params.mac,
          called:params.called,
          uamip:params.uamip,
        })
        return resolve(false);

      };
      chilliController.onUpdate = res => {
        console.log("Chilli",chilliController.challenge);
        let data = chilliController;
        return resolve(data);
      };
      username = username ? username.toLowerCase() :username
      password = password ? password.toLowerCase() :password
      chilliController.logon(username, password);
    });
  }


  isCaptiveIos(){
    try {
      let str = navigator.userAgent;//"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_6) AppleWebKit/605.1.15 (KHTML, like Gecko)"//navigator.userAgent
    str = str ?str.toLocaleLowerCase():str;
      console.log("isCaptiveIos",str, str.indexOf("applewebkit/")  > -1)
    if(
      (str.indexOf("iphone") > -1  || str.indexOf("ipad") > -1  || str.indexOf("macintosh") > -1  ) &&
      //  (str.indexOf("mozilla/")  === -1) &&
       (str.indexOf("applewebkit/")  > -1) &&
      //  (str.indexOf("mobile/")  === -1) &&
       (str.indexOf("safari") === -1)
     ){
      return true;
    }
    return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  ErrorChilli(cmd,obj ={}){
    let error = {
      ...obj,
      "hostname":window.location.hostname,
      "type-error":cmd
    }

    setTimeout(() => { // + moment().format("YYYY-MM-DD HH:mm:ss") +
      console.log("error",JSON.stringify(error))
      // throw 'ErrorChilliAPI ['+obj['nasid']+"] ["+window.location.hostname+"]";
      // Sentry.captureMessage('ErrorChilliAPI ['+obj['nasid']+"] ["+window.location.hostname+"]");

    }, 3000);

  }

}




export default new CommonService();
