import React ,{Component} from 'react';
import './assets/css/global.scss';
import './assets/css/fonts.css';
import './assets/css/animate.min.css';

import {  Route, Switch/* ,  Link */ } from "react-router-dom";
import AppThemes from "./pages/AppThemes";
import MemberConfirmPage from './components/Themes/default/MemberConfirmPage'
import MemberForgotPage from './components/Themes/default/MemberForgotPage'
import Agreement from './components/Themes/default/components/Agreement'
import AuthFacebook from './components/AuthFacebook'
import AuthLine from './components/AuthLine'




import PrivacyPolicyPage from './components/Themes/default/components/PrivacyPolicyPage'

import "./assets/css/antd/antd.css";
import AppConfig  from './services/app.config';
// import * as Sentry from '@sentry/browser';
// import FormTest  from './services/FormTest';

import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';
declare let FB: any;
declare let window: any;





class App extends Component<{},{stratApp:any}> {
  constructor(props){
    console.log("update ","28-02-2020")
      super(props);
      this.state = {stratApp:false}
      this.getInitConfig = this.getInitConfig.bind(this)
      this.getInitConfig();
  }

  componentDidMount(){
      // Load the SDK asynchronously
      // (function(doc, str, id) {
      //   const fjs:any = doc.getElementsByTagName(str)[0];
      //   if (doc.getElementById(id)) {return;}
      //   const js:any = doc.createElement(str);
      //   js.id = id;
      //   js.src = '//connect.facebook.net/en_US/sdk.js';
      //   fjs.parentNode.insertBefore(js, fjs);
      // }(document, 'script', 'facebook-jssdk'));
  }

  getInitConfig(){
    console.log("getInitConfig")
    AppConfig.getConfig().then((config:any)=>{
      this.setState({stratApp:true})
      ReactGA.initialize(config.gAnalytics, {  });
      console.log(config.sentry || "https://22b16ec3d990402ba3a83f3a9850e6e7@sentry.io/1513174");
      Sentry.init({dsn: config.sentry || "https://22b16ec3d990402ba3a83f3a9850e6e7@sentry.io/1513174"});
      // window.fbAsyncInit = function() {
      // FB.init({
      //     appId            : config.fbAppId,
      //     autoLogAppEvents : true,
      //     xfbml            : true,
      //     cookie           : true,
      //     version          : 'v3.1'
      //   });
      // }

    })
  }

  componentDidCatch(error, info) {
    // throw new Error('componentDidCatch');
    console.log(error,info);

    window.location = 'https://szext.smartzoneonline.com' +  window.location.search
    Sentry.captureMessage('/login/locationInfo|all');
  }

  render() {
    // console.log(MaterialUiForm)
    return (
      <>

      {this.state.stratApp?<div>
        {/* <FormTest> </FormTest> */}
          <Switch>
          <Route path={`/privacy-policy`} render={() => (
            <div>
              <h3>ข้อตกลงการใช้งาน (Terms of Use)</h3>
              <Agreement locale={"EN"}/>
            </div>

          )}/>
          <Route
            path={`/member-confirm`}
            component={MemberConfirmPage}
              />
          <Route
            path={`/member-forgot`}
            component={MemberForgotPage}
          />

          <Route path={`/auth/facebook`} component={AuthFacebook}/>
          <Route path={`/auth/line`} component={AuthLine}/>
          <Route path={`/privacy-policy-page`} component={PrivacyPolicyPage}/>

          <Route path="/" component={AppThemes}  />
          </Switch>
      </div>:null}

      </>
    );
  }
}

///auth/facebook
export default App;
