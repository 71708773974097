import React  from 'react';
const Agreement = (props) =>{
  let buildingInfo = props.buildingInfo && props.buildingInfo ? props.buildingInfo : {}
// console.log("buildingInfo:data3",buildingInfo)
  return <div>
    {/* {props.locale} */}
  {/*<h3>ข้อตกลงการใช้งาน (Terms of Use)</h3>*/}
  <ul className="agreement">
 {
   props.locale === 'TH'?
   <li > โปรแกรม {buildingInfo.loginAlphaboxFlag?<>Alpha Box</>:<>SmartZone™</>} ปฏิบัติตามพระราชบัญญัติคอมพิวเตอร์ พ.ศ. 2550 โดยเก็บ log files ของข้อมูลการจราจรทางคอมพิวเตอร์ เป็นเวลา 90 วัน นับตั้งแต่วันที่ข้อมูลนั้นเข้าสู่ระบบคอมพิวเตอร์ ผู้ใช้บริการจะต้องไม่กระทำการใดๆ อันเป็นเหตุทำให้ผู้อื่นเกิดความเสียหายและ/หรือจะต้องไม่กระทำการใดๆ อันเป็นความผิดต่อพระราชบัญญัติว่าด้วยการกระทำผิดเกี่ยวกับคอมพิวเตอร์ พ.ศ.2550 และ/หรือกฎหมายอื่นใดที่กำหนดความผิดเกี่ยวกับการใช้งานคอมพิวเตอร์และการใช้งานเครือข่ายอินเทอร์เน็ต หากผู้ใช้บริการฝ่าฝืน ผู้ใช้บริการจะต้องรับผิดในความเสียหายที่เกิดขึ้นจากกรณีดังกล่าวแต่เพียงผู้เดียว</li>
    :
    <li>{buildingInfo.loginAlphaboxFlag?<>Alpha Box</>:<>SmartZone™</>} software is in abidance with the 2007 Computer Crime Act by keeping log files of computer network traffic for 90 days counting from the day the traffic data entered the system. {buildingInfo.loginAlphaboxFlag?<>Alpha Box</>:<>SmartZone™</>} Users must not do any actions that would cause damage or illegal to the 2007 Computer Crime Act or other laws concerning illegal usage of computer and the internet.  {buildingInfo.loginAlphaboxFlag?<>Alpha Box</>:<>SmartZone™</>} Users who violate these laws are hold solely responsible for the damages occurred by violation.</li>
 }
 {
   props.locale === 'TH'?
      <li>บริการ hi-speed Internet ที่ให้บริการภายในอาคารนี้ เป็นระบบเครือข่ายที่ต่อเชื่อมอินเตอร์เน็ตความเร็วสูงแบบแชร์สปีดจากผู้ให้บริการอินเตอร์เน็ต (ISP) ดังนั้น ความเร็วในการรับ-ส่งข้อมูล จึงขึ้นอยู่กับความเร็วตั้งต้นจากผู้ให้บริการอินเตอร์เน็ต (ISP) และจำนวนผู้ใช้บริการอินเทอร์เน็ตที่ใช้งานอินเทอร์เน็ตอยู่ในขณะนั้น</li>
          :
      <li>Hi-speed internet service in this building or area is a computer network that connects to a shared hi-speed internet connection from an internet service provider (ISP). Therefore, the speed of downloading and uploading data is subject to the speed from the ISP and the number of internet users who are using the service at that time.</li>
 }
{
  props.locale === 'TH'?
  <li>การดาวน์โหลดไฟล์ขนาดใหญ่รวมทั้งการดาวน์โหลดจากเว็บไซต์ต่างประเทศในช่วงเวลาที่มีจำนวนผู้ใช้บริการหนาแน่น (18.00-24.00น.) อาจทำให้ความเร็วของอินเตอร์เน็ตลดลงมาก</li>
    :
  <li>Downloading of large files as well as downloading from international sites during peak usage times (6pm to midnight) may cause a large drop in internet speed.</li>
}
{
  props.locale === 'TH'?
<li>ความเร็วในการเชื่อมต่อ คือ ความเร็วของคอมพิวเตอร์ที่เชื่อมต่อกับโครงข่าย hi-speed Internet (ไม่ใช่ความเร็วในการดาวน์โหลดข้อมูล) ความเร็วในการดาวน์โหลดที่แท้จริงอาจจะต่ำกว่าความเร็วสูงสุดในการเชื่อมต่อตามประเภทที่เลือกใช้บริการฯ โดยเฉพาะเว็บไซต์ที่ตั้งอยู่ต่างประเทศซึ่งขึ้นอยู่กับหลายปัจจัย เช่น ความเข้มของสัญญาณที่เชื่อมต่อ (กรณีเป็นเครือข่ายแบบไร้สาย Wireless) สภาพเครื่องคอมพิวเตอร์ ความเร็วในการเชื่อมต่อของอินเตอร์เน็ต และการติดขัดของข้อมูลอินเตอร์เน็ต รวมถึงอุปกรณ์เซิร์ฟเวอร์ และ Router ของเว็บไซต์ที่ผู้ใช้บริการเข้าเยี่ยมชม และปัจจัยอื่น ๆ อีกหลายประการ</li>
  :
<li>Internet connection speed is that speed which the computer connects to the hi-speed internet network (Not the speed of downloading data). The actual download speed might be lower than the maximum internet connection speed, especially for international websites, and depends upon many other factors, for example; wireless signal density (if connected by wifi), computer condition, internet connection speed, internet latency, as well as the server and router equipment of the website visited, etc.</li>
}
{
  props.locale === 'TH'?
<li>{buildingInfo.loginAlphaboxFlag?<>Alpha Box</>:<>SmartZone™</>} เป็นเพียง Software ที่ใช้ในการบริหารจัดการระบบอินเตอร์เน็ตเท่านั้น ไม่มีส่วนรับผิดชอบในข้อผิดพลาดของระบบและเครือข่ายอินเตอร์เน็ตไม่ว่ากรณีใดๆ และขอสงวนสิทธิ์ในการปิดปรับปรุงระบบเมื่อมีข้อผิดพลาดเกิดขึ้นไม่ว่ากรณีใดๆ </li>
  :
<li>{buildingInfo.loginAlphaboxFlag?<>Alpha Box</>:<>SmartZone™</>} software is software for internet system management only. {buildingInfo.loginAlphaboxFlag?<>Alpha Box</>:<>SmartZone™</>} shall not be liable for any internet errors and reserves the right to shutdown the system for maintenance in all conditions.</li>
}
{
  props.locale === 'TH'?
<li>Username และ Password ที่ถูกต้องจะสามารถใช้งาน ณ สถานที่เดียวกับที่ได้รับ Username และ Password นั้นมาเท่านั้น {buildingInfo.loginAlphaboxFlag?<>Alpha Box</>:<>SmartZone™</>} ไม่รับผิดชอบต่อ Username และ Password ที่ได้รับมาจากสถานที่อื่นแล้วไม่สามารถใช้งานได้</li>
:
<li>Correct Usernames and Passwords are only usable at the location they were received. {buildingInfo.loginAlphaboxFlag?<>Alpha Box</>:<>SmartZone™</>} shall not be liable for unusable Username and Passwords that are received from other locations.</li>
}
{
  props.locale === 'TH'?
<li>{buildingInfo.loginAlphaboxFlag?<>Alpha Box</>:<>SmartZone™</>} ขอสงวนสิทธิ์ระงับการให้บริการชั่วคราว โดยไม่ต้องแจ้งให้ทราบล่วงหน้า หากตรวจพบว่า เครื่องคอมพิวเตอร์ที่เชื่อมต่ออยู่กับระบบนั้นเป็นสาเหตุให้ระบบโดยรวมเกิดปัญหา เช่น ไวรัส, ใช้โปรแกรมประเภท P2P หรือ Torrent ในการดาวน์โหลด</li>
:
<li>{buildingInfo.loginAlphaboxFlag?<>Alpha Box</>:<>SmartZone™</>} reserves the right to to temporarily disable our service without notice if we've detected that your computer has caused problems to the overall system, for example viruses, P2P or Torrent programs for downloading.</li>
}
</ul>
<br/>
<b style={{color: 'rgba(0, 0, 0, 0.85)' ,fontWeight: 500,fontSize: '16px' ,lineHeight: '22px' ,}}> {
  props.locale === 'TH'?"คำเตือน!":"Warning!"}
</b>
<ul>
{
  props.locale === 'TH'?
<li>การดาวน์โหลดไฟล์ที่มีลิขสิทธิ์ต่างๆ โดยไม่ได้รับอนุญาต ถือเป็นการละเมิดลิขสิทธิ์ซึ่งผู้กระทำการมีความผิดทางกฎหมาย ทาง {buildingInfo.loginAlphaboxFlag?<>Alpha Box</>:<>SmartZone™</>} ไม่มีส่วนรับผิดชอบใดๆ ทั้งสิ้น</li>
  :
<li>Downloading licensed material from the internet without the owner's persmission is a violation of rights and is considered illegal. {buildingInfo.loginAlphaboxFlag?<>Alpha Box</>:<>SmartZone™</>} takes no responsibility for the user's illegal action.</li>
}
</ul>
<br/>
<p><br/></p>

  </div>
}

export default Agreement;
