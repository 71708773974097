import React,{}  from 'react';
import { thisExpression } from '@babel/types';
import AD from "./Ad";
import AdCustom from './AdCustom';
declare let $: any;
declare let window: any;


class Advertisement extends React.Component<{buildingInfo:any,reviveBannerId:any,loginAlphaboxFlag:any,updateDetachAds:any,newBannerId:any,user:any,setCountTime:any}, {mode:any,height:any,scroll:any,isFixed:any,top:any,newBannerIdArr:any,user:any}>{

  instance:any;
  constructor(props) {
    super(props);
    let mode:any = null;
    let newIdArr = props.newBannerId
    let reviveBannerId = props.reviveBannerId
    let newBannerIdArr:any = []
      if(this.props.user && newIdArr.length > 0){
        if(newIdArr.length>=2 && newIdArr[1] && newIdArr[1] !== '0' ){
        newBannerIdArr.push({id:newIdArr[1]})
        }
      }else{
        if(newIdArr.length>=1  &&  newIdArr[0] && newIdArr[0] !== '0'){
          newBannerIdArr.push({id:newIdArr[0]})
        }
      }

      if(newBannerIdArr.length === 0 ){
        if(props.user && reviveBannerId.length > 0){
          if(reviveBannerId.length>=2 && reviveBannerId[1] !== '0' ){
              newBannerIdArr.push({id:reviveBannerId[1]})
          }
        }else{
          if(reviveBannerId.length>=1  && reviveBannerId[0] && reviveBannerId[0] !== '0'){
            newBannerIdArr.push({id:reviveBannerId[0]})
          }
        }
        mode = 1;
      }

    this.state = {
      newBannerIdArr:newBannerIdArr,
      user:{},
      isFixed:false,scroll:0,top:0,height:0,mode:mode
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
    handleScroll() {
      var w = window,
          d = document,
          e = d.documentElement,
          g = d.getElementsByTagName('body')[0],
          x = w.innerWidth || e.clientWidth || g.clientWidth,
          y = w.innerHeight|| e.clientHeight|| g.clientHeight;
      let isFixed = false;
      if(window.scrollY > this.state.top  && x < 768 ){
        isFixed = true;
      }
        this.setState({isFixed:isFixed });
    }

    componentDidMount() {
      const el:any = document.getElementById("abs");
      if(el && this.instance){
        let instance  = this.instance.getBoundingClientRect();
        this.setState({  top: window.scrollY + instance.top, height:instance.height });
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener("resize", this.handleScroll, true);
      }

  }

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 componentWillReceiveProps(nextProps) {
  const currentPage = this.props.user;
  const nextPage = nextProps.user;
  if (currentPage !== nextPage) {



    let newIdArr = nextProps.newBannerId;
    let reviveBannerId = nextProps.reviveBannerId;
    let newBannerIdArr:any = []
    let tempId = null;
    let tempIdOld = null;
    let mode:any = null;
    if(this.state.newBannerIdArr.length > 0){
      tempIdOld = this.state.newBannerIdArr[0].id
    }



      if(nextProps.user && newIdArr.length > 0){
        if(newIdArr.length>=2 && newIdArr[1] && newIdArr[1] !== '0' ){
            newBannerIdArr.push({id:newIdArr[1]})
            tempId = newIdArr[1]
        }
      }else{
        if(newIdArr.length>=1  && newIdArr[0] && newIdArr[0] !== '0'){
          newBannerIdArr.push({id:newIdArr[0]})
          tempId = newIdArr[0]
        }
      }


      if(newBannerIdArr.length === 0 ){
        if(nextProps.user && reviveBannerId.length > 0){
          if(reviveBannerId.length>=2 && reviveBannerId[1] && reviveBannerId[1] !== '0' ){
              newBannerIdArr.push({id:reviveBannerId[1]})
              tempId = reviveBannerId[1]
          }
        }else{
          if(reviveBannerId.length>=1  && reviveBannerId[0] && reviveBannerId[0] !== '0'){
            newBannerIdArr.push({id:reviveBannerId[0]})
            tempId = reviveBannerId[0]
          }
        }
        mode = 1;
        // newBannerIdArr.push({id:this.props.reviveBannerId[2]})
      }


      // console.log("newBannerIdArr:10",newBannerIdArr)
      if(tempIdOld !== tempId){
        this.setState({newBannerIdArr:[]},()=>{
          setTimeout(() => {
            this.setState({newBannerIdArr:newBannerIdArr,mode:mode})
          },10);
        })
      }
  }
}

//  componentDidMount () {}
    render() {

      let {newBannerIdArr}= this.state
        return  <>
        {
          this.props.buildingInfo.enableAdvertisement === 1?<>
              <div style={{padding: 8}}>
                <AdCustom advertisement={this.props.buildingInfo.advertisement} type={this.props.user?'x':'w'} buildingInfo={this.props.buildingInfo}/>
              </div>
            </>:
        <>
          {
            newBannerIdArr.length > 0?
                <div className={this.state.isFixed? "abs-info fixed" : "abs-info"} style={{textAlign: 'center',padding: '8px 8px'}}  ref={(el) => this.instance = el } id="abs">
                  {
                          newBannerIdArr.map((r)=>{
                            return <AD keyData={"4"}  type="" id={r.id} updateDetachAds={this.props.updateDetachAds} setCountTime={this.props.setCountTime} mode={this.state.mode} />
                        })
                  }
               </div>
               :<div>

                 {
                   this.props.loginAlphaboxFlag?
                    <img src={require('../../../../assets/images/alpha-box/banner_vdo.png')} style={{width:"100%",maxHeight:'300px'}}/>
                   :<img src={require('../../../../assets/images/banner_vdo.png')} style={{width:"100%",maxHeight:'300px'}}/>
                 }

               </div>}
               </>}


               </>

  }
}


export default Advertisement;
