import AppConfig  from '../services/app.config';
import store  from '../data/index';
const axios = require('axios');
const uuid = require("uuidv4");



class Request {
  api: any;
  x_transaction_id: any;
  constructor() {
    this.getConfig();
  }

  getConfig() {
    AppConfig.getConfig().then((config:any)=>{
      this.api = config.app_api;
      this.x_transaction_id = config.x_transaction_id;
      console.log('====================================');
      console.log("config",config);
      console.log('====================================');
   })

  }

  get(url, data?, headers?) {
    console.log("get:",url,data,headers);
    return axios
      .get(this.api + url, {
        params: data,
        timeout: 15000,
        headers: {
          language: this.getLocale(),
          "x-transaction-id":  this.x_transaction_id,
          ...headers
        }
      })
      .then(response => response.data)
      .then(
        data => {
          console.log(data);
          return data;
        },
        err => {
          console.log(err);
          return false;
        }
      );
  }

  getOther(url, data?, headers?) {
    console.log("get:",url,data,headers);
    return axios
      .get(url, {
        params: data,
        timeout: 15000,
        headers: {
          ...headers
        }
      })
      .then(response => response.data)
      .then(
        data => {
          console.log(data);
          return data;
        },
        err => {
          console.log(err);
          return false;
        }
      );
  }

  post(url, data?, headers?) {
    console.log("post:",this.api + url);
    return axios
      .post(this.api + url, data, {
        timeout: 15000,
        headers: {
          language: this.getLocale(),
          "x-transaction-id":  this.x_transaction_id,
          ...headers
        }
      })
      .then(response => response.data)
      .then(
        data => {
          console.log(data);
          return data;
        },
        err => {
          console.log(err);
          return false;
        }
      );
  }

  post2(url, data?, headers?) {
    console.log("post:",this.api + url);
    return axios
      .post(this.api + url, data, {
        timeout: 15000,
        headers: {
          language: this.getLocale(),
          "x-transaction-id":  this.x_transaction_id,
          ...headers
        }
      })
      .then(response => response.data)
      .then(
        data => {
          console.log(data);
          return data;
        },
        err => {
          console.log(err);


          // return false;
          return {
            description:"" + err ,
            errorMessage:"" + err,
            resultCode: "FE"
          };

        }
      );
  }

  postForm(options) {
    // console.log("get:",url,data,headers);
    return axios(options)
      .then(response => response.data)
      .then(
        data => {
          console.log(data);
          return data;
        },
        err => {
          console.log(err);
          return false;
        }
      );
  }

  getLocale() {


    let languageCode = "EN";
        try {
          let s:any = store();
          languageCode = s.getState().i18n.locale
        }
        catch (e) {

        }
    return languageCode;
  }


}


export default new Request();
