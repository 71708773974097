

import React, { Component } from 'react';
import i18n from "../../../i18n/index";
import { Button  ,Form ,Typography } from 'antd';
import Notification  from '../../../services/notification.service';
// import { Translate } from 'react-redux-i18n'
import queryString from 'query-string';
import { connect } from 'react-redux'
import { getCurrentLocale } from "../../../data/utils";
import DictionaryService  from '../../../i18n/DictionaryService';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import Request  from '../../../services/request.service';
import _ from 'lodash';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Lens from '@material-ui/icons/Lens';
import { withStyles } from '@material-ui/core/styles';
import AppConfig  from '../../../services/app.config';
import MenuItem from '@material-ui/core/MenuItem';
import ScValidator  from '../../../services/ScValidator';
import moment from "moment";
import ReactGA from 'react-ga';
const { Title } = Typography;
// import { connect } from 'react-redux';
declare let chilliController: any;
declare let location: any;
const LensChecked = withStyles({
  root: {
    color: 'rgb(248, 153, 50)',
  }
})(Lens);
const LensUnCheckedChecked = withStyles({
  root: {
    color: '#fff',
  }
})(Lens);



type TranslateProps = {
  value: any,
  locale:any
}
type TranslateState = {}
class TranslatePage extends Component<TranslateProps, TranslateState> {
  constructor(props) {
    super(props)
  }
  render() {
    let locale = "EN";
    try{
      locale = this.props.locale;
    }catch(e){

    }
    return (<span> {DictionaryService.msg(this.props.value,locale)}</span>);
  }
}

const mapStateToProps0: any = (state: any) => ({
  locale: getCurrentLocale(state),
});

const mapDispatchToProps0 = {}
const Translate:any = connect<any, any, {}>(mapStateToProps0, mapDispatchToProps0)(Form.create({})(TranslatePage));



let $profile:any = {}
class EditProfile extends Component<{ location:any, buildingInfo:any,ChilliData:any,history:any,changePage:any,registerUser:any,registerAction:any,form:any,submitRegister:any,locale:any},
{formConfig:any,fields:any,fieldsLogin:any,countryCodes:any,occupation:any,reloading:any,resData:any,btDisable:any,user:any,v_date:any,gender:any,date:any,v_idCard:any,v_passportNo,loading:any,citizenId:any,passportNo:any}> {
  formLogin:any;
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      reloading:false,
      citizenId:"",
      passportNo:"",
      fieldsLogin:[],
      v_idCard:{
        validateStatus: 'success',
        errorMsg: null,
      },
      v_passportNo:{
        validateStatus: 'success',
        errorMsg: null,
      },
      v_date:{
        validateStatus: 'success',
        errorMsg: null,
      },
      date:new Date(),
      user:{},
      gender:"",
      btDisable:true,
      resData:{},
      occupation:[],
      countryCodes:[],
      fields:{},
      formConfig:[],
    }//user:props.registerUser
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.onFocus = this.onFocus.bind(this);

  }

  componentDidMount() {

    Promise.all([this.getOccupation(),this.getCountryCodes()]).then(() => {
      this.getProfile()
    });

  }

  getCountryCodes(){
    return Request.getOther("/json/CountryCodes.json", {}, {}).then((res: any) => {
      this.setState({countryCodes:res || []})
      return res
      }).catch(e => {
        console.log(e);
        return e
      });
  }



  getOccupation(){
    return Request.get("/dropdown/occupation", {}, {
      "x-access-id": this.props.buildingInfo.data.accessToken
    }).then((res: any) => {
        if (res.resultCode && res.resultCode[0] === "2") {//) === "20000"
          this.setState({occupation:res.resultData})
        } else {

        }
        return res
      }).catch(e => {
        console.log(e);
        return e
      });
  }

getProfile(){
    this.setState({loading:true,btDisable:true})
    let sessionId = this.props.ChilliData&& this.props.ChilliData.session ? this.props.ChilliData.session.sessionId :"";
    return Request.get("/register/profile", {sessionId:sessionId}, {
      "x-access-id": this.props.buildingInfo.data.accessToken
    }).then(async (res)=>{

      if(res.resultCode && res.resultCode[0] === "2"){
        $profile = _.cloneDeep(res.profile) || {}
        this.reverse($profile)
        this.getConfigForm($profile);
      }else{
        this.setState({loading:false})
        let msg = res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
        Notification.error({
          message:"",
          description:msg,
          resultCode:res.resultCode
        });
        this.props.history.push({ pathname:"/", search:this.props.location.search});
      }
      return res
    }).catch(e => {
      this.setState({loading:false})
      console.log(e);
        let msg = e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
        Notification.error({
          message:"",
          description:msg,
        });
        this.props.history.push({ pathname:"/", search:this.props.location.search});;

      return {}
    });
  }

  validateIdCard(data) {
    if(!this.state.passportNo ){
      if (!this.state.citizenId) {
        return {
          validateStatus: 'error',
          errorMsg: DictionaryService.msg("vPassport",this.props.locale),
        };
      }
    }

    if (this.checkID(data) || (this.state.passportNo && !this.state.citizenId)) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }
    return {
      validateStatus: 'error',
      errorMsg:  DictionaryService.msg("vIDcard_",this.props.locale),
    };
  }

  checkID(id){
    if(!id ||id.length != 13) return false;
    let sum=0
    for(let i=0; i < 12; i++)
      sum += parseFloat(id.charAt(i))*(13-i);

      if((11-sum%11)%10!=parseFloat(id.charAt(12)))
      return false;

      return true;
    }

  validatePassport(data) {
    // console.log(this.state.citizenId , this.state.passportNo ,!!this.state.citizenId , !!!this.state.passportNo  )
    if(!this.state.passportNo ){
      if (!this.state.citizenId) {
        return {
          validateStatus: 'error',
          errorMsg: DictionaryService.msg("vPassport",this.props.locale),
        };
      }
    }

    var re = /[a-zA-Z]{2}[0-9]{7}/;
    if ((re.test(data) && data && data.length <= 16  )  || (this.state.citizenId && !this.state.passportNo  )) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }
    return {
      validateStatus: 'error',
      errorMsg: DictionaryService.msg("vPassport_",this.props.locale),
    };
  }

  validateDate(data) {

    if (data) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }
    return {
      validateStatus: 'error',
      errorMsg: DictionaryService.msg("vBirthday",this.props.locale),
    };
  }

  handleIdCardChange = value => {
    let icvalue = {...this.validateIdCard(this.state.citizenId)}
    let ppvalue = icvalue.validateStatus === 'success'? icvalue : {...this.validatePassport(this.state.passportNo)}

    this.setState({

      v_idCard: {
        ...icvalue,
        value:this.state.citizenId
      },
      v_passportNo: {
        ...ppvalue,
        value:this.state.passportNo
      },
    });
  };


  handlePassportNoChange= value => {
    let ppvalue = {...this.validatePassport(this.state.passportNo)}
    let icvalue = ppvalue.validateStatus === 'success'? ppvalue : {...this.validateIdCard(this.state.citizenId)}
    this.setState({
      v_idCard: {
        ...icvalue,
        value:this.state.citizenId
      },
      v_passportNo: {
        ...ppvalue,
        value:this.state.passportNo
      },
    });
  };

  handleDateChange = value => {
   this.setState({

      v_date: {
        ...{...this.validateDate(value)},
        value:value
      },

    });
  };

  scrollView(){
    let view:any = document.getElementById('main-view')
    if(view){
      const yCoordinate = view.getBoundingClientRect().top + window.pageYOffset;
      const yOffset = -85;
      try {
        window.scrollTo({
            top: yCoordinate + yOffset,
            behavior: 'smooth'
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  submitUpdateProfile(item){

    return new Promise(async resolve => {
      Request.post("/register/editProfile", {...item,gender:+item.gender}, {
        "x-access-id": this.props.buildingInfo.data.accessToken
        }).then(async (res: any) => {
          this.setState({btDisable:true})
          if (res.resultCode && res.resultCode[0] === "2") {
            this.scrollView()
          let msg = res.errorMessage || "Success"
          Notification.success({
              message:"",
              description:msg,
            });
        }else{
          let msg = res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")

          Notification.error({
            message:"",
            description:msg,
            resultCode:res.resultCode
            });
        }
          resolve(res)
        }).catch(e => {
          console.log(e);
          let msg = e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")

          Notification.error({
            message:"",
            description:msg
            });

          return resolve(false);
        });

      })
  }

  async ChilliLogOff() {
    return new Promise(async resolve => {
      let config: any = await AppConfig.getConfig();
      let params: any = queryString.parse(this.props.location.search);
      let chilli_https =  params.c === "1" || params.c === 1 ? true :false;
      if (location.protocol != 'https:'){
        chilli_https = false;
      }
      if(!chilli_https){
        chilliController.host = config.chilli_host;
        chilliController.port = config.chilli_port;
      }else{
        chilliController.host = config.chilli_hosts;
        // chilliController.port = config.chilli_port;
      }
      chilliController.interval = 60;
      chilliController.ssl = chilli_https;
      chilliController.onError = err => {
        return resolve(false);
      };
      chilliController.onUpdate = res => {
        let data = chilliController;
        return resolve(data);
      };
      chilliController.logoff();
    });
  }

  async redirectChilli() {
    let config: any = await AppConfig.getConfig();
        window.location.href = `http://${config.chilli_host}:${config.chilli_port}`;
  }


  updatePassword(item){
    let sessionId = this.props.ChilliData&& this.props.ChilliData.session ? this.props.ChilliData.session.sessionId :"";

    return new Promise(async resolve => {
      Request.post("/register/changePassword", {email:this.state.user.username,sessionId:sessionId,...item,password:item.newPassword}, {
        "x-access-id": this.props.buildingInfo.data.accessToken
        }).then(async (res: any) => {
          if (res.resultCode && res.resultCode[0] === "2") {
          let msg = res.errorMessage || "Success"
          Notification.success({
              message:"",
              description:msg,
            });
            this.redirectChilli();
        }else{
          let msg = res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")

          Notification.error({
            message:"",
            description:msg,
            resultCode:res.resultCode
            });
        }
          resolve(res)
        }).catch(e => {
          console.log(e);
          let msg = e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")

          Notification.error({
            message:"",
            description:msg,
            });

          return resolve(false);
        });

      })
  }

  handleSubmit(e){
    e.preventDefault();
    this.handleIdCardChange(this.state.passportNo)
    this.handleDateChange(this.state.date)
    setTimeout(()=>{
      this.props.form.validateFields(async (err, values) => {

        if(!values.nationality){
          values.nationality = ''
        }
        if(!values.occupation){
          values.occupation =  null
        }
        if (!err && ( this.state.v_date.validateStatus === 'success'  && ( this.state.v_idCard.validateStatus === 'success' &&  this.state.v_passportNo.validateStatus==='success'))) {
          this.setState({loading:true})
          let sessionId = this.props.ChilliData&& this.props.ChilliData.session ? this.props.ChilliData.session.sessionId :"";
          await this.submitUpdateProfile({isMobileLogin:this.state.user.isMobileLogin,sessionId:sessionId,email:this.state.user.username,areaCode:this.state.user.areaCode,mobileNo:this.state.user.mobileNo,...values,birthday:this.state.user.birthday,occupation:this.state.user.occupation})
          this.setState({loading:false})
          this.getProfile()
        }
      });
    },50)

  }

  onFocus(){
    this.setState({btDisable:false})
  }

  reverse(profile:any = false){
    this.setState({reloading:true})
    if(!profile){
      profile = $profile
    }
    let gender:any = null;
    if(profile.gender ===0 || profile.gender ===1){
      gender = profile.gender===1?"1":"0"
    }
    if(!profile.areaCode)
    profile.areaCode = '+66'
    this.props.form.setFieldsValue({
      firstName:profile.firstName,
      surName:profile.surName,
      citizenId:profile.citizenId,
      passportNo:profile.passportNo,
      birthday:profile.birthday,
      nationality:profile.nationality,
      gender:gender,
      occupation:profile.occupation,
      areaCode:profile.areaCode,
      mobileNo:profile.mobileNo
    })


    this.setState({user:profile,citizenId:profile.citizenId,passportNo:profile.passportNo,gender:gender ,
      v_idCard:{
        validateStatus: 'success',
        errorMsg: null,
      },
      v_passportNo:{
        validateStatus: 'success',
        errorMsg: null,
      },
      v_date:{
        validateStatus: 'success',
        errorMsg: null,
      }

      ,})

      setTimeout(() => {
        this.setState({reloading:false},()=>{

        })
      }, 0);

  }

  isDisabledBT(){
    let errs = this.props.form.getFieldsError();
    let isErr = false;
    let flist = [
      // 'email',
      'firstName',
      'gender',
      'surName'
    ]
    let fvlist =this.props.form.getFieldsValue(flist)
    for (const i in fvlist) {
      if(!fvlist[i]){
        isErr = true
      }
    }

    for (const i in errs) {
      if(errs[i]){
        isErr = true
      }
    }
    // console.log(fvlist,errs,( this.state.v_date.validateStatus === 'success'  && ( this.state.v_idCard.validateStatus === 'success' &&  this.state.v_passportNo.validateStatus==='success')))
    return !( !isErr && ( this.state.v_date.validateStatus === 'success'  && ( this.state.v_idCard.validateStatus === 'success' &&  this.state.v_passportNo.validateStatus==='success')))
  }



  componentWillReceiveProps(nextProps) {
    if(nextProps.locale !== this.props.locale){
      this.getConfigFormLang(nextProps.locale);
    }
  }

  getConfigForm(profile){
    let temp = {}
    for (let i in profile) {
      temp[i] =  Form.createFormField({
        ...profile[i],
        value: profile[i],
      })
    }
    console.log("profile::",profile)

    Request.get("/register/form", {custGroupId: this.props.buildingInfo.data.custgroupId,}, {
      "x-access-id": this.props.buildingInfo.data.accessToken
    }).then((res: any) => {
      this.setState({loading:false})
        if (res.resultCode && res.resultCode[0] === '2') {//) === "20000"
        let resData = res.resultData || [];
        resData = resData.map((r)=>{
          return {name:r.codeName,required:r.requiredField === 1 || r.requiredForce ===1  ? true : false};
        })
          this.setState({resData:resData,
            fields: {
              data:temp
            },
          },()=>{
            this.getConfigFormLang(this.props.locale)


            this.setState({loading:false})
          })

        } else {
          let msg = res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
          Notification.error({
            message:"",
            description:msg,
            resultCode:res.resultCode
          });
          this.props.history.push({ pathname:"/", search:this.props.location.search});
        }
      })
      .catch(e => {
        this.setState({loading:false})
        console.log(e);
          let msg = e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")
          Notification.error({
            message:"",
            description:msg,
          });
          this.props.history.push({ pathname:"/", search:this.props.location.search});
      });
  }

  getConfigFormLang(locale){
    let data:any = []
    let resData = this.state.resData || [];
    let list:any = [];

    let isMobile = false
    if(resData && this.state.user.isMobileLogin){
      for (const i in resData) {
        if(resData[i].name === 'mobileNo'){
          isMobile = true
        }
      }
      if(!isMobile){
        list.push( {name:'areaCode',required: true})
        list.push( {name:'mobileNo',required: true})
      }
    }

    // let isEmail = false
    // if(resData && this.state.user.isMobileLogin){
    //   for (const i in resData) {
    //     if(resData[i].name === 'mobileNo'){
    //       isMobile = true
    //     }
    //   }
    //   if(!isMobile){
    //     list.push( {name:'areaCode',required: true})
    //     list.push( {name:'mobileNo',required: true})
    //   }
    // }

    for (const i in resData) {
      if(resData[i].name === 'password'){

      }else if(resData[i].name === 'email'){
        list.push( {name:'email',required:resData[i].required})
      }else if(resData[i].name === 'name'){
        list.push( {name:'firstName',required:resData[i].required})
        list.push( {name:'surName',required:resData[i].required})
      }else if(resData[i].name === 'mobileNo'){
        list.push( {name:'areaCode',required:resData[i].required})
        list.push( {name:'mobileNo',required:resData[i].required})
      }else{
        list.push(resData[i])
      }
    }


    list = list.map(r=>{
      let value:any = ''
      if(r.name==='gender'){
        value = this.state.fields.data[r.name].value === 0 || this.state.fields.data[r.name].value === 1 ? this.state.fields.data[r.name].value + "":null
        // console.log("gender : value",value)
      }if(this.state.fields && this.state.fields.data && this.state.fields.data[r.name]){
        value = this.state.fields.data[r.name].value
      }else if(r.name==='birthday'){
        value = moment().add("years",-20).toDate()
      }else if(r.name==='gender'){
        value = null
      }else if(r.name==='areaCode'){
        value = "+66"
      }
      let disabled = false
      if(r.name === "email" && !this.state.user.isMobileLogin ){
        disabled = true
      }else if(this.state.user.isMobileLogin && (r.name === "mobileNo"|| r.name === "areaCode") ){
        disabled = true
      }


      return {...r,value:value,disabled:disabled}
    })
    for(let i in list){
      data.push(this.ifTypeForm(list[i].name,list[i].required,list[i].value,list[i].disabled,locale))
    }
    // console.log("datadatadata",data)
    this.setState({formConfig:data})
  }

  ifTypeForm(name,required,value,disabled,locale){
    if(name === 'username'){
    return {
      name : 'username',
      label : DictionaryService.msg("username",locale),
      type : 'text',
      value : value,
      disabled: disabled,
      xs:10,
      end : <Grid item  xs={1}></Grid>,
      requiredUI:required,
      start : <Grid item  xs={1}><div className="sz-icons"><div className="icon username"></div></div></Grid> ,
      rules: [
        {
          required: required,
          whitespace: true,
          message: DictionaryService.msg("vUsername",locale),
        },
      ],
      }
    }
    else if(name === 'firstName'){
      return {
        name : 'firstName',
        label : DictionaryService.msg("firstName",locale),
        type : 'text',
        value : value,
        disabled: disabled,
        xs:5,
        requiredUI:required,
        start : <Grid item  xs={1}><div className="sz-icons"><div className="icon username"></div></div></Grid> ,
        rules: [
          {
            required: required,
            whitespace: true,
            message: DictionaryService.msg("vFirstName",locale),
          },
        ],
      }
    }
    else if (name === 'surName'){
      return {
        name : 'surName',
        label : DictionaryService.msg("lastName",locale),
        type : 'text',
        disabled: disabled,
        value : value,
        xs:5,
        requiredUI:required,
        end : <Grid item  xs={1}></Grid>,
        rules: [
          {
            required: required,
            whitespace: true,
            message: DictionaryService.msg("vLastName",locale),
          },
        ],
      }
    }
    else if (name === 'email'){
      return {
        name : 'email',
        label : DictionaryService.msg("email",locale),
        type : 'text',
        disabled: disabled,
        value : value,
        xs:10,
        requiredUI:true,
        start : <Grid item  xs={1}><div className="sz-icons"><div className="icon email"></div></div></Grid> ,
        end : <Grid item  xs={1}></Grid>,
        rules: [
          { required: true,
            message: DictionaryService.msg("vEmail",locale)
          },
          {
            type: 'email',
            message: DictionaryService.msg("vEmail",locale),
          },
        ],
      }
    }else if(name === 'citizenId'){
      return   {
          name : 'citizenId',
          label : DictionaryService.msg("idCard",locale),
          type : 'text',
          disabled: disabled,
          value : value,
          xs:10,
          requiredUIDynamic:true,
          requiredUIDynamicFuction:()=>{
            if(!required){
              return required;
            }

            if(!this.formLogin)
            return true;

            let passportNo = this.formLogin.getFieldValue("data[passportNo]")
            let citizenId = this.formLogin.getFieldValue("data[citizenId]")
            if(passportNo && !citizenId){
              return false;
            }
            return true;

          },
          start : <Grid item  xs={1}><div className="sz-icons"><div className="icon citizen"></div></div></Grid> ,
          end : <Grid item  xs={1}></Grid> ,
          rules: [
            {
              validator:(res,value)=>{

                if( this.formLogin){
                  let error = this.formLogin.getFieldError(`data[passportNo]`)
                  if(error && error.length > 0){
                    this.formLogin.validateFields(['data[passportNo]'], { force: true });
                  }
                }else{}

              if(!required){
                return true;
              }
              let error_ = this.formLogin.getFieldError(`data[passportNo]`)
              let value_ = this.formLogin.getFieldValue(`data[passportNo]`)
              //  (`data[citizenId]`)
              if((!value || value && value.length === 0 ) && ( !value_ || error_ && error_.length > 0))
              return false;


              return true;

              },
              message: DictionaryService.msg("vPassport",locale),
            },
            {
              validator:(res,value)=>{
                if(!required){
                  if(!value || value && value.length === 0)
                  return true;

                  if(!value || value.length != 13)
                  return false;

                  let sum=0
                  for(let i=0; i < 12; i++)
                    sum += parseFloat(value.charAt(i))*(13-i);

                    if((11-sum%11)%10!=parseFloat(value.charAt(12)))
                    return false;

                  return true;
                }

                if( this.formLogin){
                  let error = this.formLogin.getFieldError(`data[passportNo]`)
                  if(error && error.length > 0){
                    this.formLogin.validateFields(['data[passportNo]'], { force: true });
                  }
                }
               if((!value || value.length === 0 )&& !this.formLogin.getFieldError(`data[passportNo]`)&& this.formLogin.getFieldValue(`data[passportNo]`)){
                  return true;
                }


                if(value && value.length === 0)
                return true;

                if(!value || value.length != 13) return false;

                let sum=0
                for(let i=0; i < 12; i++)
                  sum += parseFloat(value.charAt(i))*(13-i);

                  if((11-sum%11)%10!=parseFloat(value.charAt(12)))
                  return false;

                  return true;
              },
              message: DictionaryService.msg("vIDcard_",locale),
            }
          ],
        }
    }  else if(name === 'passportNo'){
      // console.log("passportNo:passportNo")
      return {
        name : 'passportNo',
        label : DictionaryService.msg("passport",locale),
        type : 'text',
        disabled: disabled,
        value : value,
        xs:10,
        requiredUIDynamic:true,
        requiredUIDynamicFuction:()=>{

          if(!required){
            return required;
          }

          if(!this.formLogin)
          return true;

          let passportNo = this.formLogin.getFieldValue("data[passportNo]")
          let citizenId = this.formLogin.getFieldValue("data[citizenId]")
          if(!passportNo && citizenId){
            return false;
          }
          return true;

        },
        start : <Grid item  xs={1}><div className="sz-icons"><div className="icon passport"></div></div></Grid> ,
        end : <Grid item  xs={1}></Grid> ,
        rules: [
          {
            validator:(res,value)=>{

              if( this.formLogin){
                let error = this.formLogin.getFieldError(`data[citizenId]`)
                if(error && error.length > 0){
                  this.formLogin.validateFields(['data[citizenId]'], { force: true });
                }
              }else{}

            if(!required){
              return true;
            }
            let error_ = this.formLogin.getFieldError(`data[citizenId]`)
            let value_ = this.formLogin.getFieldValue(`data[citizenId]`)
            //  (`data[citizenId]`)
            if((!value || value && value.length === 0 ) && ( !value_ || error_ && error_.length > 0))
            return false;


            return true;

            },
            message: DictionaryService.msg("vPassport",locale),
          },
          {
            validator:(res,value)=>{
              if(!required){
                if(!value || value && value.length === 0)
                return true;

                var re = /[a-zA-Z]{2}[0-9]{7}/;
                if (!re.test(value)) {
                  return false;
                }
                return true;
              }

              if( this.formLogin){
                let error = this.formLogin.getFieldError(`data[citizenId]`)
                if(error && error.length > 0){
                  this.formLogin.validateFields(['data[citizenId]'], { force: true });
                }
              }

              if((!value || value.length === 0 )&& !this.formLogin.getFieldError(`data[citizenId]`)&& this.formLogin.getFieldValue(`data[citizenId]`)){
                return true;
              }

              if(value && value.length == 0)
              return true;


              var re = /[a-zA-Z]{2}[0-9]{7}/;
              if (re.test(value)) {
                return true;
              }

              return false;


            },
            message: DictionaryService.msg("vPassport_",locale),
          }
        ],
      }

    } else if(name === 'nationality'){
      return {
        name : 'nationality',
        label : DictionaryService.msg("nationality",locale),
        type : 'text',
        disabled: disabled,
        value : value,
        xs:10,
        requiredUI:required,
        start : <Grid item  xs={1}><div className="sz-icons"><div className="icon nationality"></div></div></Grid> ,
        end : <Grid item  xs={1}></Grid> ,
        rules: [
        ],
      }
    }else if(name === 'birthday'){
      return {
        name : 'birthday',
        label : DictionaryService.msg("dateOfBirth",locale),
        type : 'date',
        disabled: disabled,
        value : value,
        xs:6,
        requiredUI:required,
        start : <>
              <Grid item  xs={1}><div className="sz-icons"><div className="icon date"></div></div></Grid>
              <Grid item  xs={4} style={{padding: "0px 8px 0px !important"}}>
              <div style={{    position: 'relative',top: -4,color:"#fff",width: 85}} className={required?"MuiTypography-body1_ rq-lable":"MuiTypography-body1_"}>
              {DictionaryService.msg("dateOfBirth",locale)}
              </div>
              </Grid>
              </> ,
         end : <Grid item  xs={1}></Grid> ,
        rules: [
          {
            required: required,
            message: DictionaryService.msg("vBirthday",locale),
          },
        ],
      }
    } else if(name === 'nationality'){
      return {
        name : 'nationality',
        label : DictionaryService.msg("nationality",locale),
        type : 'text',
        disabled: disabled,
        value : value,
        xs:10,
        requiredUI:false,
        start : <Grid item  xs={1}><div className="sz-icons"><div className="icon nationality"></div></div></Grid> ,
        end : <Grid item  xs={1}></Grid> ,
        rules: [
        ],
      }

    }else if(name === 'occupation'){
      let occupation = this.state.occupation || []

      occupation = occupation.map((option)=>{
        let label =  option.occupationNameEn;
        if(locale==='TH'){
          label = option.occupationNameTh
        }
        return {value:option.id,label:label}
      })

      return {
        name : 'occupation',
        // label : DictionaryService.msg("occupation",locale),
        type : 'seletebox',
        disabled: disabled,
        value : value,
        items:occupation,
        xs:6,
        requiredUI:false,
        start : <>
              <Grid item  xs={1}><div className="sz-icons"><div className="icon occupation"></div></div></Grid>
              <Grid item  xs={4} style={{padding: "0px 8px 0px !important"}}>
              <div style={{    position: 'relative',top: -4,color:"#fff",width: 85}} className={required?"MuiTypography-body1_ rq-lable":"MuiTypography-body1_"}>
                {DictionaryService.msg("occupation",locale)}
              </div>
              </Grid>
              </> ,
        end : <Grid item  xs={1}></Grid> ,
        rules: [
          {
            required: required,
            message: DictionaryService.msg("vOccupation",'TH'),
          },
        ],
      }
    }else if(name === 'gender'){
      return {
        name : 'gender',
        label : DictionaryService.msg("gender",locale),
        type : 'radio',
        disabled: disabled,
        value : value,
        items:[
          {label:DictionaryService.msg("male",locale),value:"0"},
          {label:DictionaryService.msg("female",locale),value:"1"},
        ],
        xs:6,
        requiredUI:false,
        start : <>
              <Grid item  xs={1}><div className="sz-icons"><div className="icon gender"></div></div></Grid>
              <Grid item  xs={4} style={{padding: "0px 8px 0px !important"}}>
              <div style={{    position: 'relative',top: -4,color:"#fff",width: 85}} className={required?"MuiTypography-body1_ rq-lable":"MuiTypography-body1_"}>
                {DictionaryService.msg("gender",locale)}
              </div>
              </Grid>
              </> ,
        end : <Grid item  xs={1}></Grid> ,
        rules: [
          {
            required: required,
            message: DictionaryService.msg("vGender",'TH'),
          },
        ],
      }
    }else if (name === 'mobileNo'){
      // console.log("mobileNo:disabled",disabled)
      return {
        name : 'mobileNo',
        label : DictionaryService.msg("mobileNo",locale),
        type : 'text',
        disabled: disabled,
        value : value,
        xs:6,
        requiredUI:required,
        end : <Grid item  xs={1}></Grid>,
        rules: [
          { required: required,
            message: DictionaryService.msg("vMobileNo",locale)
          },
          // {
          //   min:10,
          //   message: DictionaryService.msg("errMobiledMin",locale),
          // },
          {
            pattern:/[0][0-9]{9}/,
            message: DictionaryService.msg("errPhoneRegex",locale),
          },
          {
            max:10,
            message: DictionaryService.msg("errMobiledMax",locale),
          },
        ],
      }
    }else if(name === 'areaCode'){
      let countryCodes = this.state.countryCodes || []

      // {this.state.countryCodes.map(option => (
      //   <MenuItem key={option.code} value={option.dial_code}>
      //   {option.dial_code} ({option.code})
      //   </MenuItem>
      // ))}

      countryCodes = countryCodes.map((option)=>{
        return {value:option.dial_code,label:`${option.dial_code} (${option.code})`}
      })

      return {
        name : 'areaCode',
        label : DictionaryService.msg("areaCode",locale),
        type : 'seletebox',
        disabled: disabled,
        value : value,
        items:countryCodes,
        xs:4,
        requiredUI:false,
        start :<Grid item  xs={1}><div className="sz-icons" ><div className="icon mobile"></div></div></Grid>,
        rules: [
          {
            required: required,
            message: DictionaryService.msg("vOccupation",'TH'),
          },
        ],
      }
    }



    else if(name === 'recaptcha'){ //recaptcha
      return {
        name : 'recaptcha',
        value : value,
        type: 'recaptcha',
        rules: [
          {
            required: true,
            message: DictionaryService.msg("vNotRobot",'TH'),
          },
        ],
      }

    }else {
      return null
    }
  }

  handleFormChange = changedFields => {
    let { fields } = this.state;
    let data = { ...fields.data,...changedFields.data}
      this.setState({
        fields: {
          ...fields,
          ...changedFields,
          data:data
        },
      },()=>{
        // console.log(this.state.fields)
      });
  };


  render() {
    const { getFieldDecorator } = this.props.form;
    let occupation:any = this.state.occupation || []
    // let {fieldsLogin } = this.state
    let fieldsLogin = this.state.formConfig;

    return <div><div id="login-"  className="bg_register regis"  >
          {(this.state.loading || this.state.reloading  )?
          <div id="login_" >
              <div style={{ padding: "200px 0px", display: 'table', margin: '0 auto' }}>
                  <div className="loader"></div>
              </div>
              </div>
            :null}

            <div className="bt-sz-back fix" onClick={()=>{
                  this.props.history.push({ pathname:"/", search:this.props.location.search});
                }}  >
            <Translate value="back" />
            </div>


        <div id="form_login">
        {!this.state.reloading?
        <div  style={!this.state.loading ? {} : { display: 'none' }}>
        <div className="row" style={!this.state.loading ? {} : { display: 'none' }}>
          <div className="col-md-12 header-text" >
          <Translate value="editProfile" />
          </div>

        </div>
        </div>:null}
        {!this.state.reloading?<>
<div className="row regis-fm" style={!this.state.loading ? {} : { display: 'none' }}>
{/* <div className="col-md-12" style={{padding: '14px 0px'}}>
          <Grid container spacing={3} alignItems="flex-end">
          <Grid item  xs={1}>
            <div className="sz-icons"><div className="icon email"></div></div>
          </Grid>
          <Grid item  xs={10}>

          <Form.Item>
          <div className="rq-">
            <TextField disabled
            id="input-with-icon-grid"  label={DictionaryService.msg("email",this.props.locale)} style={{width:  '100%'}}
            defaultValue={""}
            value={this.state.user.email}

          />
          </div>
            </Form.Item>
          </Grid>
        </Grid>
    </div> */}
{
  fieldsLogin.length > 0?
    <ScValidator
    errorOnTouched={false}
    fields={fieldsLogin}
    values={{...this.state.fields}}
     onReady={(form)=>{
      this.formLogin = form;
    }}
    onSuccess={ async (data)=>{
      ReactGA.event({category: 'Edit Profile Page',action:""});

      this.setState({loading:true})
      let sessionId = this.props.ChilliData&& this.props.ChilliData.session ? this.props.ChilliData.session.sessionId :"";
      await this.submitUpdateProfile({isMobileLogin:this.state.user.isMobileLogin,sessionId:sessionId,...data,gender:data.gender === 0 || data.gender === 1 || data.gender === "0" || data.gender === "1"? +data.gender :null,occupation:data.occupation?+data.occupation:null})
      this.setState({loading:false})
      this.getProfile()
    }}
    onSuccess2={(data)=>{
        this.getProfile()
        this.scrollView()
    }}
    isDisabled={true}
    submitFormText={DictionaryService.msg("save",this.props.locale)}
    subButtonFormText={DictionaryService.msg("reverse",this.props.locale)}
    isSubButton={true}
    isDisabledTouched={true}
    onChange={this.handleFormChange}
    subButton={<>

    </>}
  />
  :null
}
</div>
{/*
  fieldsLogin.length > 0?
  <ScValidator
        fields={fieldsLogin} onReady={(form)=>{
          console.log(form)
          this.formLogin = form;
        }}
        onSuccess={(data)=>{
          // this.setState({
          //   username:data.username,
          //   password:data.password,
          // },()=>{
          //   this.props.OnSubmitLogin(this.state);
          // })
        }}

        onSuccess2={(data)=>{

        }}
        isDisabled={true}
        submitFormText={DictionaryService.msg("save",this.props.locale)}
        subButtonFormText={DictionaryService.msg("reverse",this.props.locale)}
        isSubButton={true}
        isDisabledTouched={true}
        subButton={<>

        </>}
      />
      :null

        */}




        {/* <Form onSubmit={this.handleSubmit} className="login-form" style={!this.state.loading ? {} : { display: 'none' }}  autoComplete="off">

        <div className="row">


         <div className="col-md-12" >


            <Grid container spacing={3} alignItems="flex-end">
            <Grid item  xs={1}>
              <div className="sz-icons"><div className="icon username"></div></div>
            </Grid>
            <Grid item  xs={5}>
            <Form.Item>
            {getFieldDecorator('firstName', {
                  initialValue:this.state.user.firstName,
                    rules: [{ required: true, message: DictionaryService.msg("vFirstName",this.props.locale) }],
                  })(
                    <div className="rq">
                      <TextField id="input-with-icon-grid"  label={DictionaryService.msg("firstName",this.props.locale)} style={{width:  '100%'}}
                      defaultValue={"-"}
                      onFocus={ this.onFocus }
                      value={this.state.user.firstName}
                      onChange={(e)=>{

                        let value = e.target.value
                        let { user } = this.state
                        user.firstName = value
                        this.setState({user:user})
                      }}
                      />
                    </div>
            ,)}</Form.Item>
            </Grid>
            <Grid item  xs={6}>
            <Form.Item>
            {getFieldDecorator('surName', {
                    initialValue:this.state.user.surName,
                      rules: [{ required: true, message: DictionaryService.msg("vLastName",this.props.locale) }],
                    })(
                      <div className="rq">
                      <TextField id="input-with-icon-grid"  label={DictionaryService.msg("lastName",this.props.locale)} style={{width:  '100%'}}
                      defaultValue={"-"}

                      onFocus={ this.onFocus }
                      value={this.state.user.surName}
                      onChange={(e)=>{

                        let value = e.target.value
                        let { user } = this.state
                        user.surName = value
                        this.setState({user:user})
                      }}
                      />
                      </div>
                      ,
                )}
              </Form.Item>
            </Grid>
          </Grid>

       </div>
       <div className="col-md-12" >


            <Grid container spacing={3} alignItems="flex-end">
            <Grid item  xs={1}>
              <div className="sz-icons"><div className="icon citizen"></div></div>
            </Grid>
            <Grid item  xs={11}>
            <Form.Item
                validateStatus={this.state.v_idCard.validateStatus}
                help={this.state.v_idCard.errorMsg || ''}
              >
              {getFieldDecorator('citizenId', {
                  initialValue:this.state.citizenId,
                    // rules: [{ required: !this.state.passportNo , message: DictionaryService.msg("vIdCard",this.props.locale) }],
                  })(
              <div className={this.state.v_passportNo.validateStatus !=="success" || !this.state.v_passportNo.validateStatus || this.state.v_idCard.value ?"rq":""}>
              <TextField id="input-with-icon-grid"
               label={DictionaryService.msg("idCard",this.props.locale)}
               style={{width:  '100%'}}
               value={this.state.citizenId}
               defaultValue={"-"}
               onFocus={ this.onFocus }
               onChange={(e)=>{
                let value = e.target.value
                this.setState({citizenId:value})
                setTimeout(() => {
                  this.handleIdCardChange(value)
                  // this.props.form.validateFields(['passportNo'], { force: true });
                }, 100);

              }}
              /></div>
               ,)}
          </Form.Item>
            </Grid>
          </Grid>

      </div>
       <div className="col-md-12" >

            <Grid container spacing={3} alignItems="flex-end">
            <Grid item  xs={1}>
              <div className="sz-icons"><div className="icon passport"></div></div>
            </Grid>

            <Grid item  xs={11}>
            <Form.Item
        validateStatus={this.state.v_passportNo.validateStatus}
        help={this.state.v_passportNo.errorMsg || ''}
      >
     {getFieldDecorator('passportNo', {
            initialValue:this.state.passportNo,
            // rules: [{ required: !this.state.citizenId , message: DictionaryService.msg("vPassport",this.props.locale) }],
          })(
            <div className={this.state.v_idCard.validateStatus !=="success" || !this.state.v_idCard.validateStatus || this.state.v_passportNo.value ?"rq":""}>
              <TextField id="input-with-icon-grid"
               label={DictionaryService.msg("passport",this.props.locale)}
               style={{width:  '100%'}}

               defaultValue={"-"}
               onFocus={ this.onFocus }
               value={this.state.passportNo}
               onChange={(e)=>{
                let value = e.target.value
                this.setState({passportNo:value})
                setTimeout(() => {
                  this.handlePassportNoChange(value)
                  // this.props.form.validateFields(['citizenId'], { force: true });
                }, 100);
              }}
              /></div>
              ,)}
              </Form.Item>
            </Grid>
          </Grid>

      </div>
      <div className="col-md-12" >

         <Grid container spacing={3} alignItems="flex-end">
         <Grid item  xs={1}>
           <div className="sz-icons"><div className="icon nationality"></div></div>
         </Grid>
         <Grid item  xs={11}>
         <Form.Item>
          {getFieldDecorator('nationality', {
             initialValue:this.state.user.nationality,
          })(
           <TextField id="input-with-icon-grid" onFocus={ this.onFocus }  label={DictionaryService.msg("nationality",this.props.locale)} style={{width:  '100%'}}
           defaultValue={"-"}
           value={this.state.user.nationality}
          onChange={(e)=>{

            let value = e.target.value
            let { user } = this.state
            user.nationality = value
            this.setState({user:user})
          }}/> ,
           )}
            </Form.Item>
         </Grid>
       </Grid>


       </div>
       <div className="col-md-12" >



          <Grid container spacing={3} alignItems="flex-end">
            <Grid item  xs={1}>
              <div className="sz-icons"><div className="icon date"></div></div>
            </Grid>
            <Grid item  xs={4} style={{padding: "0px 8px 0px !important"}}>
              <div style={{    position: 'relative',top: -4,color:"#fff",width: 85}} className="MuiTypography-body1_ rq-lable">  {DictionaryService.msg("dateOfBirth",this.props.locale)} </div>

            </Grid>
            <Grid item  xs={7}>
            <Form.Item
                validateStatus={this.state.v_date.validateStatus}
                help={this.state.v_date.errorMsg || ''}
            >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                    value={this.state.user.birthday}
                     format="dd/MM/yyyy"

                      onFocus={ this.onFocus }
                      onChange={(e)=>{
                        let {user} = this.state
                        console.log(e)
                        user.birthday = e
                        this.setState({user:user})
                        this.onFocus()
                        this.handleDateChange(e)
                      }}
                      InputProps={{}}
                      style={{width:  '100%',marginTop: 15
                    }}
                    />
            </MuiPickersUtilsProvider>

            </Form.Item>
            </Grid>
          </Grid>


      </div>

       <div className="col-md-12" >

            <Grid container spacing={3} alignItems="flex-end">
            <Grid item  xs={1}>
              <div className="sz-icons"><div className="icon gender"></div></div>
            </Grid>
            <Grid item  xs={3} style={{padding: "0px 8px 0px !important"}}>
              <div style={{    position: 'relative',top: -4,color:"#fff",width: 85}} className="MuiTypography-body1_ rq-lable">
                 {DictionaryService.msg("gender",this.props.locale)}
                 </div>
            </Grid>
            <Grid item  xs={8} >
            <Form.Item>

      {getFieldDecorator('gender', {
            initialValue:this.state.gender,
            rules: [{ required: true, message: DictionaryService.msg("vGender",this.props.locale) }],
          })(
              <div className="gender" >
            <RadioGroup
                aria-label="gender"
                name="gender"
                onFocus={ this.onFocus }
                value={this.state.gender}
                onChange={(event:any)=>{
                  this.onFocus()
                  this.setState({gender:event.target.value})
                }}

                row
              >

            <FormControlLabel value={"1"} control={<Radio
            icon={<LensUnCheckedChecked style={{fontSize: 15 }}/>} checkedIcon={<LensChecked style={{fontSize: 15 }}/>}
            style={{fontSize: 15 }}/>} label={DictionaryService.msg("male",this.props.locale)} />
            <FormControlLabel value={"0"} control={<Radio
            icon={<LensUnCheckedChecked style={{fontSize: 15 }}/>} checkedIcon={<LensChecked style={{fontSize: 15 }}/>}
            style={{fontSize: 15 }}/>} label={DictionaryService.msg("female",this.props.locale)}/>
          </RadioGroup>
          </div>
          ,
          )}
           </Form.Item>

            </Grid>
          </Grid>



       </div>
       <div className="col-md-12" >

            <Grid container spacing={3} alignItems="flex-end">
            <Grid item  xs={1}>
              <div className="sz-icons"><div className="icon occupation"></div></div>
            </Grid>
            <Grid item  xs={11}>
              <Form.Item>
                  <TextField
                  id="standard-select-currency"
                  select
                  label={DictionaryService.msg("occupation",this.props.locale)}
                  SelectProps={{

                  }}
                  onFocus={ this.onFocus }
                  value={this.state.user.occupation}
                    onChange={(e)=>{

                      let value = e.target.value
                      let { user } = this.state
                      user.occupation = value
                      this.setState({user:user})
                    }}
                  margin="normal"
                  style={{width:'100%'}}
                  >
                  {occupation.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {this.props.locale === 'EN'?<>
                      {option.occupationNameEn}
                      </>:<>
                      {option.occupationNameTh}
                      </>}
                    </MenuItem>
                  ))}
                  </TextField>
              </Form.Item>
            </Grid>
          </Grid>

       </div>

       <div className="col-md-12" >
       <div className="row" >
       <div className="col-6" >
          <Button id="updateprofile-submit" type="primary" htmlType="submit" className="login-form-button bt-singin" style={{
            width: '100%',
            background: '#f89932',
            borderColor: '#f89932',marginBottom:10,marginTop:10}}
            disabled={this.state.btDisable|| this.isDisabledBT()}>
          <Translate value="save" />
          </Button>



        </div>

        <div className="col-6" >
          <Button id="updateprofile-submit" type="primary"  className="login-form-button bt-close" style={{
            width: '100%',
            background: '#f89932',
            borderColor: '#f89932',marginBottom:10,marginTop:10}}
            disabled={this.state.btDisable}
            onClick={()=>{
              this.getProfile()
              this.scrollView()
              }}>
          <Translate value="reverse" />
          </Button>



        </div>
        </div>
        </div>
    </div>
    </Form> */}


    </>:null}
    </div>
    </div>

    </div>
  }
}

class RePasswordForm extends Component<{ updatePassword:any, buildingInfo:any,ChilliData:any,history:any,changePage:any,registerUser:any,registerAction:any,form:any,submitRegister:any,locale:any},
{  data:any
    newPassword:string,
    confirmPassword:string
    v_confirmPassword:any,
    loding:any,
    isOpen:any}> {
  constructor(props) {
    super(props);
    this.state = {
      data:false,
      newPassword:'',
      confirmPassword:'',
      v_confirmPassword:{},
      loding:false,
      isOpen:false
    }

    }


    handleSubmit = e => {
      e.preventDefault();
      this.handleConfirmPasswordChange(this.state.confirmPassword)
      this.props.form.validateFields(async (err, user) => {
        if (!err && this.state.v_confirmPassword.validateStatus==='success' ) {
            console.log(user)
            this.props.updatePassword(user)
            return false;
          }
        });
      }


      validatePassword(data) {
        if(!data && this.state.newPassword){
          return {
            validateStatus: 'error',
            errorMsg: DictionaryService.msg("vConfirmPassword",this.props.locale),
          };
        }

        // console.log(data , this.state.newPassword)
        if (data === this.state.newPassword || !this.state.newPassword) {
          return {
            validateStatus: 'success',
            errorMsg: null,
          };
        }
        console.log(DictionaryService.msg("vConfirmPasswordNotMatch",this.props.locale))
        return {
          validateStatus: 'error',
          errorMsg: DictionaryService.msg("vConfirmPasswordNotMatch",this.props.locale),
        };
      }

      handleConfirmPasswordChange = value => {
        setTimeout(() => {
          this.setState({
            v_confirmPassword: {
              ... {...this.validatePassword(value)},
              value:value
            },
          });
        }, 50);

      };


    render() {
      const { getFieldDecorator } = this.props.form;
      return <div  >
        <div id="form_login" >
        <div className="row change-password">

        <div className="col-12" >
            <Form.Item>
                <Grid container spacing={3} alignItems="flex-end">
                <Grid item  xs={1}>
                  <div className="sz-icons"><div className="icon password"></div></div>
                </Grid>
                <Grid item  xs={11}>
                {this.state.isOpen?
                  <TextField id="input-with-icon-grid"
                    label={DictionaryService.msg("changePassword",this.props.locale)}
                    style={{width:  '100%'}}
                    disabled
                  />
                  :<div>
                  <Button type="primary" className="login-form-button  bt-change-password"
                  onClick={()=>{
                    this.handleConfirmPasswordChange("")
                    this.setState({isOpen:true})
                  }}

                        style={{
                          background: '#f89932',
                          borderColor: '#f89932',
                          width: '265px'}}>

                         <Translate value="changePassword" />
                        </Button>
                  </div>}
                </Grid>
              </Grid>
            </Form.Item>
            </div>
        {this.state.isOpen?
            <Form onSubmit={this.handleSubmit} className="login-form" >



                      <div className="col-12" >
                          <Form.Item>
                            {getFieldDecorator('oldPassword', {
                                initialValue:"",
                                rules: [{ required: true, message: DictionaryService.msg("vOldPassword",this.props.locale) }],
                              })(
                                <Grid container spacing={3} alignItems="flex-end">
                                <Grid item  xs={1}>
                                  {/* <div className="sz-icons"><div className="icon password"></div></div> */}
                                </Grid>
                                <Grid item  xs={5}>
                                <div className="rq">
                                  <TextField id="input-with-icon-grid"
                                  label={DictionaryService.msg("oldPassword",this.props.locale)}
                                  style={{width:  '100%'}}
                                  type="password"
                                  /></div>
                                </Grid>
                              </Grid>
                              ,
                              )}
                            </Form.Item>
                            </div>

                         <div className="col-12">


                            <Grid container spacing={3} alignItems="flex-end">
                            <Grid item  xs={1}>
                              {/* <div className="sz-icons"><div className="icon password"></div></div> */}
                            </Grid>
                            <Grid item  xs={5}>
                            <Form.Item>
                              {getFieldDecorator('newPassword', {
                                  initialValue:"",
                                  rules: [{ required: true, message: DictionaryService.msg("vNewPassword",this.props.locale) }],
                                })(<div className="rq">
                                    <TextField id="input-with-icon-grid"
                                    type="password"
                                    label={DictionaryService.msg("newPassword",this.props.locale)}
                                    style={{width:  '100%'}}
                                    value={this.state.newPassword}
                                    onChange={(e)=>{
                                      let value = e.target.value
                                      this.setState({ newPassword:value})
                                      this.handleConfirmPasswordChange(this.state.confirmPassword)
                                    }}
                                    /> </div>,
                                    )}
                              </Form.Item>
                            </Grid>
                            <Grid item  xs={6}>
                            <Form.Item
                              validateStatus={this.state.v_confirmPassword.validateStatus}
                              help={this.state.v_confirmPassword.errorMsg || ''}
                              ><div className="rq">
                                 <TextField id="input-with-icon-grid"
                              label={DictionaryService.msg("confirmPassword",this.props.locale)}
                              style={{width:  '100%'}}
                              value={this.state.confirmPassword}
                              type="password"
                              onChange={(e)=>{
                                let value = e.target.value
                                this.setState({ confirmPassword:value})
                                this.handleConfirmPasswordChange(value)
                              }}
                              /></div>
                            </Form.Item>
                            </Grid>
                          </Grid>




                        </div>
                        <div className="offset-1 col-11 text-center row" style={{marginTop:10}}>
                        <div className="col-7">
                        <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button  bt-singin"
                        disabled={this.state.loding}
                        style={{
                          background: '#f89932',
                          borderColor: '#f89932',
                          width: '265px'}}>
                          <Translate value="savePassword" />
                        </Button>
                        </Form.Item>
                        </div>
                        <div className="col-5">
                        <Form.Item>
                        <Button type="primary" className="login-form-button  bt-close"
                        onClick={()=>{
                          this.setState({isOpen:false})
                        }}>

                        <Translate value="cancel" />
                        </Button>
                        </Form.Item>
                        </div>
                        </div>


                  </Form>:null}
        </div>
        </div>
        </div>
    }
  }



const mapStateToProps: any = (state: any) => ({
  locale: getCurrentLocale(state),
});

const mapDispatchToProps = {}
const RePassword:any = connect<any, any, {}>(mapStateToProps, mapDispatchToProps)(Form.create({})(RePasswordForm));
const EditProfilePage:any = connect<any, any, {}>(mapStateToProps, mapDispatchToProps)(Form.create({})(EditProfile));
export default EditProfilePage;
