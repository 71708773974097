import React, { Component } from 'react';
import Request  from '../../../services/request.service';
// import { Translate } from 'react-redux-i18n'
import { getCurrentLocale } from "../../../data/utils";
import { Button ,Form } from 'antd';
import { connect } from 'react-redux'
import Notification  from '../../../services/notification.service';
import i18n from "../../../i18n/index";
import { init } from '@sentry/browser';
import queryString from 'query-string';
import AppConfig  from '../../../services/app.config';
import '../../../assets/css/new-theme/index.scss';
import { setLocale } from 'react-redux-i18n';
import ReactGA from 'react-ga';
import CommonService  from '../../../services/commonService.service';
import Header from '../../../components/Themes/default/components/Header';
import DictionaryService  from '../../../i18n/DictionaryService';
declare let FB: any;
declare let chilliController: any;
declare let location:any;


type TranslateProps = {
  value: any,
  locale:any
}
type TranslateState = {}
class TranslatePage extends Component<TranslateProps, TranslateState> {
  constructor(props) {
    super(props)
  }
  render() {
    let locale = "EN";
    try{
      locale = this.props.locale;
    }catch(e){

    }
    return (<span> {DictionaryService.msg(this.props.value,locale)}</span>);
  }
}

const mapStateToProps0: any = (state: any) => ({
  locale: getCurrentLocale(state),
});

const mapDispatchToProps0 = {}
const Translate:any = connect<any, any, {}>(mapStateToProps0, mapDispatchToProps0)(Form.create({})(TranslatePage));




type MemberConfirmProps = {
  loginInfo: any,
  user: any,
  locale:any,
  location:any,
  setLocaleData:any,
  history: any,

}

type MemberConfirmState = {
  data:any,
  cData:any,
  loading:any

}

const trackPage = (page) => {
  ReactGA.set({
    page,
    // ...options
  });
  ReactGA.pageview(page);
  ReactGA.set({ contentGroup3: "SmartZone™" ,contentGroup2:"Member Confirm" });
};

class MemberConfirmPage extends Component<MemberConfirmProps, MemberConfirmState> {
  constructor(props) {
    super(props);
    this.state = {data:false,cData:false,loading:true}

    this.initApp();
  }
  async redirectChilli() {
    let params: any = queryString.parse(this.props.location.search);
    let config: any = await AppConfig.getConfig();
    console.log("this.state.cData",this.state.cData)
    let header:any = {}
    if(params.language){
      header.language = params.language
      this.props.setLocaleData(params.language)
    }
    let cData = this.state.cData;

    if(this.state.cData.clientState){
        let logoffData: any = await this.ChilliLogOff();

        Request.post("/register/stamplogchilli", {
          cmd:'logoff',
          status:logoffData?'sucess':'error',
          data:logoffData
        })

        console.log(logoffData);
        let cData: any =  await  CommonService.getChilli(config,{})
        this.setState({cData:cData});
    }

    if(cData && cData.challenge){
      console.log("cData",cData)
             Request.post("/login/genPasswordByconfirmCode", {
               email:params.email,
               confirmCode:params.key,
               challenge:this.state.cData.challenge,
               nas:cData.location?cData.location.name:'',
             },header).then((res: any) => {
               if(res.resultCode === "20000"){
                 let username = res.resultData.strUsername
                 let password = res.resultData.pappassword
                   // console.log("e1")
                   window.location.href = `http://192.168.182.1:3990/logon?username=${username}&response=${password}`
               }else{
                    // console.log("e2")
                  window.location.href = `http://${config.chilli_host}:${config.chilli_port}`;
               }
             }).catch(e => {
               // console.log(e)
                window.location.href = `http://${config.chilli_host}:${config.chilli_port}`;
             });
    }else{
      console.log("e3")
      window.location.href = `http://${config.chilli_host}:${config.chilli_port}`;
    }
  }

  componentDidMount() {
    const page = this.props.location.pathname;
    trackPage(page);
  }

  componentWillReceiveProps(nextProps) {
    const currentPage = this.props.location.pathname;
    const nextPage = nextProps.location.pathname;
    // console.log("nextPage",nextPage)
    if (currentPage !== nextPage) {
      trackPage(nextPage);
    }
  }
  async initApp(){
    let config: any = await AppConfig.getConfig();
    CommonService.getChilli(config,{}).then((data: any) => {
      this.init();
      console.log("data",data)
      this.setState({cData:data,loading:false})

      Request.post("/register/stamplogchilli", {
        cmd:'getchilli',
        status:data?'sucess':'error',
        data:data
      })
    });
  }

  async init(){

    let params: any = queryString.parse(this.props.location.search);
    // this.setState({data:{}})

    let header:any = {}
    if(params.language){
      header.language = params.language
      this.props.setLocaleData(params.language)
    }
    Request.post("/register/confirmMail", {
      email:params.email,
      confirmCode:params.key
    },header).then((res: any) => {
        if (res.resultCode && res.resultCode[0] === "2") {// === "20000"
          this.setState({data:res.errorMessage })
        }else{
          this.setState({data:res.errorMessage })
          Notification.error({
            message:"",
            description:res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
            resultCode:res.resultCode
            });
        }
        }).catch(e => {
          console.log(e);
         Notification.error({
          message:"",
          description:e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
          });

        });
  }





  async ChilliLogOff() {
    return new Promise(async resolve => {
      let config: any = await AppConfig.getConfig();
      let params: any = queryString.parse(this.props.location.search);
      let chilli_https =   true
      if (location.protocol != 'https:'){
        chilli_https = false;
      }
      if(!chilli_https){
        chilliController.host = config.chilli_host;
        chilliController.port = config.chilli_port;
      }else{
        chilliController.host = config.chilli_hosts;
      }
      chilliController.interval = 60;
      chilliController.ssl = chilli_https;
      chilliController.onError = err => {
        return resolve(false);
      };
      chilliController.onUpdate = res => {
        let data = chilliController;
        return resolve(data);
      };
      chilliController.logoff();
    });
  }

  render() {

    return (
      <div>
          <div className="app-main alphabox" id="page" >
            <Header {...this.props}
            />
            <main role="main" >
            <div className="album py-5">
            <div className="container ">
            <div className="row app-main-container">
            <div className="col-md-6 offset-md-3 main-form" >
            <div className="row">
              <div className="col-12" >
              <div id="wrapper">
              <div id="content">
                <div id="login-" className="bg_login">
                {/* <div className="bt-sz-back fix"  onClick={()=>this.redirectChilli()}  >
                  <Translate value="back" />
                  </div> */}
                  <div id="form_login">
                    <div >
                    {(this.state.loading)?
                    <div id="login_" >
                        <div style={{ paddingBottom: 50,paddingTop: 50, display: 'table', margin: '0 auto' }}>
                            <div className="loader"></div>
                        </div>
                        </div>
                      :null}

                      <div style={this.state.data ? {} : { display: 'none' }}>
                      <div className="row" style={{marginTop: 15}}>
                        <div className="col-12" >
                              <div style={{textAlign:'center'}}>


                              <h1 style={{color: "#eee", fontSize: 20 }}>
                                {
                                    this.state.data?<div>
                                      <div style={{textAlign:'center'}}>
                                        <p style={{fontSize:20}}> {this.state.data}</p>
                                      <Button onClick={()=>this.redirectChilli()} className="login-form-button bt-singin" style={{marginTop: 15,color:'#fff',width:'100%'}}>
                                             <Translate value="login"/>
                                      </Button>
                                      </div>
                                    </div>:null
                                  }
                                </h1>
                              </div>
                            </div>
                          </div>
                          </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
               </div>
            </div>






            </div>
            </div>
            </div>
            </div>
          </main>
          </div>
        </div>

    );
  }
}


const mapStateToProps: any = (state: any) => ({
  locale: getCurrentLocale(state),
});

const setLocaleCustom = (res)=>{
  try {
    let haslocalStorage = !!localStorage;
    if (haslocalStorage) {
          localStorage.setItem('locale', res);
        }
      }
  catch (e) {
  }
return setLocale(res)
}
const mapDispatchToProps = {
  setLocaleData:setLocaleCustom,
}
const MemberConfirmPage_:any = connect<any, any, {}>(mapStateToProps, mapDispatchToProps)(Form.create({})(MemberConfirmPage));

export default MemberConfirmPage_
// export default MemberConfirmPage
