import React, { Component } from 'react';
import { Button ,Form } from 'antd';
import { connect } from 'react-redux'
import { init } from '@sentry/browser';
import queryString from 'query-string';
import { getCurrentLocale } from "../data/utils";
import DictionaryService  from '../i18n/DictionaryService';
type TranslateProps = {
  value: any,
  locale:any
}
type TranslateState = {}

class TranslatePage extends Component<TranslateProps, TranslateState> {
  constructor(props) {
    super(props)
  }
  render() {
    let locale = "EN";
    try{
      locale = this.props.locale;
    }catch(e){

    }
    return (<span> {DictionaryService.msg(this.props.value,locale)}</span>);
  }
}


const mapStateToProps: any = (state: any) => ({
  locale: getCurrentLocale(state),
});

const mapDispatchToProps = {}
const TranslatePage_:any = connect<any, any, {}>(mapStateToProps, mapDispatchToProps)(Form.create({})(TranslatePage));

export default TranslatePage_
// export default TranslatePage
