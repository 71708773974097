

import React, { Component } from 'react';
import queryString from 'query-string';
import i18n from "../../../i18n/index";
import { Button  ,Form ,Typography } from 'antd';
import Notification  from '../../../services/notification.service';
import { Translate } from 'react-redux-i18n'
// import { Modal } from 'antd';
import ReactGA from 'react-ga';
import { connect } from 'react-redux'
import { getCurrentLocale  ,redirectChilli } from "../../../data/utils";
import DictionaryService  from '../../../i18n/DictionaryService';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Request  from '../../../services/request.service';
import _ from 'lodash';
import Lens from '@material-ui/icons/Lens';
import { withStyles } from '@material-ui/core/styles';
import AppConfig  from '../../../services/app.config';
import IconButton from '@material-ui/core/IconButton';
import PriorityHigh from '@material-ui/icons/ErrorOutline';
import Tooltip from "./components/Tooltip"

const { Title } = Typography;
declare let chilliController: any;

const LensChecked = withStyles({
  root: {
    color: 'rgb(248, 153, 50)',
  }
})(Lens);
const LensUnCheckedChecked = withStyles({
  root: {
    color: '#fff',
  }
})(Lens);


class RePasswordForm extends Component<{ socialTypeList:any,location:any, user:any,buildingInfo:any,ChilliData:any,history:any,changePage:any,registerUser:any,registerAction:any,form:any,submitRegister:any,locale:any},
{   data:any
    oldPassword:string,
    newPassword:string,
    confirmPassword:string
    v_oldPassword:any,
    v_newPassword:any,
    v_confirmPassword:any,
    loding:any,
    isOpen:any,

    loading:any}> {
    constructor(props) {
      super(props);
      this.state = {
        data:false,
        oldPassword:"",
        newPassword:'',
        confirmPassword:'',
        v_confirmPassword:{},
        v_oldPassword:{},
        v_newPassword:{},
        loding:false,
        isOpen:true,
        loading:false
      }
    }

    async redirectChilli() {
      // let data = await this.ChilliLogOff();
      try{
              // localStorage.removeItem('user-e-register');
                this.setUsernameCls()
      }catch(e){

      }

      let config: any = await AppConfig.getConfig();
      let params: any = queryString.parse(this.props.location.search);

      redirectChilli(config,params,'/logoff')

    }

    updatePassword(item){

      ReactGA.event({category: 'Change Password',action:""});

      let sessionId = this.props.ChilliData&& this.props.ChilliData.session ? this.props.ChilliData.session.sessionId :"";
      this.setState({loading:true})
      return new Promise(async resolve => {
        Request.post("/register/changePassword", {email:this.props.user.userName,sessionId:sessionId,...item,password:item.newPassword}, {
          "x-access-id": this.props.buildingInfo.data.accessToken
          }).then(async (res: any) => {
            this.setState({loading:false})
            if ( res && res.resultCode && res.resultCode[0] === "2" ){
              this.scrollView()
            let msg = res.errorMessage || "Success"
              Notification.success({
                  message:"",
                  description:msg,
                });
              // this.redirectChilli();
              this.setState({
                data:false,
                oldPassword:"",
                newPassword:'',
                confirmPassword:'',
                v_confirmPassword:{},
                v_oldPassword:{},
                v_newPassword:{},
                loding:false,
                isOpen:true,
                loading:false
              })
              // try {
                // localStorage.removeItem('user-e-register')
              // }catch (e) {}
              this.setUsernameCls()
          }else{
            let msg = res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")

            Notification.error({
              message:"",
              description:msg,
              resultCode:res.resultCode
              });
          }
            resolve(res)
          }).catch(e => {
            console.log(e);
            let msg = e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:"")

            Notification.error({
                message:"",
                description:msg
              });
              this.setState({loading:false})
            return resolve(false);
          });

        })
    }

    setUsername(item){
      console.log("item>>>",item);
      Request.post("/login/rememberLogin", item, {
        "x-access-id":  this.props.buildingInfo.data.accessToken
      }).then((res: any) => {
        console.log("setUserrememberLogin",res);
      })
    }

    setUsernameCls(){

      this.setUsername({
         "mac": this.props.buildingInfo.data.mac,
         "username": '',
         "password": '',
         "type": 1,
         "remember":false
      })
    }


    handleSubmit = e => {
      e.preventDefault();
      this.setState({loading:true})
      this.handlePasswordAllChange();
      this.props.form.validateFields(async (err, user) => {
        this.setState({loading:false})
        if (!err && this.state.v_confirmPassword.validateStatus==='success' &&  this.state.v_newPassword.validateStatus==='success' &&  this.state.v_oldPassword.validateStatus==='success'  ) {
            console.log(user)
            let { oldPassword ,newPassword , confirmPassword } = this.state;
            this.updatePassword({ oldPassword:oldPassword ,newPassword:newPassword , confirmPassword:confirmPassword,...user})
            return false;
          }
        });
      }


      validateSamePassword(value1,value2) {
        // if(!data && this.state.newPassword){
        //   return {
        //     validateStatus: 'error',
        //     errorMsg: DictionaryService.msg("vConfirmPassword",this.props.locale),
        //   };
        // }

        if(value1 === value2){
          return {
            validateStatus: 'error',
            errorMsg:  DictionaryService.msg("errOldNewPasswordSame",this.props.locale),
          }
        }

          return {
            validateStatus: 'success',
            errorMsg: null,
          };

      }

      validateNotMatchPassword(value1,value2) {
      if (value1 === value2) {
            return {
              validateStatus: 'success',
              errorMsg: null,
            };
          }
          console.log(DictionaryService.msg("vConfirmPasswordNotMatch",this.props.locale))
          return {
            validateStatus: 'error',
            errorMsg: DictionaryService.msg("vConfirmPasswordNotMatch",this.props.locale),
          };
        }


      validatePasswordRegex(data) { //[$&+,:;=?@#|'<>.^*()%!-]
      let regex = /^[a-zA-Z0-9!@#$&()\-.+,/*_:<=>?@\[\]^`{}|~]*$/

       if(data.length < 4 || data.length > 16 ){
        return {
          validateStatus: 'error',
          errorMsg:  DictionaryService.msg("errOldNewPasswordMin",this.props.locale),
        }
      }else if(!regex.test(data)){
        return {
          validateStatus: 'error',
          errorMsg:  DictionaryService.msg("errOldNewPasswordRegex",this.props.locale),
        }
      }

      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }



    validatePassword(value){
      if(!value || value.length <= 0 ){
        return {
          validateStatus: 'error',
          errorMsg:  DictionaryService.msg("vPassword",this.props.locale),
        }
      }
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }

    validateConfirmPassword(value){
      if(!value || value.length <= 0 ){
        return {
          validateStatus: 'error',
          errorMsg:  DictionaryService.msg("vConfirmPassword",this.props.locale),
        }
      }
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }

    validateOldPassword(value){
      if(!value || value.length <= 0 ){
        return {
          validateStatus: 'error',
          errorMsg:  DictionaryService.msg("vOldPassword",this.props.locale),
        }
      }
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }

    validateNewPassword(value){
      if(!value || value.length <= 0 ){
        return {
          validateStatus: 'error',
          errorMsg:  DictionaryService.msg("vNewPassword",this.props.locale),
        }
      }
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }

    handlePasswordAllChange = () => {
      console.log("handlePasswordAllChange")
        setTimeout(() => {
          let { oldPassword ,newPassword , confirmPassword } = this.state;
          console.log(oldPassword ,newPassword , confirmPassword )

          let v_oldPassword:any = {}
          let v_newPassword:any = {}
          let v_confirmPassword:any = {}

              v_oldPassword = {
                ... {...this.validateOldPassword(oldPassword)},
                value:oldPassword
              }

            v_newPassword = {
              ... {...this.validateNewPassword(newPassword)},
              value:oldPassword
            }

            v_confirmPassword = {
              ... {...this.validateConfirmPassword(confirmPassword)},
              value:confirmPassword
            }

          if(newPassword && confirmPassword){
            let NotMatchPassword = this.validateNotMatchPassword(newPassword , confirmPassword);

              v_newPassword = {
                ... {...NotMatchPassword},
                value:newPassword
              }
              v_confirmPassword = {
                ... {...NotMatchPassword},
                value:confirmPassword
              }
              // v_oldPassword = {
              //   ...  {...this.validateOldPassword(oldPassword)},
              //   value:oldPassword
              // }
            if(NotMatchPassword.validateStatus === 'success'){
              let NotMatchPassword2 = this.validateSamePassword(newPassword , oldPassword);

                v_newPassword = {
                  ... {...NotMatchPassword2},
                  value:newPassword
                }

                v_confirmPassword = {
                  ... {...NotMatchPassword2},
                  value:confirmPassword
                }
                v_oldPassword = {
                  ... {...NotMatchPassword2},
                  value:oldPassword
                }

              if(NotMatchPassword2.validateStatus === 'success'){
                  v_oldPassword = {
                    ...  {...this.validateOldPassword(oldPassword)},
                    value:oldPassword
                  }

              }
            }
          }

          this.setState({
            v_newPassword:v_newPassword,
            v_confirmPassword:v_confirmPassword,
            v_oldPassword:v_oldPassword,
          });

        }, 0);
    }


      handleConfirmPasswordChange = (password) => {
        setTimeout(() => {
            let temp = this.validatePasswordRegex(password)
            this.setState({
              v_confirmPassword: {...
                {...temp},
                value:password
              },
            });
            if(temp.validateStatus === 'error'){
              return;
            }
            this.handlePasswordAllChange();
        }, 25);
      };


      handleOldPasswordChange  = ( password ) => {
        // let pass = true;
        setTimeout(() => {

            let temp = this.validatePasswordRegex(password)
            this.setState({
              v_oldPassword: {...
                {...temp},
                value:password
              },
            });
            if(temp.validateStatus === 'error'){
              return;
            }
            this.handlePasswordAllChange();
        }, 25);
      };


      handleNewPasswordChange  = ( password ) => {
        // let pass = true;
        setTimeout(() => {

            let temp = this.validatePasswordRegex(password)
            this.setState({
              v_newPassword: {...
                {...temp},
                value:password
              },
            });
            if(temp.validateStatus === 'error'){
              return;
            }
            this.handlePasswordAllChange();

        }, 25);
      };

      isDisabledBT(){
        let errs = this.props.form.getFieldsError();
        let isErr = false;
        for (const i in errs) {
          if(errs[i]){
            isErr = true
          }
        }
        return !( !isErr && (this.state.v_confirmPassword.validateStatus==='success' &&  this.state.v_newPassword.validateStatus==='success' &&  this.state.v_oldPassword.validateStatus==='success'))
      }

      scrollView(){
      let view:any = document.getElementById('main-view')
      if(view){
        const yCoordinate = view.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -85;

        try {
          window.scrollTo({
              top: yCoordinate + yOffset,
              behavior: 'smooth'
          });
        } catch (error) {
          console.log(error);
        }
      }
    }


    render() {
      const { getFieldDecorator } = this.props.form;
      return <div><div id="login-"  className="bg_register regis"  >
      {(this.state.loading )?
      <div id="login_" >
          <div style={{ padding: "90px 0px", display: 'table', margin: '0 auto' }}>
              <div className="loader"></div>
          </div>
          </div>
        :null}

        <div className="bt-sz-back fix" onClick={()=>{
              this.props.history.push({ pathname:"/", search:this.props.location.search});
            }}  >
        <Translate value="back" />
        </div>


      <div  >
        <div id="form_login" >
        <div className="row change-password" style={!this.state.loading ? {} : { display: 'none' }}>
        <div className="col-md-12 header-text" >
          <Translate value="changePassword" />
        </div>

        {this.state.isOpen?
            <Form onSubmit={this.handleSubmit} className="login-form" >



                      <div className="col-12" >
                          <Form.Item
                            validateStatus={this.state.v_oldPassword.validateStatus}
                            help={this.state.v_oldPassword.errorMsg || ''}
                            >
                                <Grid container spacing={3} alignItems="flex-end">
                                <Grid item  xs={6}>
                                <div className="rq">
                                  <TextField id="input-with-icon-grid"
                                  label={DictionaryService.msg("oldPassword",this.props.locale)}
                                  style={{width:  '100%'}}
                                  type="password"
                                  inputProps={{ maxLength: 16 }}
                                  onChange={(e)=>{
                                    let value = e.target.value
                                    this.setState({ oldPassword:value})
                                    this.handleOldPasswordChange(value)
                                  }}
                                  value={this.state.oldPassword}
                                  /></div>
                                </Grid>
                                <Grid item  xs={5}></Grid>
                                <Grid item  xs={1}>
                                  <Tooltip text={()=> {
                                    return <>
                                    <h5 style={{marginBottom: 0}}> <Translate value="passwordInfo1" /> </h5>
                                      <Translate value="passwordInfo2" /> <br/>
                                      <Translate value="passwordInfo3" /> <br/>
                                      <Translate value="passwordInfo4" /> <br/>
                                      <Translate value="passwordInfo5" />
                                    </>
                                  }} style={{margin: -15}}>
                                  <IconButton aria-label="delete"  size="small">
                                        <PriorityHigh style={{fontSize: 15,color:'#fff' }}/>
                                  </IconButton>
                                  </Tooltip>
                                  </Grid>
                              </Grid>
                            </Form.Item>
                            </div>

                         <div className="col-12">
                            <Grid container spacing={3} alignItems="flex-end">
                            <Grid item  xs={6}>
                            <Form.Item
                              validateStatus={this.state.v_newPassword.validateStatus}
                              help={this.state.v_newPassword.errorMsg || ''}
                            >
                              {/* {getFieldDecorator('newPassword', {
                                  initialValue:"",
                                  rules: [{ required: true, message: DictionaryService.msg("vNewPassword",this.props.locale) }],
                                })( */}
                                <div className="rq">
                                    <TextField id="input-with-icon-grid"
                                    type="password"
                                    label={DictionaryService.msg("newPassword",this.props.locale)}
                                    style={{width:  '100%'}}
                                    inputProps={{ maxLength: 16 }}
                                    value={this.state.newPassword}
                                    onChange={(e)=>{
                                      let value = e.target.value
                                      this.setState({ newPassword:value})
                                      this.handleNewPasswordChange(value)
                                    }}
                                    /> </div>

                              </Form.Item>
                            </Grid>
                            <Grid item  xs={6}>
                            <Form.Item
                              validateStatus={this.state.v_confirmPassword.validateStatus}
                              help={this.state.v_confirmPassword.errorMsg || ''}
                              ><div className="rq">
                                 <TextField id="input-with-icon-grid"
                              label={DictionaryService.msg("confirmPassword",this.props.locale)}
                              style={{width:  '100%'}}
                              value={this.state.confirmPassword}
                              type="password"
                              inputProps={{ maxLength: 16 }}
                              onChange={(e)=>{
                                let value = e.target.value
                                this.setState({ confirmPassword:value})
                                this.handleConfirmPasswordChange(value)
                              }}
                              /></div>
                            </Form.Item>
                            </Grid>
                          </Grid>
                        </div>
                              {
                                this.props.socialTypeList.indexOf(1) > -1 && this.props.socialTypeList.length > 1?
                                <div className="col-12" style={{marginTop:10}}>
                                  <div className="_alert _alert-warning" role="alert" style={{width:'100%'}}>
                                      <div dangerouslySetInnerHTML={{__html:DictionaryService.msg("warningChagepassword",this.props.locale)}}></div>
                                      {/* <Translate value="warningChagepassword" /> */}

                                    </div>
                                </div>
                                 :null
                              }


                        <div className="col-12" style={{marginTop:10}}>
                        <div className="col-12">
                        {/* <Form.Item> */}
                        <Button type="primary" htmlType="submit" className="login-form-button  bt-singin"
                        disabled={this.state.loding || this.isDisabledBT()}
                        style={{
                          background: '#f89932',
                          borderColor: '#f89932',
                          width: '265px'}}>
                          <Translate value="savePassword" />
                        </Button>
                        {/* </Form.Item> */}
                        </div>
                        </div>


                  </Form>:null}
        </div>
        </div>
        </div>

        </div>
        </div>
    }
  }



const mapStateToProps: any = (state: any) => ({
  locale: getCurrentLocale(state),
});

const mapDispatchToProps = {}
const ChangePasswordPage:any = connect<any, any, {}>(mapStateToProps, mapDispatchToProps)(Form.create({})(RePasswordForm));
export default ChangePasswordPage;
