import { ActionsUnion } from '../types'
import { createAction } from '../utils'
import { configModel } from "../actionTypes";
export const actions = {
         setConfig: (config) => {
            return createAction(configModel.SET_CONFIG, config);
         }
       };

export type Actions = ActionsUnion<typeof actions>
