import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n'
import ContactUs from './components/ContactUs';
import Header from './components/Header';
import BuildingInfo from './components/BuildingInfo';
import Ads from './components/Ads';
import AdCustom from './components/AdCustom';

import AdsReviveBannerId from './components/AdsReviveBannerId';
import {Helmet} from "react-helmet";
import AD from './components/Ad';
import { Scrollbars } from 'react-custom-scrollbars';
import '../../../assets/css/new-theme/index.scss';
import '../../../assets/css/new-theme/bootstrap/bootstrap.scss';
import CloseIcon from '@material-ui/icons/Close';

import '../../../assets/css/theme-red.scss';
import '../../../assets/css/theme-darkmode.scss';
import '../../../assets/css/theme-brown.scss';
import '../../../assets/css/theme-pink.scss';
import '../../../assets/css/theme-green.scss';
import AdsMask from './AdsMask';
// this.state.counterAds
type ThemeProps = {
  specialTheme:any,
  customThemeJson:any,
  img4:any,
  setLocaleData:any,
  buildingInfo:any,
  locale:any,
  history:any,
  location:any,
  buildingInfoLoading:any
  user:any,
  setCountTime:any,
  updateDetachAds:any,
  socialTypeList:any,
  socialAuth:any,
  reloadAuth:any,
  newBannerId:any
  setPopupAds:any,
  popupAds:any,
  counterAds:any,
  setCounterAds:any
}

type ThemeState = {
  isOpen:boolean,
  isFixed:boolean,
  first:boolean,

}

class DefaultTheme extends Component<ThemeProps, ThemeState> {
  grid;
  constructor(props) {
     super(props);
     this.state = {
       isOpen:false,
       isFixed:false,
       first:true
     };
     this.openMenu = this.openMenu.bind(this)
     this.handleScroll = this.handleScroll.bind(this)
  }
  openMenu(){
    console.log(this.state.isOpen)
    this.setState({isOpen:!this.state.isOpen})
  }


  handleScroll() {
    var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        x = w.innerWidth || e.clientWidth || g.clientWidth,
        y = w.innerHeight|| e.clientHeight|| g.clientHeight;
    let isFixed = false;
    if(x < 768 ){
      isFixed = true;
    }
      this.setState({isFixed:isFixed });
  }

componentDidMount() {
  const el:any = document.getElementById("app-main");
  if(el){
    window.addEventListener("resize", this.handleScroll, true);
    this.handleScroll()
  }

}

componentWillReceiveProps(nextProps){
    // console.log("page",this.props.location , nextProps.location)
    if (this.props.location !== nextProps.location) {
      // window.scrollTo(0, 0)
      let view:any = document.getElementById('main-view')
      // console.log(view) // && !this.state.first
      if(view && !this.state.first || view && nextProps.user){
        // view.scrollIntoView({block: 'start', behavior: 'smooth'});
        // window.scrollBy(0, -10);
        const yCoordinate = view.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -85;
        try {
          window.scrollTo({
              top: yCoordinate + yOffset,
              behavior: 'smooth'
          });
        } catch (error) {
          console.log(error);
        }


      }
      this.setState({first:false})

    }
  }

  render() {
    let props = this.props;
        // try{
    let banners = props.buildingInfo && props.buildingInfo.data ? props.buildingInfo.data.banners : []
    let newBannerId = props.newBannerId;
    let reviveBannerId_ = props.buildingInfo && props.buildingInfo.data && props.buildingInfo.data.reviveBannerId? props.buildingInfo.data.reviveBannerId:"";
    let reviveBannerId = reviveBannerId_.split(",") || [];
    // console.log("reviveBannerId",reviveBannerId)
    let buildingInfo = props.buildingInfo && props.buildingInfo.data ? props.buildingInfo.data :{};
    let themeCode = buildingInfo.themeCode?buildingInfo.themeCode:''
    let adsPopupUrl = buildingInfo.adsPopupUrl?buildingInfo.adsPopupUrl:''
    // console.log("adsPopupUrl",adsPopupUrl);
    // }catch(e){
    //
    // }
    console.log("customThemeJson",props.customThemeJson);

    let hide_web =  props.customThemeJson && props.customThemeJson.hide1 ? props.customThemeJson.hide1 : []
    let hide_mobile =  props.customThemeJson && props.customThemeJson.hide2 ? props.customThemeJson.hide2 : []

    return (
      <>
          {
            buildingInfo.loginAlphaboxFlag?<>
            <Helmet>
            <title>Alpha Box</title>
            <link rel="shortcut icon"  href={require('../../../assets/favicon2.ico')}  />
            </Helmet>

              </>:<>
              <Helmet>
              <title>SmartZone™</title>
              <link rel="shortcut icon"  href={require('../../../assets/favicon.ico')}  />
              </Helmet>
            </>
          }
      <div className={"change-theme-preview app-main "+( buildingInfo.loginAlphaboxFlag?'alphabox ':' ' )+(themeCode)} id="app-main">
          {
            !this.props.popupAds?
            <AdsMask
                img4={this.props.img4}
                buildingInfo={buildingInfo}
                newBannerId={newBannerId}
                reviveBannerId={reviveBannerId}
                reloadAuth={this.props.reloadAuth}
                socialAuth={this.props.socialAuth}
                setPopupAds={this.props.setPopupAds}
                setCounterAds={this.props.setCounterAds}
                counterAds={this.props.counterAds}
                />
              :null
          }





        <Header setLocaleData={props.setLocaleData} openMenu={this.openMenu} locale={props.locale} history={props.history} location={props.location} user={props.user} socialTypeList={props.socialTypeList}
        buildingInfo={buildingInfo}/>

      <main role="main">
        {/* <Scrollbars style={{height: "calc(100vh - 175px)", width: '100%', marginBottom: 20}}> */}
          <div className="album py-4">
            <div className="container_ app-main-container ">
            <div  className={this.state.isFixed?"stack-grid-mobile":'row stack-grid'}>
                {
                  this.props.specialTheme === 1?
                  <>

                  </>:
                  <>
                  <div key="key1" className={"box-key col-md-6 list "+ (hide_web.indexOf(1) > -1 && hide_web.indexOf(2) > -1 && hide_web.indexOf(3) > -1 ?'web-hide ':' ')}>
                  <div className="">
                  <div className={"row card-view-row "+ (hide_web.indexOf(1) > -1?'web-hide ':' ') + (hide_mobile.indexOf(1) > -1 ?'mobile-hide ':' ')}>
                      <div className="col-12 card-view auto-center"  style={{minHeight: 155}}>
                        <div className="auto-center card mb-6 box-shadow" style={{maxWidth: 400}} >
                          <BuildingInfo
                              data={props.buildingInfo}

                              locale={props.locale}
                              buildingInfoLoading={props.buildingInfoLoading}
                            />
                        </div>
                        </div>
                  </div>


                    <div className={"row card-view-row "+ (hide_web.indexOf(2) > -1?'web-hide ':' ') + (hide_mobile.indexOf(2) > -1?'mobile-hide ':' ')}>
                      <div className="col-12 card-view auto-center" >
                          <div
                          className={buildingInfo.enableAdvertisement === 1 || (newBannerId.length > 0 && newBannerId[0] !== "0" && newBannerId[0] !== "") || (reviveBannerId.length > 0 && reviveBannerId[0] !== "0" && reviveBannerId[0] !== "") ?"card mb-6 box-shadow banner1":" mb-6 box-shadow"} style={{minHeight: 160}}>
                          <Ads setCountTime={props.setCountTime} updateDetachAds={props.updateDetachAds} user={props.user} newBannerId={newBannerId}
                          reviveBannerId={reviveBannerId}
                          buildingInfo={buildingInfo}
                          loginAlphaboxFlag={buildingInfo.loginAlphaboxFlag}/>

                          </div>
                      </div>
                    </div>


                    <div key="key3" className={"row card-view-row show-md "+ (hide_web.indexOf(3) > -1?'web-hide ':' ') + (hide_mobile.indexOf(3) > -1?'mobile-hide ':' ')}  >
                      <div className="col-12-">
                        <div className="" >
                          <div className="col-12 card-view auto-center " style={{padding: 0,paddingTop:10 }}>
                              <div className={buildingInfo.enableAdvertisement === 1 || (newBannerId.length > 2 && newBannerId[2] !== "0" && newBannerId[2] !== "") ||
                                            (reviveBannerId.length > 2 && reviveBannerId[2] !== "0"  && reviveBannerId[2] !== "") ?"banner card mb-6 box-shadow ":"mb-6 box-shadow "}>

                                <AdsBanner newBannerId={newBannerId} keyData={"1"}
                                loginAlphaboxFlag={buildingInfo.loginAlphaboxFlag} buildingInfo={buildingInfo}
                                reviveBannerId={reviveBannerId}/>

                              </div>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </>
                }


                  <div className="box-key list col-md-6 "  id="main-view" style={{ marginLeft: 'auto' ,marginRight: 'auto'}}>
                    <div className="col-12" style={{paddingTop: 0,padding: 0}}>
                      <div className="row card-view-row" >
                        <div className="col-12 card-view auto-center">
                          <div className="card- mb-6 box-shadow ">
                          {props.children}
                            </div>
                        </div>
                        </div>
                      </div>
                    </div>

                    {
                      this.props.specialTheme === 1?
                      <>

                      </>:
                      <>
                      <div className={"box-key hide-md list" + (hide_web.indexOf(3) > -1?'web-hide ':' ') + (hide_mobile.indexOf(3) > -1?'mobile-hide ':' ')} >
                          <div className="col-12" style={{paddingTop: 0,padding: 0}}>
                            <div className="row card-view-row" >
                              <div className="col-12 card-view  auto-center">
                              <div className={buildingInfo.enableAdvertisement === 1 || (newBannerId.length > 2 && newBannerId[2] !== "0" && newBannerId[2] !== "") || (reviveBannerId.length > 2 && reviveBannerId[2] !== "0" && reviveBannerId[2] !== "") ?"banner card mb-6 box-shadow ":"mb-6 box-shadow "}>

                                    <AdsBanner newBannerId={newBannerId} keyData={"2"}
                                    buildingInfo={buildingInfo}
                                    loginAlphaboxFlag={buildingInfo.loginAlphaboxFlag}
                                    reviveBannerId={reviveBannerId}/>


                                  </div>
                              </div>
                              </div>
                            </div>
                          </div>
                    </>
                  }

              </div>





            </div>
          </div>
        {/* </Scrollbars> */}
      </main>
        <ContactUs buildingInfo={this.props.buildingInfo}
        />
      </div>
      </>
    );
  }
}






class AdsBanner extends React.Component<{reviveBannerId:any,loginAlphaboxFlag:any,newBannerId:any,keyData:any,buildingInfo:any},{}>{
  constructor(props) {
    super(props);
    this.state = {
    };
 }
  render() {


    let newBannerIdArr:any = []
    let mode:any = null;
    if(this.props.newBannerId.length > 2  && this.props.newBannerId[2] !== '0'){
      newBannerIdArr = [{id:this.props.newBannerId[2]}]
    }

    if(newBannerIdArr.length === 0 && this.props.reviveBannerId.length > 2 && this.props.reviveBannerId[2] !== '0'){
      mode = 1;
      newBannerIdArr.push({id:this.props.reviveBannerId[2]})
    }

    // console.log("yyyy",this.props.buildingInfo,this.props.buildingInfo.enableAdvertisement === 1)
    return <div>
    {
      this.props.buildingInfo.enableAdvertisement === 1 ?<>
          <AdCustom advertisement={this.props.buildingInfo.advertisement} type={'y'} buildingInfo={this.props.buildingInfo}/>
        </>:
    <>
      {
        newBannerIdArr.length > 0?
        <div className="ads-AdsBanner">
          {
              newBannerIdArr.map((r,index)=>{
                return <AD id={r.id} keyData={this.props.keyData} updateDetachAds={()=>{
                }} setCountTime={()=>{}} type="" mode={mode}/>
            })
          }
        </div>
        :<>
        {
          this.props.loginAlphaboxFlag?
          <img src={require('../../../assets/images/alpha-box/banner_image.png')}  style={{width:"100%"}}/>
          :<img src={require('../../../assets/images/banner_image.png')}  style={{width:"100%"}}/>
        }
        </>
      }
      </>
    }
    </div>
  }
}


export default DefaultTheme
