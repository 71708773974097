import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n'
import i18n from "../../../i18n/index";
import { getCurrentOtpData } from "../../../data/utils";
import Request  from '../../../services/request.service';
import DictionaryService  from '../../../i18n/DictionaryService';
import { connect } from 'react-redux'
import Notification  from '../../../services/notification.service';
import queryString from 'query-string';
import AES from "crypto-js/aes";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PriorityHigh from '@material-ui/icons/ErrorOutline';
import Tooltip from "./components/Tooltip"
import moment from 'moment'
// import Input from '@material-ui/core/Input';
// import FormControl from '@material-ui/core/FormControl';
import { Form, Input, Button, Checkbox } from 'antd';
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

// function TextMaskCustom(props) {
//   const { inputRef, ...other } = props;

//   return (
//     <MaskedInput
//       {...other}
//       ref={ref => {
//         inputRef(ref ? ref.inputElement : null);
//       }}
//       mask={[ ' [',/[0-9]/, '] [', /[0-9]/, '] [', /[0-9]/, '] [', /[0-9]/, '] [', /[0-9]/, '] [', /[0-9]/,']']}
//       placeholderChar={'\u2000'}
//       showMask
//     />
//   );
// }

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => {
    if(field === 'data'){
      let res = false;
      for (const i in fieldsError[field]) {
        if(fieldsError[field][i]){
          res = true;
          break;
        }
      }
      return res
    }
    return fieldsError[field]
  });
}

class ConfirmOtpPage extends Component<{
  postLoginERegister:any,updatePageError:any,notificationError:any,locale:any,strUsername:any,buildingInfo:any,user:any,pageError:any,history:any,location:any,otpData:any,form:any
  }, {
  isNewpass:any,cmd:any,email:any,loading:any,text:any,dataInit:any,  data:any,
  newPassword:string,
  confirmPassword:string
  v_confirmPassword:any,
  v_newPassword:any,
  loding:any,
  timeOutMinsTime:any,
  resendOTPTime:any
}> {
  constructor(props) {
    super(props);

    // let params: any = queryString.parse(props.location.search);
    // console.log("otpData",props.otpData,params.emailotp)
    // {
    //   refCode:"1234"
    // }
    this.state = {
      data:false,
      dataInit:false,
      text:false,
      loading:false,
      email:props.otpData.item?props.otpData.item.email:'',
      cmd:props.otpData.item && props.otpData.item.forgotFlag?props.otpData.item.forgotFlag:0,
      isNewpass:false,
      newPassword:'',
      confirmPassword:'',
      v_confirmPassword:{},
      v_newPassword:{},
      loding:false,
      timeOutMinsTime:"",
      resendOTPTime:moment()
    }

    // console.log("this.state ",this.state,props,props.otpData ,props.otpData.item,props.otpData.item && this.state.email)

    if(this.state.email){
        this.init()
    }else{
        this.backHome()
    }

    // setTimeout(()=>{
    //   this.setState({dataInit: {
    //     refCode:"1234"
    //   },
    //     timeOutMinsTime: moment().add(5, 'minutes').format('HH:mm:ss'),
    //     resendOTPTime: moment().add(1, 'minutes')//.format('HH:mm:ss'),
    //    });
    // },50)


    this.confirmOTP = this.confirmOTP.bind(this);
  }


  backHome(){
    this.props.history.push({ pathname:"/", search:this.props.location.search});
  }

  resendOTP( first = false){
    if(!moment().isAfter(this.state.resendOTPTime)){
      let errmsg = "You can click new OTP again at "
      if(this.props.locale === 'TH'){
        errmsg = "ท่านสามารถกด รับรหัส OTP ใหม่ได้ในเวลา "
      }

      Notification.error({
        message:"",
        description: errmsg + (this.state.resendOTPTime?this.state.resendOTPTime.format('HH:mm:ss'):null),
        });
      return ;
    }
    this.setState({loading:true})
    Request.post("/register/resendOTP", {
      userName:this.state.email,
      cmd:this.state.cmd
    },{
      "x-access-id": this.props.buildingInfo.data.accessToken
    }).then((res: any) => {
        this.setState({loading:false})
        if (res.resultCode && res.resultCode[0] === "2") {

          this.setState({dataInit:res.resultData,
            timeOutMinsTime: moment().add(res.resultData.timeOutMins, 'minutes').format('HH:mm:ss'),
            resendOTPTime: moment().add(res.resultData.waitingMinute, 'minutes')//.format('HH:mm:ss'),
           },()=>{
            console.log(" this.props.form", this.props.form)
            this.props.form.setFieldsValue({
              code:"",
            });

           })
        }else{
          if(first){
            if(res.resultCode){
              this.setState({text:"["+res.resultCode+"] "+res.errorMessage })
            }else{
              this.setState({text:"[C50000] "+i18n[this.props.locale].errOther })
            }
          } else{
            this.setState({text:""})
          }
          Notification.error({
            message:"",
            description:res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
            resultCode:res.resultCode
            });
        }
        }).catch(e => {
          console.log(e);
         Notification.error({
          message:"",
          description:e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
          });
          this.setState({loading:false})
        });

  }


  confirmOTP(e){
    e.preventDefault();

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("confirmOTP")
        this.setState({loading:true})
        Request.post("/register/confirmOTP", {
          userName:this.state.email,
          codeOTP:values.code,
          cmd: this.state.cmd,
          transactionID:this.state.dataInit.transactionID
        },{
          "x-access-id": this.props.buildingInfo.data.accessToken
        })
          .then((res: any) => {
            this.setState({loading:false})
            if (res.resultCode && res.resultCode[0] === "2") {
              if(this.state.cmd === 0){
                this.ERegister(this.state.dataInit.item)
              }else{
                this.setState({isNewpass:true})
              }
            }else{
              // if(res.resultCode){
              //   this.setState({text:"["+res.resultCode+"] "+res.errorMessage })
              // }else{
              //   this.setState({text:"[C50000] "+i18n[this.props.locale].errOther })
              // }
              this.setState({text:""})
              Notification.error({
                message:"",
                description:res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
                resultCode:res.resultCode
                });
            }


            }).catch(e => {
              console.log(e);
             Notification.error({
              message:"",
              description:e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
              });
              this.setState({loading:false})
            });
      }
    });


  }

  init(){
    console.log("init:1",this.props.otpData , this.props.otpData.refCode)
    if(this.props.otpData && this.props.otpData.refCode){
      setTimeout(() => {
        console.log("init:3",this.props.otpData)
        this.setState({
          dataInit:this.props.otpData,
          timeOutMinsTime: moment().add(this.props.otpData.timeOutMins, 'minutes').format('HH:mm:ss'),
          // resendOTPTime: moment().add(this.props.otpData.waitingMinute, 'minutes').format('HH:mm:ss'),
        })
      }, 50);

    }else{
      this.resendOTP(true)
    }

  }


  async ERegister(item){
    this.setState({loading:true})
    let data = await this.props.postLoginERegister({...item,userName:item.email,username:item.email,socialId:1})
    this.setState({loading:false})
    if (data.resultCode && data.resultCode[0] === "2") {// === "20000"

      }else{
        Notification.error({
          message:"",
          description:data.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
          resultCode:data.resultCode
          });
      }

    if(item.socialId === 1){
      if(data.userdata && data.logindata.res && data.logindata.res && data.logindata.res.resultData){
        if(item.remember){
          try {
            let res = data.logindata.res.resultData || {}
            let d = {
              "user":{ ...res },
              "username":item.username,
              "password":item.password,
              "remember":item.remember
             }
             console.log(d,"ERegister",item)
            //  this.setState({userRemember:d})
            let haslocalStorage = !!localStorage;
            if (haslocalStorage) {
                    localStorage.setItem('user-e-register',AES.encrypt(JSON.stringify(d), "szlogin"));
                }
              }
          catch (e) {
            console.log(e)
          }
        }else{
          try {
            localStorage.setItem('user-e-register',AES.encrypt(JSON.stringify({"remember":false}), "szlogin"));
          }catch (e) {}
        }
      }else{
        try {
          localStorage.removeItem('user-e-register')
        }catch (e) {}
      }
    }
    if(data && data.pageError){
      this.props.updatePageError(data.pageError)
      this.props.history.push({ pathname:"/login-error", search:this.props.location.search})
    }
    let dataRes:any  = {}
    let edata:any = false;
    if(data.resList && data.resList.length)
    for (let index = 0; index < data.resList.length; index++) {
      const e = data.resList[index];
        if(e.resultCode!=="20000" && e.resultCode[0] === "2"){
          edata = e
          break;
        }
    }
    if(edata){
      dataRes = edata.logindata.res
    }else if(data.userdata){
      dataRes = data.userdata.res
    }else{
      if(data.logindata.res)
      dataRes = data.logindata.res
    }
    if(data.msgE)
      dataRes.msgE = data.msgE

    if(data.resultCode !== "20000"){
      dataRes.resultCode = data.resultCode;
    }
    dataRes.data = data
    return dataRes
    }


    postData(data){
      let params: any = queryString.parse(this.props.location.search);
      this.setState({data:false,loding:true})
      Request.post("/register/confirmForgotPassword", {
        email:this.state.email,
        confirmForgotCode:params.key,
        password:data.password,
        flagConfirm:1
      })
        .then( async (res: any) => {
          this.setState({loding:false})
          if (res.resultCode && res.resultCode[0] === "2") {// === "20000"
            // this.setState({data:res.errorMessage })
           let d = await this.ERegister({email:this.state.email,...data})
          console.log("res",d)
          }else{
            Notification.error({
              message:"",
              description:res.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
              resultCode:res.resultCode
              });
          }
          }).catch(e => {
            console.log(e);
            this.setState({loding:false})
           Notification.error({
            message:"",
            description:e.errorMessage || (i18n[this.props.locale]?i18n[this.props.locale].errOther:""),
            });

          });
    }


handleSubmit = e => {
  e.preventDefault();
  this.handleConfirmPasswordChange(this.state.confirmPassword)
  this.handleNewPasswordChange(this.state.newPassword)
  this.props.form.validateFields(async (err, user) => {

    if (!err && this.state.v_confirmPassword.validateStatus==='success' && this.state.v_newPassword.validateStatus==='success'  ) {
        console.log(user)
        this.postData({password:this.state.newPassword})
        return false;
      }
    });
  }

  validatePassword(data) {
    if(!data && this.state.newPassword){
      return {
        validateStatus: 'error',
        errorMsg: DictionaryService.msg("vConfirmPassword",this.props.locale),
      };
    }

    if (data === this.state.newPassword || !this.state.newPassword) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }



    console.log(DictionaryService.msg("vConfirmPasswordNotMatch",this.props.locale))
    return {
      validateStatus: 'error',
      errorMsg: DictionaryService.msg("vConfirmPasswordNotMatch",this.props.locale),
    };
  }

  validatePasswordRegex(data,type ='') {
    let regex = /^[a-zA-Z0-9!@#$&()\-.+,/*_:<=>?@\[\]^`{}|~]*$/
    console.log("regex",regex,regex.test(data),data,data.length )
    if(data.length <= 0 ){
      if(type === 'confirm'){
        return {
          validateStatus: 'error',
          errorMsg:  DictionaryService.msg("vConfirmPassword",this.props.locale),
        }
      }
      return {
        validateStatus: 'error',
        errorMsg:  DictionaryService.msg("vPassword",this.props.locale),
      }
    }else if(data.length < 4 || data.length > 16 ){
      return {
        validateStatus: 'error',
        errorMsg:  DictionaryService.msg("errOldNewPasswordMin",this.props.locale),
      }
    }else if(!regex.test(data)){
      return {
        validateStatus: 'error',
        errorMsg:  DictionaryService.msg("errOldNewPasswordRegex",this.props.locale),
      }
    }

    return {
      validateStatus: 'success',
      errorMsg: null,
    };
  }

  handleConfirmPasswordChange = ( confirmPassword ) => {
    setTimeout(() => {
        let temp = this.validatePasswordRegex(confirmPassword ,'confirm')
        console.log("temp",temp)
        if(temp.validateStatus === 'error'){
          this.setState({
            v_confirmPassword: {...
              {...temp},
              value:confirmPassword
            },
          });
          return;
        }

        // setTimeout(() => {
          let { newPassword } = this.state;
            this.setState({
              v_confirmPassword: {
                ... {...this.validatePassword(confirmPassword)},
                value:confirmPassword
              },
              v_newPassword: {
                ... {...this.validatePassword(newPassword)},
                value:newPassword
              },
            });

        // }, 25);
    }, 25);
  };

  isDisabledBT(){
    console.log( this.props.form.getFieldsError() ,this.props.form.isSubmitting())
    return !( this.state.v_confirmPassword.validateStatus==='success' && this.state.v_newPassword.validateStatus==='success')
  }

  handleNewPasswordChange = (newPassword) => {
    // let pass = true;
    setTimeout(() => {
        let temp = this.validatePasswordRegex(newPassword)
        if(temp.validateStatus === 'error'){
          this.setState({
            v_newPassword: {...
              {...temp},
              value:newPassword
            },
          });
          return;
        }
        // setTimeout(() => {
          let { confirmPassword } = this.state;
          this.setState({
            v_newPassword: {
              ... {...this.validatePassword(newPassword)},
              value:newPassword
            },
            v_confirmPassword:{
              ... {...this.validatePassword(confirmPassword)},
              value:confirmPassword
            }
          });

      // }, 25);
    }, 25);
  };

   replaceAt(str, index, replacement) {
    if(!str){
      return "";
    }
    else if(index >= str.length ){
      return str;
    }
    else{
      return str.substr(0, index) + replacement+ str.substr(index + replacement.length);
    }
  }


  render() {
    let props = this.props;
    const { getFieldDecorator,isFieldsTouched,getFieldsError } = this.props.form;
    console.log("init:4",this.state.dataInit)
    let mobileNo = this.state.dataInit.mobileNo;
    const $isFieldsTouched = isFieldsTouched();
    mobileNo = this.replaceAt(mobileNo,0,"xxxxxx")

    let errmsg = "One time Password (OTP) has been sent to your mobile no.  "+mobileNo+", please enter 6-digit OTP here to confirm your identity";
    if(this.props.locale === "TH"){
      errmsg = " รหัสผ่านครั้งเดียว (OTP) ถูกส่งไปยังเบอร์มือถือ "+mobileNo+" ของคุณแล้ว โปรดป้อนรหัส OTP 6 ตัว ที่นี่เพื่อยืนยันตัวตน";
    }

    if(this.state.cmd !== 0 ){
      errmsg = "One time Password (OTP) has been sent to your mobile no.  "+mobileNo+", please enter 6-digit OTP  here to reset password.";
      if(this.props.locale === "TH"){
        errmsg = " รหัสผ่านครั้งเดียว (OTP) ถูกส่งไปยังเบอร์มือถือ "+mobileNo+" ของคุณแล้ว โปรดป้อนรหัส OTP 6 ตัว ที่นี่เพื่อรีเซ็ตรหัสผ่าน";
      }
    }



  return <div className="">
          <div>
            <div >
              <div  id="login-" className="bg_login login"  >
                  <div className="bt-sz-back fix" onClick={()=>{
                      this.props.history.push({ pathname:"/", search:this.props.location.search});
                    }}  >
                <Translate value="back" />
                </div>

                <div className="row" style={{paddingLeft: 15,paddingRight: 15}}>
                {
                  !this.state.isNewpass?
                  <div className="col-md-12 header-text text-left"style={{paddingLeft: 6}} >
                    {
                      this.state.cmd === 0?<Translate value="otp" />:<Translate value="otp_2" />
                    }

                  </div>
                  :null
                  }

                  {this.state.text?<div style={{color: 'red'}}>
                    {this.state.text}
                  </div>:null}
                  {(this.state.loading)? <div id="login_" style={{width: '100%' ,padding: 20}}> <div style={{ paddingTop: 0, display: 'table', margin: '0 auto' }}> <div className="loader"></div> </div> </div>:null}



                  <div>


                  {
                    !this.state.isNewpass?
                    <div>
                    <Form onSubmit={this.confirmOTP} className="login-form" style={!this.state.loading && !this.state.isNewpass ? {} : { display: 'none' }} >
                    {this.state.dataInit && this.state.dataInit.refCode?
                      <div className="row" style={{color:"#fff"}}>
                      <div className="col-md-12 col-12 text-center" style={{marginTop: 10}}>
                      {/* <Translate value="mOtp1" /> */}
                      {errmsg}
                      </div>
                      <div className="col-md-12 col-12 text-center" >
                      {/* <Translate value="mOtp2" />( {mobileNo} ) */}
                      </div>
                      <div className="col-md-12 col-12 text-center" >
                      <Translate value="mOtp3" />  : {this.state.dataInit.refCode}
                      </div>

                      {
                        this.state.timeOutMinsTime?
                        <div className="col-md-12 col-12 text-center" >
                         <Translate value="mOtp4" />: {this.state.timeOutMinsTime}
                        </div>:null
                      }



                      <div className="col-md-12 col-12 text-center" >

                      <Form.Item style={{width: 150,display: 'table',margin: '0 auto',marginTop: 15}}>
                        {getFieldDecorator('code', {
                          rules: [
                            {
                              required: true,
                              message: i18n[this.props.locale].mOtp1,
                            },
                            {
                              min: 6,
                              message: i18n[this.props.locale].mOtp1 ,
                            }

                          ],
                        })(<Input placeholder="" maxLength={6} style={{width: '100%',textAlign: 'center'}}/>)}
                      </Form.Item>


                      </div>

                      <div className="col-md-12  col-12" style={{marginTop: 12}} >
                      <div className="row" >
                        <div className="col-6" >
                            <Button id="updateprofile-submit" type="primary" htmlType="submit" className="login-form-button bt-singin" style={{
                              width: '100%',
                              background: '#f89932',
                              borderColor: '#f89932',marginBottom:10,marginTop:10}}

                              disabled={(hasErrors(getFieldsError())) ||  (!$isFieldsTouched) }

                              >
                            <Translate value="submit" />
                            </Button>



                          </div>

                          <div className="col-6" >
                            <Button id="updateprofile-submit" type="primary"  className="login-form-button bt-close" style={{
                              width: '100%',
                              background: '#f89932',
                              borderColor: '#f89932',marginBottom:10,marginTop:10}}
                              onClick={()=>{
                                this.resendOTP()
                                }}>
                            <Translate value="resendOtp" />
                            </Button>


                            </div>
                          </div>
                          </div>
                    </div>:null
                    }
                    </Form>
                    </div>:<div>


                    <div id="form_login" style={{marginTop:15}}>
                    <div >
                    <Form onSubmit={this.handleSubmit} className="login-form"  style={!this.state.loading ? {} : { display: 'none' }}>
                      <div id="form_login" style={!this.state.data ? {} : { display: 'none' }}>
                      <div className="row">
                      <div className="col-12 header-text" >
                              <Translate value="memberForgot" />
                              </div>
                         <div className="col-12" >

                      <Form.Item
                          validateStatus={this.state.v_newPassword.validateStatus}
                          help={this.state.v_newPassword.errorMsg || ''}
                      >

                            <Grid container spacing={3} alignItems="flex-end">

                            <Grid item  xs={11}>
                              <TextField id="input-with-icon-grid"
                              label={DictionaryService.msg("newPassword",this.props.locale)}
                              style={{width:  '100%'}}
                              value={this.state.newPassword}
                              type="password"
                              onChange={(e)=>{
                                let value = e.target.value
                                this.setState({ newPassword:value})
                                this.handleNewPasswordChange(value)
                              }}
                              />
                            </Grid>
                            <Grid item  xs={1}>
                            {/* <Form.Item> */}
                            <Tooltip text={()=> {
                              return <>
                               <h5 style={{marginBottom: 0}}> <Translate value="passwordInfo1" /> </h5>
                                <Translate value="passwordInfo2" /> <br/>
                                <Translate value="passwordInfo3" /> <br/>
                                <Translate value="passwordInfo4" /> <br/>
                                <Translate value="passwordInfo5" />
                              </>
                            }} style={{margin: -15}}>
                            <IconButton aria-label="delete"  size="small">
                                  <PriorityHigh style={{fontSize: 15,color:'#fff' }}/>
                            </IconButton>
                            </Tooltip>
                            {/* </Form.Item> */}
                            </Grid>
                          </Grid>

                            {/*    ,
                          )} */}
                        </Form.Item>


                        </div>
                        <div className="col-12">
                        <Form.Item
                          validateStatus={this.state.v_confirmPassword.validateStatus}
                          help={this.state.v_confirmPassword.errorMsg || ''}
                          >

                        <Grid container spacing={3} alignItems="flex-end">

                            <Grid item  xs={11}>
                              <TextField id="input-with-icon-grid"
                              label={DictionaryService.msg("confirmPassword",this.props.locale)}
                              style={{width:  '100%'}}
                              value={this.state.confirmPassword}
                              type="password"
                              onChange={(e)=>{
                                let value = e.target.value
                                this.setState({ confirmPassword:value})
                                this.handleConfirmPasswordChange(value)
                              }}
                              />
                            </Grid>
                          </Grid>
                        </Form.Item>
                        </div>
                        <div className="col-12 text-center" style={{marginTop:30}}>
                        <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button  bt-singin"
                        disabled={this.state.loding || this.isDisabledBT()}

                        style={{
                          background: '#f89932',
                          borderColor: '#f89932',
                          width: '265px'}}>
                           <Translate value="submit" />
                        </Button>
                        </Form.Item>
                        </div>
                        {/* <div className="col-6 text-center"  style={{marginTop:10}}>
                          <Form.Item>
                          <Button className="bt-singin-other" onClick={()=>this.redirectChilli()} style={{color: '#fff',width:'100%'}}>
                            <Translate value="back" />
                          </Button>
                        </Form.Item>
                        </div> */}
                      </div>

                          </div>
                      </Form>

                      <div style={this.state.data ? {} : { display: 'none' }}>
                      <div className="row">
                        <div className="col-12" style={{marginTop: 15}}>
                              <div style={{textAlign:'center'}}>
                              {this.state.data?
                              <p style={{fontSize:20,color:'#fff'}}>
                                {this.state.data}
                                {/* ทำรายการสำเร็จ */}
                              </p>:null}
                              <Button className="login-form-button bt-singin" id="forgotpassword-confirm-submit" onClick={()=>{

                                }} style={{marginTop: 15,width:"100%",color:'#fff'}}>
                              <Translate value="login" />
                              </Button>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>




                    </div>
                  }

                    {/* {props.pageError === 'already'?
                    <div>
                       <div style={{marginTop: 20}}>
                         {this.state.text?this.state.text:<Translate value="already"></Translate>}
                      </div>
                     {this.state.dataInit?
                      <p className="p-button" style={{marginTop: 10}}>
                          <button id="btnstamp" className="login-form-button bt-singin auto-height" style={{height:"auto !important"}}
                             onClick={()=>{

                             }}> <Translate value="lblStampLink"></Translate> </button>
                      </p>:null}
                    </div>
                    :null} */}

                </div>
                </div>
              <div id="form_login"  >

              </div>


          </div>
        </div>
      </div>
    </div>
  }
}

// registerUser={this.state.registerUser}
// registerAction={this.state.registerUser}




// const setOTPData = (res)=>{
//   // return actions.setOTPData(res)
// }
const mapDispatchToProps = {

}
const mapStateToProps: any = (state: any) => ({
  otpData: getCurrentOtpData(state)
});

const ConfirmOtpPage_:any = connect<any, any, {}>(mapStateToProps, mapDispatchToProps)(Form.create({})(ConfirmOtpPage));
export default ConfirmOtpPage_;

// export default ConfirmOtpPage
