import React, { Component } from 'react';
import { Route, Link } from "react-router-dom";
import { Translate } from 'react-redux-i18n'
import { Icon } from 'antd'
type AnnouncePopupProps = {
  text:any
}

type AnnouncePopupState = {
  height:any,
  active:any,
  close:any
}
let height = 50;
class AnnouncePopup extends Component<AnnouncePopupProps, AnnouncePopupState> {
  myInterval:any;
  constructor(props) {
     super(props);
     this.state = {
       height:0,
       active:false,
       close:false
     };

  }

  componentDidMount() {
    setTimeout(()=>{
          this.setState({
            height:height
          },()=>{
            setTimeout(()=>{
                  this.setState({
                    active:true
                  })
            },700)
          })
    },500)
    // this.myInterval = setInterval(()=>{
    //   // if(this.state.height > height - 1){
    //   //   clearInterval(this.myInterval);
    //   // }else{
    //   //   this.setState({
    //   //     height:this.state.height + 3
    //   //   })
    //   // }
    //
    // }, 10);

  }

  render() {

    return (
      <>
      {
        !this.state.close?<div className={"sk-top "} style={{height: this.state.height}}>
          <div className="sitekit-smart-bar-wrap sitekit-background-pattern1">
              <div className="sitekit--smart-bar-padding">
                <div className={"sitekit-element-message "+(this.state.active?"fadeInUp animated":'')} >
                <Icon type="sound" style={{position: 'relative',  top: -3,padding:5}}/>{this.props.text}
                </div>
              </div>

                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>{
                  this.setState({
                    close:true
                  })
                }}>
                <span aria-hidden="true">×</span>
              </button>
          </div>
        </div>:null
      }

      </>)
  }
}


export default AnnouncePopup
