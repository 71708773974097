import React, { Component } from 'react';
import { Button ,Form,Icon } from 'antd';
// import StatusOfSystem from './StatusOfSystem';
// import { Translate } from 'react-redux-i18n'

import Translate from "./../../../Translate";
class LoginInfo extends Component<{buildingInfo:any,history:any,location:any,data:any, user:any, OnSubmitLogin:any ,OnSubmitLogOff:any,locale:any}, {}> {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.getInitData()

  }

  getInitData(){
    if(!this.props.user){
      this.props.history.push({ pathname:'', search:this.props.location.search});
    }else{
      // Request.getOther("https://jsonplaceholder.typicode.com/todos/1")
    }
  }

  render() {
    const  { data, user, OnSubmitLogin ,OnSubmitLogOff,locale,buildingInfo } = this.props;
    console.log("buildingInfo",buildingInfo)
    let _buildingInfo:any = {};
    try{
      _buildingInfo = buildingInfo.data
    }catch(e){

    }
    return (
      <>

      {/*
        _buildingInfo.enableIframeLoginSuccess && _buildingInfo.iframeLoginSuccessUrl?
        <div style={{position: 'fixed',top: 10,right: 15,zIndex: 1000}}>
        <Button type="primary" icon="shrink" size={'small'} onClick={()=>{
              this.props.history.push({ pathname:"/user-info-iframe", search: this.props.location.search});
        }} style={{border: 'transparent',backgroundColor: 'transparent',color: 'black',height: 30,boxShadow: 'none'}}/>
        </div>
        :null
      */}

      <div id="login-" className="bg_login" >

        <div className="row" style={{  marginTop: 38}}>
          <div className="col-12" style={{    paddingRight: 4,paddingLeft: 4}}>




          <div className="detail-data">
          <div className="row">
            <div className="col-4 col-md-4 text-left"  style={{paddingLeft: 12,paddingRight:0}}>
            <Translate value="name"/>
            </div>
            <div className="col-1 col-md-1 text-center nopadding">
            :
            </div>
            <div className="col-7 col-md-7 text-center nopadding detail-nowrap">
            {user.userName}
            {/* huajuak@gmail.com */}
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-md-4 text-left"  style={{paddingLeft: 12,paddingRight:0}}>

            <Translate value="startDate"/>
            </div>
            <div className="col-1 col-md-1 text-center nopadding">
            :
            </div>
            <div className="col-7 col-md-7 text-center nopadding detail-nowrap">
            {user.acctStartTime ? user.acctStartTime : "-"}
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-md-4 text-left"  style={{paddingLeft: 12,paddingRight:0}}>
            <Translate value="validUntil"/>
            </div>
            <div className="col-1 col-md-1 text-center nopadding">
            :
            </div>
            <div className="col-7 col-md-7 text-center nopadding detail-nowrap">
            {user.activeExpireDate ? user.activeExpireDate : "-"}
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-md-4 text-left" style={{paddingLeft: 12,paddingRight:0}}>
            <Translate value="remainingTime"/>
            </div>
            <div className="col-1 col-md-1 text-center nopadding">
            :
            </div>
            <div className="col-7 col-md-7 text-center nopadding detail-nowrap">
            {user.timeLeft === "Unlimited"?<span>
            { locale === 'EN'?<span>Unlimited</span> :<span>ไม่จำกัด</span>}
            </span>:<span>
            {user.timeLeft}
            </span>}

            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 text-center" >
            (<Translate value="on"/> {user.servertime})
            </div>
            </div>
          </div>


          {/* <div className="detail-data-time text-center" style={{marginTop:4}}>
          <div>(on {user.servertime})</div>
          </div> */}

          {/* {
            user.isSocialMember?
            <div className="row">
            <div className="col-12 text-right">
            <Link to="/edit-profile" style={{color:'#fff'}}>Edit Profile</Link>
            </div>
            </div>:null
          } */}

          <div style={{marginTop:15 }}>
          <form
            name="form_logout"
            className="form_logout"
            method="get"
           onSubmit={(e)=>{
            e.preventDefault();
            OnSubmitLogOff();
           }}
          >

          {
            this.props.buildingInfo.data.redirectURL?
            <p className="p-button" style={{textAlign:"center",width:"100%"}}>
              <a className="ant-btn login-form-button bt-singin ant-btn-primary" href={this.props.buildingInfo.data.redirectURL} target="_system" style={{fontSize: 14,fontWeight: 100,color:'#fff',width: '100%',lineHeight: '40px'}}>
                <Translate value="continue"/>
                </a>
             </p>:null
          }

          <p className="p-button" style={{}}>
            <button  id="signout-submit"
              className="login-form-button bt-singin-other"
              type="submit" style={{   fontSize: 14,fontWeight: 100,padding: 0,width:'100%'}}>
                 <Translate value="signOut"/>
              </button>

          </p>
          </form>
          </div>

          {/* <p className="p-button" style={{textAlign:"center",width:"100%"}}>
            <a href="http://www.google.com/" target="_system" style={{color:'#fff'}}>open</a>
          </p> */}
        </div>
      </div>
      </div>
    </>);
  }
}




export default LoginInfo;
