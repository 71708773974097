// import { Dictionary } from './Dictionary'

const EN: any = {
  'redirectRegisAlphabox':'Please enter your confirmation code from the Alpha Box manual.',
  'redirectRegisAlphabox2':'*Confirmation code is required by Alpha Box owner only.',
  'msg_connect': 'You are now connected to the network at ',
  'msg_connect2': ' If you want to use the internet, please contact us at ',
  'nav_information': 'Information',
  'nav_assistance': 'Assisting Programs',
  'nav_discussion': 'Inquiry / Feedback',
  'msg_support_time': 'Open 24 hours',
  'msg_keep_logged_in': 'Keep me logged in',
  'msg_accept': 'Accept',
  'msg_term_of_use': 'the terms of use.*',
  'msg_number_line': 'Number of line',
  'msg_status_normal': 'active',
  'msg_status_error': 'inactive',
  'click_other_logout': 'Click to logout the other device from the system',
  "new_users":"New users",
  'buy_card': 'Buy Card',
  'read': 'read',
  'none_building':'Your phone\'s basic browser doesn\'t support this website. Please open Google Chrome app, then type 2.2.2.2 to access login page.',
  'errAgreement': 'You must accept the usage agreement by clicking on the checkbox below the password form to login.',
  'errBlankUserPass': 'Please type a Username and Password',
  'errUserPassIncorrect': 'Your Username or Password is incorrect.',
  'errInvalidCustGroup': 'Your card was purchased from a different location. Cards must be used at the location where they were purchased.',
  'errFreeTestDisabled': 'You can not use the free test username here.',
  'errActiveExpireDate': 'Your Card/Account has reached its maximum valid usage date.',
  'errCardExpireDate': 'Your Card/Account has reached its expire date.',
  'errSmartZoneFreetest': 'The usage time of free test username cannot be viewed.',
  'errLockMacInvalid': 'Your Username cannot log in by this computer.',
  'errMacAlreadyUsedPromotion': 'Your internet device has already used the free internet trial. ** 1 free trial / 1 phone number / 1 device **',
  'errPromotionUsernameForOtherMac': 'This username cannot be used on this device. ** 1 free trial / 1 phone number / 1 device **',
  'errLoginData': 'Information for login not complete. Please click the link below and try again.',
  'errLoginToRMS': 'Fail login to SmartRMS.',
  'errRMSCardNotFound': 'You have not purchased a card yet. Please buy the card first.',
  'errLoginFail': 'Login fail try again.',
  'errOther':'Connection error',
  'errOldNewPasswordSame': "Old password and new password are the same.",
  'errOldNewPasswordMin': "Password must be 4-16 characters.",
  'errMobiledMin': "Mobile must be 10 characters.",
  'errMobiledMax': "Mobile must be 10 characters.",
  "errOldNewPasswordRegex":"You can enter a-z A-Z 0-9 and special characters, except ' \" and ;",
  "passwordInfo1":"Notice:",
  "passwordInfo2":"- Password must be 4-16 characters.",
  "passwordInfo3":"- Alphabets a-z, A-Z and numbers 0-9 and special characters are allowed.",
  "passwordInfo4":"- Special characters that can be entered:",
  "passwordInfo5":"   ! # $ % & ( ) * + , - . / : < = > ? @ [ \ ] ^ _ ` { | } ~",
  "username":"Username",
  "singIn":"Sign in",
  "email":"Email",
  "password":"Password",
  "firstName":"First Name",
  "lastName":"Last Name",
  "birthday":"Birthday",
  "idCard":"ID Card",
  "passport":"Passport",
  "register":"Register",
  "female":"Female",
  "male":"Male",
  "gender":"Gender",
  "mobileNo":"Mobile No",
  "occupation":"Occupation",
  "nationality":"Nationality",
  "vUsername":"Please input your username!",
  "vPassword":"Please input your Password!",
  "vNewPassword":"Please input your New Password!",
  "vConfirmPassword":"Please input your Confirm Password!",
  "vEmail":"Please input your Email!",
  "vIDcard_":"ID card information was incorrect",
  "vPassport_":"Passport information was incorrect",
  "errPhoneRegex":"Phone information was incorrect",
  "vConfirmPasswordNotMatch":"New Password and Confirm Password do not match.",
  "vMobileNo":"Please input your Mobile No!",
  "vMobile":"Please input your mobile phone no.",
  "vMobile2":"Invalid mobile number format,Please enter your 10-digit mobile phone number to complete.",
  "vFirstName":"Please input your First Name!",
  "vLastName":"Please input your Last Name!",
  "vBirthday":"Please input your Birthday!",
  "vIdCard":"Please input your ID Card or your Passport!",
  "vPassport":"Please input your ID Card or your Passport!",
  "vGender":"Please input your Gender!",
  "rememberMe":"Keep me logged in",
  "forgotPassword":"Forgot your password?",
  "newPassword":"New Password",
  "vOldPassword":"Please input your Old Password!",
  "oldPassword":"Old Password",
  "confirmPassword":"Confirm Password",
  "signIn":"Sign in",
  "back":"Back",
  "registerNow":"Register",
  "loginForFree":"Login for free",
  "loginForNotFree":"Other Login types",
  "registerText":"Register",
  "or":"Or",
  "welcome":"Welcome",
  "dateOfBirth":"Date of Birth",
  "loginWithFacebook":"Login with Facebook",
  "loginWithLine":"Login with Line",
  "loginWithMobile":"Login with Mobile",
  "loginWithERegister":"Login with Email (E-Register)",
  "vNotRobot":"Please click the box above to confirm that you are not a robot.",
  "editProfile":"Profile",
  "changePassword":"Change Password",
  "savePassword":"Save Password",
  "submit":"Confirm",
  "save":"Update",
  "cancel":"Cancel",
  "usageHistory":"Usage History",
  "memberForgot":"Change password",
  "reverse":"Reset",
  "resetPassword":"Reset Password",
  "reset":"Reset",
  "resetPasswordSub":"Enter your email below to receive your password reset instructions.",
  "resetPasswordSub2":"Enter your username below to receive your password reset instructions.",
  "login":"Login",
  "name":"Name",
  "startDate":"Start Date",
  "validUntil":"Valid Until",
  "remainingTime":"Remaining Time",
  "on":"on",
  "signOut":"Sign out",
  "cardType":"Card Type",
  "timeLimit": "Time Limit",
  "timeUsed": "Time Used",
  "timeRemaining": "Remaining Time",
  "startTime":"Start Time",
  "stopTime":"Stop Time",
  "upload":"Upload (Mb)",
  "download":"Download (Mb)",
  "sessionTime":"Session Time",
  "usageDetails":"Usage History",
  "total":"Total",
  "contactUs":"Contact Us",
  "lblStampLink":"Click here to logout the other device from the system",
  "already":"You are already logged in on another device",
  "warningChagepassword":"**This will change your password when login <br/> by email (e-register) only.",
  "successfullyRegisteredAndLogged":"Successfully registered and logged in. ",
  "emailNotConfirmed":"This email has not been confirmed. ",
  "facebookLoginWith":"Facebook login with",
  "chilliError":"Unable to connect to server. Please try again.",
  "tryAgain":"Try again",
  "otp":"Register - Confirm OTP",
  "otp_x":"Confirm OTP",
  "otp_2":"Forgot password - Confirm OTP",
  "continue":"Continue",
  "resendOtp":"new OTP",
  "mOtp1":"Please enter OTP 6 digits",
  "mOtp2":" ",
  "mOtp3":"Reference no",
  "mOtp4":"OTP will expire at",
  "smotp":"Mobile No. for receive OTP",
  "regis_h1":'Please fill out this form according to the Cyber crime Act.',
  "regis_h2":'Register and confirm the email address for the first time only.',
  "regis_h3":'Register first time only.',
  "errm4":"Invalid Location phone format,Please enter again.",
  "recaptcha1":"Please enter captcha values.",
  "recaptcha2":"Please enter the text from the image correctly.",
  "LoginWithWserCard":"Login with user card",
  "v_facebook_like":"Please click like the facebook page.",
  "nextStep":"Next",
  "emailNotConfirmed1":"Your email address ",
  "emailNotConfirmed3":"Do you want to register?",
  "mNote":"Remark",
  "mNoteEregister":"Please enter your email address to login or register."
}

export default EN
