import React  from 'react';
import { Translate } from 'react-redux-i18n'
import { Divider } from 'antd';
import { Link } from "react-router-dom";
const ContactUs = (props) =>{
  let buildingInfo = props.buildingInfo && props.buildingInfo.data ? props.buildingInfo.data :{};
  // console.log("buildingInfo",buildingInfo)
  return <footer className="text-muted contact-us">
    {
     !buildingInfo.loginAlphaboxFlag ?
    <div className="container">
      <p ></p>
      <div  className="row" >

        <div className="col-md-12">
          <div className="header">ติดต่อเรา / Contact Us</div>
          <Divider orientation="left"><div className="sub-header">
          <span className="msg_support_time">(<Translate value="msg_support_time"  />)</span>
          </div></Divider>
        </div>
        {/* <div className="col-md-12" style={{marginTop:10,marginBottom:10}}>
         <b  style={{color:'#fff'}}> หน้าที่เกี่ยวข้อง</b>
        <div className="link-page">
          <Link to="/usage-history" style={{color:'#fff'}}> - Usage history</Link>
        </div>
        </div> */}


        <div className="col-md-12 text-right mini-icons" style={{marginTop:20,marginBottom:20}}>
        <span className="icons">
        <img src={require('../../../../assets/icons/icon_telephone.png')} />
        <span id="telephone_number">{ buildingInfo.servicePhone?buildingInfo.servicePhone:"02-026-1922"}</span>
        </span>
        <span className="icons"><img src={require('../../../../assets/icons/icon_line@.png')} />@smartzone</span>
        <span className="icons"><img src={require('../../../../assets/images/icon_email@.png')}  />support@smartzoneonline.com</span>
        <span className="icons">
        <Link  to="/privacy-policy-page" style={{color: '#fff'}} >
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsSAAALEgHS3X78AAADgUlEQVQ4jZWVX0zbVRTHP/fXUn5dy9quFKZ0QAaZ1vkHZtCA7kUehKkxW5ZM5oNmb0YT3YMxLsbEaLaHPSxRH9REYkYUHxSzLEZJfGEPKv9CRorKgjCwjP5dW0rXltJeHy6FUlqSfd/uPfd8z/mec+69ggqIxaTjho++8SV658O0htM0IDE5LQQOH2C6vZFfut0M2u0iWs5flG5IKfWr41z4dorzyXWslQID6EbWzrZz5dzTXBJCpCoSR1Oy+b3rXJ8J8OhehKU4Uov3Yg8vPeAQt3cRR+5J97nv+SOcxH0/pAXYdHwDr9Lp3Cd8ABoo+W//xFAlUg2oNoCmbTqUQTyN+40fGJJSmreIByZ5Z+EuHQCP1ENXM1irlZw6K3jqYT0HMg9yU+au5gDLcTouj/ABgIhJ6XjxcxYk2AA+6YVDdpgLQyQJqSw8aIP5COhGuB2FYw3w4zScOArLMai1wGIU7sRhJkT6OQ9txhsz9BVI2cxkvw4GAaefgJ//AocZXvAo+9gSPFwPr3fAwRrQDUpVewP0j4HIowfjfKj9vkhPqaSBCfhmHAIJuHkHNvKQk5Bch+YD6oxBU2UBmA2Cpw5WVtV6JU6XcT5CWzHp3wEl614WJv6DUBJm/HArBNmcCtDkULYn3RBLQS6vggfXFEcqRa3o/kJm0llMxaUohSxZl55xWiCzAYnMlpoNI5IMbBMDuKygCQgmtqfAaQEpVe1DyZ3EkWRJ8Dx5o30fK/5Vaor3P3oeXBa4OqnW/lU49TiE1kAI+O0WNNjAn1BlqDbC6NK2v0UnajzsYta/ypFS+ZkcdDapCXnIBRM+sJhU5h/3qukQgN2selJM3OLiptbZyHCZsnLNC8OzqolVhp02owbjSzC1rC5UILHT3uXmmtZ9lEEB8WJDKAn/BNUYWU0wMq/qGE1BOAkj/0KvB3xxdUN9Rd7CQPxEK4MCoH9Mvv/1KBfLZb4X6mu2Ry+/2b2+di689ay4JACklOZXBhj1xXnsfsmLUVeDd+g1nhJCpDQAIUTqs5c5tV/HV8mpylB+xguwVOH79CQnCw/+1itYZxNz/ac53uLEW84xm9t9UQpodOD98gzHD9nEXGGv3Ndk/upPzg9O8W42h32PJNEEsTNtXH7zGa7s+TUVIxaTjuEFzk766Fm8S2ssjRuByabjb3Iyfewgv3a18F1Thc/0fx8qSIt6Og7KAAAAAElFTkSuQmCC"/>
          Privacy Policy
        </Link>
        </span>
        </div>
      </div>
    </div>  :
    <>
    {
      buildingInfo.servicePhone || buildingInfo.socialContact?
    <div className="container">
      <p ></p>
      <div  className="row" >

        <div className="col-md-12">
          <div className="header">ติดต่อเรา / Contact Us</div>
          <Divider orientation="left">
          </Divider>
        </div>
        <div className="col-md-12 text-left mini-icons" style={{marginBottom:8}}>
        <span className="icons">
        {buildingInfo.servicePhone?<>
        <img src={require('../../../../assets/icons/icon_telephone.png')} />
        <span id="telephone_number">{ buildingInfo.servicePhone?buildingInfo.servicePhone:"02-026-1922"}</span>
        </>:null}
        </span>
        <span className="icons">
        {buildingInfo.socialContact?<>
        <img src={require('../../../../assets/icons/icon_all.png')} />
        <span id="telephone_number">{ buildingInfo.socialContact}</span>
        </>:null}
        </span>

        <span className="icons">
        <Link  to="/privacy-policy-page" style={{color: '#fff'}} >
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsSAAALEgHS3X78AAADgUlEQVQ4jZWVX0zbVRTHP/fXUn5dy9quFKZ0QAaZ1vkHZtCA7kUehKkxW5ZM5oNmb0YT3YMxLsbEaLaHPSxRH9REYkYUHxSzLEZJfGEPKv9CRorKgjCwjP5dW0rXltJeHy6FUlqSfd/uPfd8z/mec+69ggqIxaTjho++8SV658O0htM0IDE5LQQOH2C6vZFfut0M2u0iWs5flG5IKfWr41z4dorzyXWslQID6EbWzrZz5dzTXBJCpCoSR1Oy+b3rXJ8J8OhehKU4Uov3Yg8vPeAQt3cRR+5J97nv+SOcxH0/pAXYdHwDr9Lp3Cd8ABoo+W//xFAlUg2oNoCmbTqUQTyN+40fGJJSmreIByZ5Z+EuHQCP1ENXM1irlZw6K3jqYT0HMg9yU+au5gDLcTouj/ABgIhJ6XjxcxYk2AA+6YVDdpgLQyQJqSw8aIP5COhGuB2FYw3w4zScOArLMai1wGIU7sRhJkT6OQ9txhsz9BVI2cxkvw4GAaefgJ//AocZXvAo+9gSPFwPr3fAwRrQDUpVewP0j4HIowfjfKj9vkhPqaSBCfhmHAIJuHkHNvKQk5Bch+YD6oxBU2UBmA2Cpw5WVtV6JU6XcT5CWzHp3wEl614WJv6DUBJm/HArBNmcCtDkULYn3RBLQS6vggfXFEcqRa3o/kJm0llMxaUohSxZl55xWiCzAYnMlpoNI5IMbBMDuKygCQgmtqfAaQEpVe1DyZ3EkWRJ8Dx5o30fK/5Vaor3P3oeXBa4OqnW/lU49TiE1kAI+O0WNNjAn1BlqDbC6NK2v0UnajzsYta/ypFS+ZkcdDapCXnIBRM+sJhU5h/3qukQgN2selJM3OLiptbZyHCZsnLNC8OzqolVhp02owbjSzC1rC5UILHT3uXmmtZ9lEEB8WJDKAn/BNUYWU0wMq/qGE1BOAkj/0KvB3xxdUN9Rd7CQPxEK4MCoH9Mvv/1KBfLZb4X6mu2Ry+/2b2+di689ay4JACklOZXBhj1xXnsfsmLUVeDd+g1nhJCpDQAIUTqs5c5tV/HV8mpylB+xguwVOH79CQnCw/+1itYZxNz/ac53uLEW84xm9t9UQpodOD98gzHD9nEXGGv3Ndk/upPzg9O8W42h32PJNEEsTNtXH7zGa7s+TUVIxaTjuEFzk766Fm8S2ssjRuByabjb3Iyfewgv3a18F1Thc/0fx8qSIt6Og7KAAAAAElFTkSuQmCC"/>
          Privacy Policy
        </Link>
        </span>
        </div>
      </div>
    </div>:null}

    </>
    }
  </footer>




}


export default ContactUs;
