
import {  getCurrentConfig } from "../data/utils";
import { resolve } from "dns";
import configDev from '../config/dev.json';
import configLocal from '../config/local.json';
import configProd from '../config/prod.json';
const uuid = require("uuidv4");
class Config {
  config:any = false;
   getConfig(){
      return new Promise(resolve=>{
        if (this.config) {
          return resolve(this.config);
        }
        if(process.env.REACT_APP_ENV === "local"){
          this.config = {...configLocal,x_transaction_id:uuid()}
          return resolve(this.config);
        }else if(process.env.REACT_APP_ENV === "dev"){
          this.config = {...configDev,x_transaction_id:uuid(),}
          return resolve(this.config);
        }else{
          this.config = {...configProd,x_transaction_id:uuid(),}
          return resolve(this.config);
        }
        // import("../config/" + process.env.REACT_APP_ENV + ".json").then(
        //   res => {
        //     this.config = res.default;
        //     resolve(res.default);
        //   }
        // );
      })
  }
}

export default new Config();
