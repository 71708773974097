

import React, { Component } from 'react';
import i18n from "../../../../../i18n/index";
import CryptoJS from "crypto-js";
import AES from "crypto-js/aes";
import { Input,Button ,Radio ,DatePicker ,Form ,Icon ,Checkbox,Dropdown,Menu,Typography,Divider } from 'antd';
import Notification  from '../../../../../services/notification.service';
// import { Translate } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { getCurrentLocale } from "../../../../../data/utils";
import DictionaryService  from '../../../../../i18n/DictionaryService';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import ReactGA from 'react-ga';
import { async } from 'q';
const { Title } = Typography;
// import RegisterForm from './RegisterForm';
// import ForgotpasswordForm from './ForgotpasswordForm';

type TranslateProps = {
  value: any,
  locale:any
}
type TranslateState = {}
class TranslatePage extends Component<TranslateProps, TranslateState> {
  constructor(props) {
    super(props)
  }
  render() {
    let locale = "EN";
    try{
      locale = this.props.locale;
    }catch(e){

    }
    return (<span> {DictionaryService.msg(this.props.value,locale)}</span>);
  }
}

const mapStateToProps0: any = (state: any) => ({
  locale: getCurrentLocale(state),
});

const mapDispatchToProps0 = {}
const Translate:any = connect<any, any, {}>(mapStateToProps0, mapDispatchToProps0)(Form.create({})(TranslatePage));



class Forgotpassword extends Component<{buildingInfo:any,changePage:any,submitForgotPassword:any,locale:any,form:any}, {loading:any}> {
  constructor(props) {
    super(props);
    this.state = {loading:false}//user:props.registerUser
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(e){
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        ReactGA.event({category: "Forgot Password",action:""});


        this.setState({loading:true})
        await this.props.submitForgotPassword(values)
        this.setState({loading:false})
      }
    });
  }

  render() {
    console.log("buildingInfo",this.props.buildingInfo)

    let sendToChannel = this.props.buildingInfo && this.props.buildingInfo.data && this.props.buildingInfo.data.sendToChannel ?this.props.buildingInfo.data.sendToChannel : 0
    // if (this.state.loading) {
    //   return <div id="login_" >
    //     <div style={{ paddingTop: 200, display: 'table', margin: '0 auto' }}>
    //       <div className="loader"></div>
    //     </div>
    //   </div>
    // }  c
    console.log("sendToChannel",sendToChannel)

    // let {user} = this.state
    const { getFieldDecorator } = this.props.form;
    return <div id="login-" className="bg_login"   >

    {(this.state.loading)?
      <div id="login_" >
          <div style={{ paddingTop: 200,paddingBottom: 200, display: 'table', margin: '0 auto' }}>
              <div className="loader"></div>
          </div>
          </div>
        :null}

         {/* <div className="row">
        <div className="col-md-12 header-text" >
          Forgot Password
        </div>

        <div className="bt-sz-back fix" onClick={()=>this.props.changePage("login")}  >
        <Translate value="back" />
        </div>
        </div> */}


    <Form onSubmit={this.handleSubmit} className="login-form" style={!this.state.loading ? {} : { display: 'none' }} >
    <div id="form_login" className="forgot">

    <div className="bt-sz-back fix" onClick={()=>this.props.changePage("login")} >
    <Translate value="back" />
      </div>

      <div className="row">
      <div className="col-md-12 header-text">

          <Translate value="resetPassword" />



        </div>
      <div className="col-md-12 header-sub-text">
      {
          sendToChannel === 1?<Translate value="resetPasswordSub2" />:<Translate value="resetPasswordSub" />

      }
      </div>


    <div className="col-md-12" >
    <Form.Item >
    {/* <Input
                type="text"
                name="Email"
                placeholder={DictionaryService.msg("email",this.props.locale)}
                style={{background:"#fff",padding: 8}}
              /> */}
        {getFieldDecorator('email', {
          //  initialValue:this.props.registerUser.email,
            rules: [{ required: true, message: DictionaryService.msg("vEmail",this.props.locale) },{
              type: 'email',
              message: DictionaryService.msg("vEmail",this.props.locale),
            },],
          })(

              <Grid container spacing={3} alignItems="flex-end">
                <Grid item  xs={1}>
                  <div className="sz-icons"><div className="icon citizen"></div></div>
                </Grid>
                <Grid item  xs={11}>
                  <TextField
                  label={ sendToChannel === 1? DictionaryService.msg("username",this.props.locale): DictionaryService.msg("email",this.props.locale)}
                  style={{width:  '100%'}}
                  name="email"
                  id="email"
                  />
                </Grid>
              </Grid>

              ,
              )}
       </Form.Item>
       </div>

       <div className="col-md-12" >
          <Button id="forgot-password" type="primary" htmlType="submit" className="login-form-button bt-singin" style={{
            background: '#f89932',
            borderColor: '#f89932',marginTop:10,marginBottom:10,width: '100%'}}>
          <Translate value="reset" />
          </Button>
          </div>

          {/* <Button  onClick={()=>this.props.changePage("login")} style={{width: '100%'}}>
          <Translate value="back" />
          </Button> */}
    </div>
    </div>
    </Form>
    </div>
  }
}

const mapStateToProps: any = (state: any) => ({
  locale: getCurrentLocale(state),
});

const mapDispatchToProps = {}
const ForgotpasswordForm:any = connect<any, any, {}>(mapStateToProps, mapDispatchToProps)(Form.create({})(Forgotpassword));


export default ForgotpasswordForm;
