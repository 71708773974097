
import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n'
import AD from './components/Ad';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import AdCustom from './components/AdCustom';
import Request  from '../../../services/request.service';
import {isMobile} from 'react-device-detect';
// import isCaptive from "iscaptive"

class AdsMask extends React.Component<{
  setPopupAds:any,reviveBannerId:any,newBannerId:any,socialAuth:any,reloadAuth:any,buildingInfo:any,
  counterAds:any,
  setCounterAds:any
  img4:any
},{open:any,type:any,newBannerIdArr:any,mode:any,showSkip:any,count:any}>{
  constructor(props) {
    super(props);


    let newBannerIdArr:any = []
    let mode:any = null;
    let type:any = null
      let url = null;
    if(props.newBannerId.length > 3 && props.newBannerId[3] !== '0'){
      if(this.validURL(props.newBannerId[3])){
        url = props.newBannerId[3];
        type = 'url';
      }
      newBannerIdArr.push({id:props.newBannerId[3]})
    }

    if(newBannerIdArr.length === 0 && props.reviveBannerId.length > 3 && props.reviveBannerId[3] !== '0'){
      mode = 1;
      if(this.validURL(props.reviveBannerId[3])){
        url = props.reviveBannerId[3];
        type = 'url';
      }
      newBannerIdArr.push({id:props.reviveBannerId[3]})
    }


    if(props.socialAuth || props.reloadAuth){
      newBannerIdArr = []
    }
    this.state = {
      open:true,
      type:type,
      newBannerIdArr: type === 'url'?[] :newBannerIdArr,
      mode:mode,
      showSkip:false,
      count:props.counterAds
    };
        // console.log("type",type,this.props.buildingInfo.enableAdvertisement);
        // console.log("props.buildingInfo.enableAdvertisement",props.buildingInfo.enableAdvertisement);

    if(props.buildingInfo.enableAdvertisement === 1){
      setTimeout(() => {
         this.setState({ open:false},()=>{
           this.props.setPopupAds(true)
         })
      }, 5000);
    }else if(type === 'url'){

      // isCaptive()
      //   .then((captive) => {
      //       if(captive) { // you are behind a captive portal or have no internet
      //           console.log("Captive!")
      //       } else { // you have full access to the internet
      //           console.log("No captive!")
      //       }
      //   })

      // this.getURL(url,props);
      // console.log("img4img4",this.props.img4);
      if(this.props.img4){
        // this.props.img4
        newBannerIdArr = []
        let isCaptive = false;
        try {
          let userMobile = localStorage.getItem("user-mobile-data");
        } catch (error) {
          isCaptive = true;
        }
        if(this.props.img4.enableRedirect && this.props.img4.redirectUrl && (!isMobile || !isCaptive)){
          newBannerIdArr.push({id:this.props.img4.image,link:this.props.img4.redirectUrl})
        }else{
           newBannerIdArr.push({id:this.props.img4.image})
        }
        // console.log("img4img4 newBannerIdArr",newBannerIdArr);
setTimeout(() => {
               this.setState({newBannerIdArr:newBannerIdArr},()=>{
        let timer = setInterval(()=>{
          if(this.state.count > 0){
              let count = this.state.count-1;
              this.setState({count:count})
              this.props.setCounterAds(count)
          }
        }, 1000);


        setTimeout(() => {
         this.setState({ showSkip:true})
         clearInterval(timer);
       }, props.counterAds * 1000);

             setTimeout(() => {
               this.setState({ open:false},()=>{
                 this.props.setPopupAds(true)
               })
             }, 120 * 1000);
          })
        }, 50);

      }

    }else{


      setTimeout(() => {
       this.setState({ open:false},()=>{
         this.props.setPopupAds(true)
       })

     }, 5000);
    }


    // var isCNA = !(window.pagevisibility && Modernizr.sessionstorage && window.localstorage && window.hashchange)
 }

 getURL(url,props){
   console.log("getOther",url);

   Request.getOther(url, {}).then(r=>{
     r = {resultCode: "20000", resultData:{
       link:"https://www.google.com/",
       img:"https://www.google.com/logos/doodles/2020/stay-and-play-at-home-with-popular-past-google-doodles-coding-2017-6753651837108765-law.gif"
     } }
     if(r.resultCode === "20000"){
       let newBannerIdArr:any = [];
       if(isMobile){
          newBannerIdArr.push({id:r.resultData.img})
       }else{
         newBannerIdArr.push({id:r.resultData.img,link:r.resultData.link})
       }


       this.setState({newBannerIdArr:newBannerIdArr},()=>{
         let timer = setInterval(()=>{
           if(this.state.count > 0){
               let count = this.state.count-1;
               this.setState({count:count})
               this.props.setCounterAds(count)
           }
         }, 1000);


         setTimeout(() => {
          this.setState({ showSkip:true})
          clearInterval(timer);
        }, props.counterAds * 1000);

        setTimeout(() => {
          this.setState({ open:false},()=>{
            this.props.setPopupAds(true)
          })
        }, 120 * 1000);
       })


     }
     console.log("loginpagelog",r);
   })
 }




    validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  render() {
    let {newBannerIdArr,mode,showSkip } = this.state
    // console.log("newBannerIdArr",newBannerIdArr);

    return <div >

    {
      this.props.buildingInfo.enableAdvertisement === 1?<>
          <div style={{padding: 0}}>
            <AdCustom advertisement={this.props.buildingInfo.advertisement} type={'z'} buildingInfo={this.props.buildingInfo}
            setPopupAds={this.props.setPopupAds}/>
          </div>
        </>:
    <>
    {
      this.state.type ===  'url'?
      <>
      {
        this.state.open?
        <>
        {
          newBannerIdArr.map((r,index)=>{
        return <div className="ads-mask-2" onClick={()=>{
          if(showSkip){
            // this.setState({ open:false},()=>{
            //   this.props.setPopupAds(true)
            // })
          }
        }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content2">
              <div className="modal-body">
                {
                  showSkip?
                  <button type="button" className="close" style={{position: "absolute",right: 16,fontSize: "18px",top: '15px',color: '#fff',background: '#222',padding: '1px 7px',opacity: 0.8,width:90}} onClick={()=>{
                    this.setState({ open:false},()=>{
                      this.props.setPopupAds(true)
                    })
                  }}>Skip <span><SkipNextIcon style={{ fontSize: 25 }}/></span>
                  </button>:
                  <div  style={{position: "absolute",right: 16,fontSize: "18px",top: '15px',color: '#fff',background: '#222',padding: '1px 7px',opacity: 0.8,width:80}}> {this.state.count} </div>
                }
                {
                  r.link?<a href={r.link}  target="_blank"><img src={r.id}  style={{width:'100%'}} /></a>
                  :<img src={r.id}  style={{width:'100%'}}/>
                }

              </div>
            </div>
          </div>
        </div> })
      }
      </>  :null
      }
      </>:
      <>
      {
        newBannerIdArr.length > 0  && this.state.open?
        <div className="ads-mask" onClick={()=>{
          this.setState({ open:false})
        }}>
          {
                newBannerIdArr.map((r,index)=>{
                  return <AD id={r.id}  mode={mode} updateDetachAds={()=>{}} setCountTime={()=>{}} keyData={"3"}  type=""/>
              })
            }
        </div>
        :null
      }
      </>}

      </>}

    </div>
  }
}
export default AdsMask
