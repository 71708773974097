
import React,{}  from 'react';
import moment from 'moment'
declare let $: any;
declare let window: any;
class AD extends React.Component<{mode:any,keyData:any,id:any,updateDetachAds:any,setCountTime:any,type:any },{id:any}>{
  constructor(props) {
    super(props);
    this.state = {
      id:false
    }

  }

  componentDidMount () {
    console.log("keyData componentDidMount")
    setTimeout(() => {
      this.renderHtml(this.props)
    }, 200);
  }


  componentWillUpdate (nextProps, nextState) {
      if (nextProps.id !== this.props.id) {
        // this.renderHtml(nextProps)
      }
  }

  renderHtml(nextProps){
    try {
      let {id,keyData } = nextProps
      // console.log("nextProps.type",nextProps.type)
      if(nextProps.mode === 1){
        // console.log("nextProps.type",window.reviveAsync)
        // var zone:any = document.getElementById('ad-zone-'+id+'-'+keyData);
        if(id || id === '0' || id === 0){
          // let {zoneid,id } = nextProps
          var zone:any = document.getElementById('ad-zone-'+id+'-'+keyData);
          zone.innerHTML = '';
          var ins = document.createElement("ins");
          ins.setAttribute("class", "aso-zone");
          ins.setAttribute("data-revive-id", "727bec5e09208690b050ccfc6a45d384");
          ins.setAttribute("data-revive-zoneid", id);
          zone.appendChild(ins);
          let timeout = 200;
          setTimeout(() => {
            try {
              console.log("window",window)
              if(window.reviveAsync){
                for (const key in window.reviveAsync) {
                  // console.log("nextProps.type >>>",window.reviveAsync[key])
                  window.reviveAsync[key].main()
                }
              }
              // window._ASO.loadAll()
              this.props.setCountTime(new Date())
          } catch (error) {
            this.props.setCountTime(new Date())
            // this.props.updateDetachAds(true)
          }

          // this.props.setCountTime(new Date())
          }, timeout);
        }
      }else{
        var zone:any = document.getElementById('ad-zone-'+id+'-'+keyData);
        if(zone){
          zone.innerHTML = '';
          var ins = document.createElement("ins");
          ins.setAttribute("class", "aso-zone");
          ins.setAttribute("data-zone", id);
          zone.appendChild(ins);
          let timeout = 50;
          setTimeout(() => {
            try {
              console.log("keyData", id,keyData,zone)
              window._ASO.loadAll()
              this.props.setCountTime(new Date())
          } catch (error) {
            this.props.setCountTime(new Date())
            // this.props.updateDetachAds(true)
          }


          }, timeout);
        }
      }


    } catch (error) {
      console.log("id",nextProps,error);

    }
  }
render() {
  let {id,keyData,mode } = this.props

  return  <>
  {
    mode === 1?
    <>
       <div id={"ad-zone-"+id+"-"+keyData}></div>
       {/* <ins data-revive-zoneid="2599" data-revive-id="727bec5e09208690b050ccfc6a45d384"></ins> */}
    </>:  <div id={"ad-zone-"+id+"-"+keyData}></div>
  }
  </>
  }
}


export default AD;
