import React, { Component } from 'react';
import Request  from '../../../services/request.service';
// import { Translate } from 'react-redux-i18n'
import Translate from "./../../Translate";
import { getCurrentLocale } from "../../../data/utils";
import { Button ,Form,Icon } from 'antd';
import { connect } from 'react-redux'
import Notification  from '../../../services/notification.service';
import i18n from "../../../i18n/index";
import { init } from '@sentry/browser';
import queryString from 'query-string';
import AppConfig  from '../../../services/app.config';
import '../../../assets/css/new-theme/index.scss';
import { setLocale } from 'react-redux-i18n';
import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";
import AdsMask from './AdsMask';



import Header from '../../../components/Themes/default/components/Header';

declare let FB: any;
declare let $: any;
declare let chilliController: any;
declare let location:any;
type LoginIfreamProps = {
  img4:any,
  loginInfo: any,
  buildingInfo:any,
  user: any,
  locale:any,
  location:any,
  setLocaleData:any,
  history:any,data:any, OnSubmitLogin:any ,OnSubmitLogOff:any,
  ChilliData:any,
  popupAds:any,
  setPopupAds:any,
  newBannerId:any,
  reloadAuth:any,
  socialAuth:any,
  counterAds:any,
  setCounterAds:any
}

type LoginIfreamState = {
  data:any

}


// const Iframe = (props) => {
class Iframe extends Component<{src:any,onload:any}, {}> {
    iframe:any
    componentDidMount () {
      setTimeout(()=>{
        if(this.iframe){
          $(this.iframe).load(() => {
            this.props.onload()
          });
        }
      },10)
    }


    render() {
    let src_data = this.props.src;
    return(
      <>
      <div className="iframeDiv" onClick={()=>{
        // window.location.href = props.src;
      }} style={{position: 'absolute',width:"100%"}}>
          <iframe  ref={ (ref) => {this.iframe = ref }}  src={src_data} height={"100%"} width={"100%"} style={{height: '100vh'}} />
        <div className="frameDummy"></div>
      </div>
      </>
    )
  }
  };



class LoginIfreamPage extends Component<LoginIfreamProps, LoginIfreamState> {
  constructor(props) {
    super(props);
    this.state = {data:false}





  }


  beforeloginpagelog(){
    if(this.props.user){
    let chilliData = this.props.ChilliData
    let params: any = queryString.parse(this.props.location.search);
      Request.post("/loginpagelog", {
        "custGroupId":this.props.buildingInfo.data.custgroupId,
        "usermac":chilliData && chilliData.redir ? chilliData.redir.macAddress : params.mac,
        "apmac":this.props.buildingInfo.data.apmac,
        "type":"iframe_before_load",
        "loginAlphaboxFlag":1
      })
    }
  }

  loginpagelogafter(){
    if(this.props.user){
      let chilliData = this.props.ChilliData
      let params: any = queryString.parse(this.props.location.search);
      // if(chilliData){
        Request.post("/loginpagelog", {
          "custGroupId":this.props.buildingInfo.data.custgroupId,
          "usermac":chilliData && chilliData.redir ? chilliData.redir.macAddress : params.mac,
          "apmac":this.props.buildingInfo.data.apmac,
          "type":"iframe_loaded",
          "loginAlphaboxFlag":1
        })
      // }
    }


  }

 componentDidMount () {
   // console.log(this.props.user)

  this.beforeloginpagelog();
 }



  render() {
    let iframeLoginSuccessUrl = '';
    let buildingInfo:any = {};
    const  { data, user, OnSubmitLogin ,OnSubmitLogOff,locale } = this.props;
    const props = this.props;
    try{
        iframeLoginSuccessUrl = this.props.buildingInfo.data.iframeLoginSuccessUrl;
        buildingInfo = this.props.buildingInfo.data;


    }catch(e){

    }

    let newBannerId = props.newBannerId;
    let reviveBannerId_ = props.buildingInfo && props.buildingInfo.data && props.buildingInfo.data.reviveBannerId? props.buildingInfo.data.reviveBannerId:"";
    let reviveBannerId = reviveBannerId_.split(",") || [];
        let themeCode = buildingInfo.themeCode?buildingInfo.themeCode:''
    // console.log("user",user.isShowIFrame)
    //background: 'linear-gradient(0deg, rgb(37, 97, 169) 30%, rgb(46, 94, 152) 90%)'
    return (
      <div>
        {
          buildingInfo.loginAlphaboxFlag?<>
          <Helmet>
          <title>Alpha Box</title>
          <link rel="shortcut icon"  href={require('../../../assets/favicon2.ico')}  />
          </Helmet>

            </>:<>
            <Helmet>
            <title>SmartZone™</title>
            <link rel="shortcut icon"  href={require('../../../assets/favicon.ico')}  />
            </Helmet>
          </>
        }
          {/*
            <div style={{position: 'fixed',top: 10,right: 15,zIndex: 1000}}>
            <Button type="primary" icon="arrows-alt" size={'small'} onClick={()=>{
                  this.props.history.push({ pathname:"/", search: this.props.location.search});
            }} style={{border: 'transparent',backgroundColor: 'transparent',color: 'black',height: 30,boxShadow: 'none'}}/>
            </div>
          */}

          {
            !this.props.popupAds?
            <AdsMask
              img4={this.props.img4}
            buildingInfo={buildingInfo}
                newBannerId={newBannerId}
                reviveBannerId={reviveBannerId}
                reloadAuth={this.props.reloadAuth}
                socialAuth={this.props.socialAuth}
                setPopupAds={this.props.setPopupAds}
                setCounterAds={this.props.setCounterAds}
                counterAds={this.props.counterAds}
                />
              :null
          }

          <div className={"change-theme-preview app-main "+( buildingInfo.loginAlphaboxFlag?'alphabox ':' ' )+(themeCode)} id="app-main" >
             <header className="navbar navbar-dark bg-main box-shadow header-done variant typeheader-4" style={{height:50}}>
               <div className="container d-flex justify-content-between">
                 <a  className="navbar-brand d-flex align-items-center"  onClick={async ()=>{let config: any = await AppConfig.getConfig();setTimeout(() => {  window.location.href = `http://${config.chilli_host}:${config.chilli_port}`;}, 0);}} >
                   {
                     buildingInfo.logoHeader?<><img src={buildingInfo.logoHeader} alt="Logo" className="brand"style={{marginTop: 0}} /></>:
                     <>{
                       buildingInfo.loginAlphaboxFlag === 1 ?<>
                       <img src={require('../../../assets/images/alpha-box/logo2.png')} alt="Logo" className="ab-img brand"
                        style={{width: 110,marginTop: 0}} />
                       <img src={require('../../../assets/images/alpha-box/logo3.png')} alt="Logo" className="ab-img brand-min"
                         style={{width: 35,marginTop: 0}} />
                       </>:
                       <>{
                           buildingInfo.custgroupId?<><img src={require('../../../assets/images/header_logo.png')} alt="Logo" className="brand" style={{}} />
                           <strong  className="header">  Login Success </strong>
                           </>:
                           null
                         }</>
                     }</>}
                 </a>

                 <div className="dropdown" >
                 <Button  onClick={()=>{
                       this.props.history.push({ pathname:"/", search: this.props.location.search});
                 }} style={{borderRadius: 30}}>  

                 {props.locale ==="TH"?<span>ข้อมูลใช้งาน Internet </span>:<span>Internet usage information</span>}
                 </Button>
                 </div>
              </div>
             	</header>

            <style>{`
            @media screen and (max-width: 768px) {
                  .iframeDiv {
                    padding-top: 50px;
                  }
            }
            `}</style>
              <Iframe src={iframeLoginSuccessUrl} onload={()=>{
                  this.loginpagelogafter();
              }}/>
          </div>
        </div>

    );
  }//
}


const mapStateToProps: any = (state: any) => ({
  locale: getCurrentLocale(state),
});

const setLocaleCustom = (res)=>{
  try {
    let haslocalStorage = !!localStorage;
    if (haslocalStorage) {
          localStorage.setItem('locale', res);
        }
      }
  catch (e) {
  }
return setLocale(res)
}
const mapDispatchToProps = {
  setLocaleData:setLocaleCustom,
}
const LoginIfreamPage_:any = connect<any, any, {}>(mapStateToProps, mapDispatchToProps)(Form.create({})(LoginIfreamPage));

export default LoginIfreamPage_
// export default LoginIfreamPage
